/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiSpacer, EuiLink } from '@elastic/eui'

import ScrollIntoView from '../../../../../ScrollIntoView'
import { getUpsertVersion } from '../../../../../../lib/stackDeployments/selectors'
import { hashMatchesNodeConfiguration } from '../../../../../../lib/stackDeployments/topology'

import { TopologyElementDescription, TopologyElementTitle } from './helpers'

import type { AnyTopologyElement, SliderInstanceType } from '../../../../../../types'
import type {
  DeploymentCreateRequest,
  DeploymentUpdateRequest,
  InstanceConfiguration,
} from '../../../../../../lib/api/v1/types'
import type { ReactNode } from 'react'

interface Props {
  id: string
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  topologyElement: AnyTopologyElement
  templateTopologyElement?: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  onChange?: (path: string[], value: any) => void
  onPlanChange: undefined | ((path: string[], value: any) => void)
  descriptionOverride?: ReactNode
  extraContent?: ReactNode
  version?: string | undefined
  sliderInstanceType: SliderInstanceType
}

export default class DisabledTopologyElement extends Component<Props> {
  render(): JSX.Element {
    const {
      deployment,
      topologyElement,
      templateTopologyElement,
      instanceConfiguration,
      onChange,
      descriptionOverride,
      extraContent,
    } = this.props

    const enableButtonText = (
      <FormattedMessage
        id='deploymentInfrastructure-topologyElement-disabled-enableButton'
        defaultMessage='Add capacity'
      />
    )

    const onEnable = onChange ? this.enable : undefined

    const version = getUpsertVersion({ deployment })

    return (
      <ScrollIntoView
        whenLocationMatches={(hash) => hashMatchesNodeConfiguration(hash, topologyElement)}
      >
        <div data-test-subj='topologyElement' data-id={instanceConfiguration.name}>
          <EuiPanel className='topologyPanel'>
            <TopologyElementTitle
              topologyElement={templateTopologyElement || topologyElement}
              instanceConfiguration={instanceConfiguration}
              version={version}
            />
            <EuiSpacer size='xs' />
            <TopologyElementDescription
              topologyElement={templateTopologyElement || topologyElement}
              instanceConfiguration={instanceConfiguration}
              descriptionOverride={descriptionOverride}
              version={version}
            />
            {onEnable && (
              <Fragment>
                <EuiSpacer size='s' />
                <EuiLink
                  color='primary'
                  data-test-id={`topologyElement-enableButton-${
                    templateTopologyElement ? templateTopologyElement.id : topologyElement.id
                  }`}
                  onClick={() => onEnable()}
                >
                  + {enableButtonText}
                </EuiLink>
              </Fragment>
            )}
            {extraContent && (
              <Fragment>
                <EuiSpacer size='s' />
                {extraContent}
              </Fragment>
            )}
          </EuiPanel>
        </div>
      </ScrollIntoView>
    )
  }

  enable = (): void => {
    const {
      templateTopologyElement,
      instanceConfiguration,
      onChange,
      version,
      onPlanChange,
      sliderInstanceType,
    } = this.props

    const {
      discrete_sizes: { default_size, resource },
    } = instanceConfiguration

    const defaultZoneCountInTemplate = templateTopologyElement?.zone_count

    // Use the default number of zones from the template, or 1 if there is no
    // template, the count in there is zero for some reason, etc.
    const zoneCount = defaultZoneCountInTemplate || 1

    onChange!([`size`], { value: default_size, resource })
    onChange!([`zone_count`], zoneCount)

    if (version && onPlanChange) {
      onPlanChange([sliderInstanceType, `version`], version)
    }
  }
}
