/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { isFeatureActivated } from '@/selectors/config'
import {
  getAllOrganizationDeploymentsSearchResults,
  searchAllOrganizationDeployments,
} from '@/components/Organization/OrganizationDeployments/lib'
import Feature from '@/lib/feature'

import { getCurrentUserUsername } from '../../../reducers'

import OrganizationOverview from './OrganizationOverview'

import type { ReduxState } from '@/types'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState, { saasOrganization }: ConsumerProps): StateProps => ({
  deploymentsSearchResults: getAllOrganizationDeploymentsSearchResults(
    state,
    saasOrganization.organization.organization_id,
  ),
  enableInvoiceAdminActions: isFeatureActivated(state, Feature.enableInvoiceAdminActions),
  username: getCurrentUserUsername(state),
})

const mapDispatchToProps = (
  dispatch,
  {
    saasOrganization: {
      organization: { organization_id: organizationId },
    },
  }: ConsumerProps,
): DispatchProps => ({
  searchOrganizationDeployments: () =>
    dispatch(
      searchAllOrganizationDeployments({
        organizationId,
      }),
    ),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationOverview)
