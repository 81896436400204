/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

import Organization from '@/components/Organization'
import Organizations from '@/components/Organization/Organizations'
import CreateDeploymentTrustRelationship from '@/components/DeploymentTrustRelationships/CreateDeploymentTrustRelationship'
import ManageDirectTrustRelationship from '@/components/DeploymentTrustRelationships/ManageDirectTrustRelationship'

import Logout from '../../components/Logout'
import RegionWrapper from '../../components/Region/RegionWrapper'
import Region from '../../components/Region'
import RegionSettings from '../../components/RegionSettings'
import PlatformRegionsRoute from '../../components/PlatformRegionsRoute'
import ElasticStackVersions from '../../components/RegionAdmin/ElasticStackVersions'
import ClusterOverview from '../../components/Cluster/ClusterOverview'
import APIConsole from '../../components/APIConsole'
import ClusterSnapshots from '../../components/Cluster/Snapshots'
import DeploymentSecurity from '../../components/DeploymentSecurity'
import DeploymentGettingStarted from '../../components/Deployment/DeploymentGettingStarted'
import DeploymentOperations from '../../components/DeploymentOperations'
import Adminconsoles from '../../components/Adminconsoles'
import AdminconsoleOverview from '../../components/AdminconsoleOverview'
import ContainerSets from '../../components/ContainerSets'
import ContainerSet from '../../components/ContainerSet'
import Container from '../../components/Container'
import Hosts from '../../components/Hosts'
import Host from '../../components/Host'
import Runner from '../../components/Runner'
import SnapshotRepositoryManagement from '../../components/SnapshotRepositoryManagement'
import CreateSnapshotRepository from '../../components/SnapshotRepositoryManagement/CreateSnapshotRepository'
import EditSnapshotRepository from '../../components/SnapshotRepositoryManagement/EditSnapshotRepository'
import Platform from '../../components/Platform'
import RedirectToDeploymentResource from '../../components/RedirectToDeploymentResource'
import SnapshotDetails from '../../components/Cluster/Snapshots/SnapshotDetails'
import Deployment from '../../components/Deployment'
import DeploymentOverview from '../../components/Deployment/Overview'
import Topology from '../../components/Topology/Topology'
import Users from '../../components/Users'
import RegionSecurity from '../../components/RegionSecurity'
import HeapDumps from '../../components/ManageHeapDumps'
import UserSettings from '../../components/UserSettings'
import NotFound from '../../components/ErrorPages/NotFound'
import SsoErrors from '../../components/ErrorPages/SsoErrors'
import IlmMigration from '../../components/IlmMigration'
import UserApiKeys from '../../components/UserApiKeys'
import CreateStackDeploymentRoute from '../../components/StackDeploymentEditor/CreateStackDeploymentRoute'
import EditStackDeployment from '../../components/StackDeploymentEditor/EditStackDeployment'
import EditStackDeploymentAdvancedRoute from '../../components/StackDeploymentEditor/EditStackDeploymentAdvancedRoute'
import StackDeploymentsRoute from '../../components/StackDeploymentsRoute'
import StackDeploymentActivityRoute from '../../components/StackDeploymentActivityRoute'
import StackActivityFeedRoute from '../../components/StackActivityFeedRoute'
import StackDeploymentSlider from '../../components/StackDeploymentSlider'
import {
  InstanceConfigurationList as TopologyInstanceConfigurationList,
  InstanceConfigurationView as TopologyInstanceConfigurationView,
  InstanceConfigurationCreate as TopologyInstanceConfigurationCreate,
  InstanceConfigurationEdit as TopologyInstanceConfigurationEdit,
} from '../../components/Topology/InstanceConfigurations'
import {
  DeploymentTemplateList as TopologyDeploymentTemplateList,
  DeploymentTemplateView as TopologyDeploymentTemplateView,
  DeploymentTemplateCreate as TopologyDeploymentTemplateCreate,
  DeploymentTemplateEdit as TopologyDeploymentTemplateEdit,
} from '../../components/Topology/DeploymentTemplates'
import {
  getSupportedSliderInstanceTypes,
  getSliderPrettyName,
  getSupportedSliderInstanceTypesWithoutEs,
} from '../../lib/sliders'
import { hydrateRouteConfig, withRouteChain, withRouteComponentChain } from '../../lib/router'
import { isFeatureActivated } from '../../selectors/config'
import Feature from '../../lib/feature'
import ManageAccountTrustRelationship from '../../components/DeploymentTrustRelationships/ManageAccountTrustRelationship'
import ManageExternalTrustRelationship from '../../components/DeploymentTrustRelationships/ManageExternalTrustRelationship'

import User from './components/User'
import SecurityClusterWrapper from './components/Management/SecurityClusterWrapper'
import ManageRegions from './components/Management/ManageRegions'
import ManageUsers from './components/Management/Users'
import {
  ExternalAuthenticationList,
  AddLdapProvider,
  EditLdapProvider,
  AddSamlProvider,
  EditSamlProvider,
} from './components/Management/ExternalAuthentication'
import ManageApiKeys from './components/Management/ManageApiKeys'
import EditActiveDirectoryProvider from './components/Management/ExternalAuthentication/activeDirectory/EditActiveDirectoryProvider'
import AddActiveDirectoryProvider from './components/Management/ExternalAuthentication/activeDirectory/AddActiveDirectoryProvider'
import LogsMetrics from './components/LogsMetrics'
import SsoReceiveToken from './components/LoginIndex/SsoReceiveToken'
import SsoBasedLogin from './components/LoginIndex/SsoBasedLogin'
import TrustManagement from './components/TrustManagement'
import LoginIndex from './components/LoginIndex'
import App from './components/App'
import BasicLogin from './components/LoginIndex/BasicLogin'
import AppRoot from './components/AppRoot'

import type { ReduxState, RedirectConfig } from '../../types'
import type { Store } from 'redux'
import type { IntlShape } from 'react-intl'
import type { RouteConfig } from 'react-router-config'

const defaultPostLoginLocation = `deployments`

const messages = defineMessages({
  activityFeedSliderRouteTitle: {
    id: `adminconsole-route-titles.activity-feed-slider`,
    defaultMessage: `{name} Activity Feed`,
  },
  sliderActivityPageTitle: {
    id: `adminconsole-route-titles.deployment.activity-page.slider`,
    defaultMessage: `{name} Activity`,
  },
  sliderManagePageTitle: {
    id: `adminconsole-route-titles.deployment.manage-page.slider`,
    defaultMessage: `Manage {name}`,
  },
})

export function getRedirects({ store }: { store: Store<ReduxState> }): RedirectConfig[] {
  const state = store.getState()
  const postLoginLocation = getPostLoginLocation(state)

  return [
    { from: '/', to: postLoginLocation },

    ...getPlatformRedirects(),
    ...getPlatformRbacRedirects(),
    ...getLegacyActivityFeedRedirects(),
    ...getStackDeploymentRedirects(),
  ]

  function getPlatformRedirects(): RedirectConfig[] {
    return [
      { from: '/region/:regionId', to: '/region/:regionId/platform' },
      {
        from: '/region/:regionId/templates',
        to: '/region/:regionId/templates/deployments',
      },
      {
        from: '/region/:regionId/runners',
        to: '/region/:regionId/hosts',
      },
      {
        from: '/region/:regionId/runners/:runnerId',
        to: '/region/:regionId/hosts/:runnerId',
      },
      {
        from: '/region/:regionId/runners/:runnerId/roles',
        to: '/region/:regionId/hosts/:runnerId',
      },
      {
        from: '/region/:regionId/runners/:runnerId/delete',
        to: '/region/:regionId/hosts/:runnerId',
      },
      {
        from: '/region/:regionId/allocators',
        to: '/region/:regionId/hosts/allocators',
      },
      {
        from: '/region/:regionId/allocators/:allocatorId',
        to: '/region/:regionId/hosts/:allocatorId/allocator',
      },
      {
        from: '/region/:regionId/allocators/:allocatorId/vacate',
        to: '/region/:regionId/hosts/:allocatorId/allocator',
      },
      {
        from: '/region/:regionId/allocators/:allocatorId/delete',
        to: '/region/:regionId/hosts/:allocatorId/allocator',
      },
      {
        from: '/region/:regionId/proxies',
        to: '/region/:regionId/hosts/proxies',
      },
      {
        from: '/region/:regionId/constructors',
        to: '/region/:regionId/hosts/control-planes',
      },
    ]
  }

  function getPlatformRbacRedirects(): RedirectConfig[] {
    const manageRbac = isFeatureActivated(state, Feature.manageRbac)

    if (!manageRbac) {
      return []
    }

    return [
      {
        from: '/region/:regionId/manage',
        to: '/region/:regionId/manage/authentication-providers',
      },
    ]
  }

  function getLegacyActivityFeedRedirects(): RedirectConfig[] {
    return getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
      from: `/activity-feed/${sliderInstanceType}`,
      to: '/activity-feed',
    }))
  }

  function getStackDeploymentRedirects(): RedirectConfig[] {
    return [
      {
        from: '/region/:regionId/deployment/:deploymentId',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/edit',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/edit/advanced',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit/advanced',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/config',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/config/advanced',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit/advanced',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/console',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/console',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/security',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/security',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/snapshots',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/snapshots',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/elasticsearch/snapshots/:snapshotName',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/elasticsearch/snapshots/:snapshotName',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/kibana',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/kibana',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/kibana/edit',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/kibana/edit/advanced',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit/advanced',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/apm',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/apm',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/apm/edit/advanced',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/edit/advanced',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/security',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/security',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/operations',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/operations',
      },
      {
        from: '/region/:regionId/deployment/:deploymentId/operations/heap-dumps',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/operations/heap-dumps',
      },

      {
        from: '/region/:regionId/deployment/:deploymentId/activity',
        to: '/deployments/resolve/cluster/:regionId/:deploymentId/activity',
      },

      ...getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
        from: `/region/:regionId/deployment/:deploymentId/activity/${sliderInstanceType}`,
        to: `/deployments/resolve/cluster/:regionId/:deploymentId/activity/${sliderInstanceType}`,
      })),
    ]
  }
}

export function getRoutes({
  intl,
  store,
}: {
  intl: IntlShape
  store: Store<ReduxState>
}): RouteConfig[] {
  const { formatMessage } = intl
  const state = store.getState()
  const manageRbac = isFeatureActivated(state, Feature.manageRbac)

  const routes: RouteConfig[] = [
    {
      component: AppRoot,
      routes: getRootRoutes(),
    },
  ]

  return routes.map(hydrateRouteConfig)

  function getRootRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = [
      { path: '/login', component: LoginIndex, title: 'Login' },
      { path: '/login/basic', component: BasicLogin, title: 'Login' },
      { path: '/login/sso', component: SsoBasedLogin, title: 'Login' },
      { path: '/logout', component: Logout, title: 'Logging out' },
      {
        path: '/oauth/token',
        component: SsoReceiveToken,
        title: 'Authenticating',
      },
      {
        path: '/sso/token',
        component: SsoReceiveToken,
        title: 'Authenticating',
      },

      { path: '/errors/sso', component: SsoErrors, title: 'Error' },

      ...getLoggedInRoutes(),

      { component: NotFound, title: 'Not Found' },
    ]

    return routes
  }

  function getLoggedInRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = [
      {
        path: '/deployments',
        component: StackDeploymentsRoute,
        title: 'Deployments',
      },
      {
        path: '/deployments/create',
        component: CreateStackDeploymentRoute,
        title: 'Create Deployment',
      },

      ...getStackDeploymentRoutes(),
      ...getOrganizationsRoutes(),
      ...getSaasUsersRoutes(),
      ...getPlatformRoutes(),
      ...getAccountLevelRoutes(),
    ]

    return routes.map(withRouteChain(App))
  }

  function getStackDeploymentRoutes(): RouteConfig[] {
    return [
      {
        path: '/deployments/resolve/:resourceType/:regionId/:resourceId',
        component: RedirectToDeploymentResource,
      },
      {
        path: '/deployments/resolve/:resourceType/:regionId/:resourceId/**',
        component: RedirectToDeploymentResource,
      },

      ...getDeploymentRoutes(),
    ]

    function getDeploymentRoutes(): RouteConfig[] {
      const routes: RouteConfig[] = [
        {
          path: '/deployments/:deploymentId',
          component: DeploymentOverview,
          title: getDeploymentOverviewTitle,
        },
        {
          path: '/deployments/:deploymentId/getting-started',
          component: DeploymentGettingStarted,
          title: getDeploymentOverviewTitle,
        },
        {
          path: '/deployments/:deploymentId/edit',
          component: EditStackDeployment,
          title: 'Edit deployment',
        },
        {
          path: '/deployments/:deploymentId/edit/advanced',
          component: EditStackDeploymentAdvancedRoute,
          title: 'Edit deployment',
        },

        {
          path: '/deployments/:deploymentId/:sliderInstanceType(elasticsearch)',
          component: ClusterOverview,
          title: 'Manage Elasticsearch',
        },
        {
          path: '/deployments/:deploymentId/elasticsearch/console',
          component: APIConsole,
          title: 'Deployment console',
        },
        {
          path: '/deployments/:deploymentId/elasticsearch/snapshots',
          component: ClusterSnapshots,
          title: 'Deployment snapshots',
        },
        {
          path: '/deployments/:deploymentId/elasticsearch/snapshots/:snapshotName',
          component: SnapshotDetails,
          title: 'Snapshot :snapshotName',
        },

        ...getStackDeploymentSliderRoutes(),

        {
          path: '/deployments/:deploymentId/activity',
          component: StackDeploymentActivityRoute,
          title: 'Deployment Activity',
        },

        ...getSupportedSliderInstanceTypes().map((sliderInstanceType) => ({
          path: `/deployments/:deploymentId/activity/${sliderInstanceType}`,
          component: StackDeploymentActivityRoute,
          title: formatMessage(messages.sliderActivityPageTitle, {
            name: formatMessage(getSliderPrettyName({ sliderInstanceType })),
          }),
        })),

        {
          path: '/deployments/:deploymentId/logs-metrics',
          component: LogsMetrics,
          title: 'Logs and metrics',
        },
        {
          path: '/deployments/:deploymentId/security',
          component: DeploymentSecurity,
          title: 'Security',
        },
        {
          path: '/deployments/:deploymentId/security/trust/create',
          component: CreateDeploymentTrustRelationship,
          title: 'Add trusted environment',
        },
        {
          path: '/deployments/:deploymentId/security/trust/accounts/:trustRelationshipId',
          component: ManageAccountTrustRelationship,
          title: 'Manage trust',
        },
        {
          path: '/deployments/:deploymentId/security/trust/accounts',
          component: ManageAccountTrustRelationship,
          title: 'Add trusted environment',
        },
        {
          path: '/deployments/:deploymentId/security/trust/external/:trustRelationshipId',
          component: ManageExternalTrustRelationship,
          title: 'Manage trust',
        },
        {
          path: '/deployments/:deploymentId/security/trust/external',
          component: ManageExternalTrustRelationship,
          title: 'Add trusted environment',
        },
        {
          path: '/deployments/:deploymentId/security/trust/direct/:trustRelationshipId',
          component: ManageDirectTrustRelationship,
          title: 'Manage trust',
        },
        {
          path: '/deployments/:deploymentId/security/trust/direct',
          component: ManageDirectTrustRelationship,
          title: 'Add trusted environment',
        },
        {
          path: '/deployments/:deploymentId/operations',
          component: DeploymentOperations,
          title: 'Operations',
        },
        {
          path: '/deployments/:deploymentId/operations/heap-dumps',
          component: HeapDumps,
          title: 'Heap dumps',
        },
        {
          path: '/deployments/:deploymentId/migrate-to-index-lifecycle-management',
          component: IlmMigration,
          title: 'Migrate to Index Lifecycle Management',
        },
      ]

      return routes.map(withRouteChain(Deployment))

      function getStackDeploymentSliderRoutes(): RouteConfig[] {
        return getSupportedSliderInstanceTypesWithoutEs().map((sliderInstanceType) => {
          const route: RouteConfig = {
            path: `/deployments/:deploymentId/:sliderInstanceType(${sliderInstanceType})`,
            title: formatMessage(messages.sliderManagePageTitle, {
              name: formatMessage(getSliderPrettyName({ sliderInstanceType })),
            }),
            component: StackDeploymentSlider,
          }

          return route
        })
      }
    }
  }

  function getSaasUsersRoutes(): RouteConfig[] {
    const lookupSaasUsers = isFeatureActivated(state, Feature.lookupSaasUsers)

    if (!lookupSaasUsers) {
      return []
    }

    const routes: RouteConfig[] = [
      { path: '/users', component: Users, title: 'Users' },
      {
        path: '/users/:userId',
        component: User,
        title: 'User Overview',
      },
      {
        path: '/users/:userId/:selectedTabId(deployments)',
        component: User,
        title: 'User Deployments',
      },
      {
        path: '/users/:userId/:selectedTabId(usage)',
        component: User,
        title: 'User Usage',
      },
      {
        path: '/users/:userId/:selectedTabId(billing-history)',
        component: User,
        title: 'User Billing History',
      },
      {
        path: '/users/:userId/:selectedTabId(billing-overview)',
        component: User,
        title: 'User Billing Overview',
      },
    ]

    return routes
  }

  function getOrganizationsRoutes(): RouteConfig[] {
    const lookupSaasUsers = isFeatureActivated(state, Feature.lookupSaasUsers)

    if (!lookupSaasUsers) {
      return []
    }

    const routes: RouteConfig[] = [
      { path: '/organizations', component: Organizations, title: 'Organizations' },
      {
        path: '/organizations/:organizationId',
        component: Organization,
        title: 'Organization Overview',
      },
      {
        path: '/organizations/:organizationId/:selectedTabId(members)',
        component: Organization,
        title: 'Organization Members',
      },
      {
        path: '/organizations/:organizationId/:selectedTabId(deployments)',
        component: Organization,
        title: 'Organization Deployments',
      },
      {
        path: '/organizations/:organizationId/:selectedTabId(billing-overview)',
        component: Organization,
        title: 'Organization Billing Overview',
      },
      {
        path: '/organizations/:organizationId/:selectedTabId(usage)',
        component: Organization,
        title: 'Organization Usage',
      },
      {
        path: '/organizations/:organizationId/:selectedTabId(billing-history)',
        component: Organization,
        title: 'Organization Billing History',
      },
    ]

    if (isFeatureActivated(state, Feature.billingPrepaidConsumptionUI)) {
      routes.push({
        path: '/organizations/:organizationId/:selectedTabId(billing-subscription)',
        component: Organization,
        title: 'Organization Billing Subscription',
      })
    }

    return routes
  }

  function getPlatformRoutes(): RouteConfig[] {
    const manageRbacRegionsRoutes = manageRbac
      ? [
          {
            path: '/manage',
            component: ManageRegions,
            title: 'Regions',
          },
        ]
      : []

    const routes: RouteConfig[] = [
      { path: '/platform', component: PlatformRegionsRoute, title: 'Platform' },

      ...manageRbacRegionsRoutes,

      ...getPlatformRegionRoutes(),

      ...getActivityFeedRoutes(),
    ]

    return routes

    function getPlatformRegionRoutes(): RouteConfig[] {
      const routes: RouteConfig[] = [
        ...getRegionPlatformRoutes(),
        ...getCorePlatformRoutes(),
        ...getHostsRoutes(),
        ...getAdminconsolesRoutes(),
        ...getContainerSetRoutes(),
        ...getElasticStackRoutes(),
        ...getTrustManagementRoutes(),
        ...getSnapshotRepositoryRoutes(),
        ...getDeploymentTemplatesRoutes(),
        ...getManageRbacRoutes(),
      ]

      return routes.map(withRouteChain(RegionWrapper))
    }

    function getRegionPlatformRoutes(): RouteConfig[] {
      const routes = [
        {
          path: '/region/:regionId/platform',
          component: Platform,
          title: 'Platform summary',
        },
      ]

      // @ts-ignore ??
      return routes.map(withRouteChain(Region))
    }

    function getCorePlatformRoutes(): RouteConfig[] {
      const routes = [
        {
          path: '/region/:regionId/security',
          component: RegionSecurity,
          title: 'Security',
        },

        {
          path: '/region/:regionId/settings',
          component: RegionSettings,
          title: 'Settings',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getHostsRoutes(): RouteConfig[] {
      const HostsRoute = withRouteComponentChain(Region)(Hosts)

      return [
        {
          path: '/region/:regionId/hosts',
          component: HostsRoute,
          title: 'Hosts',
        },

        {
          path: '/region/:regionId/hosts/:hostsType(allocators)',
          component: HostsRoute,
          title: 'Allocators',
        },

        {
          path: '/region/:regionId/hosts/:hostsType(proxies)',
          component: HostsRoute,
          title: 'Proxies',
        },

        {
          path: '/region/:regionId/hosts/:hostsType(control-planes)',
          component: HostsRoute,
          title: 'Control planes',
        },

        ...getIndividualHostRoutes(),
      ]

      function getIndividualHostRoutes(): RouteConfig[] {
        const HostRoute = withRouteComponentChain(Region, Runner)(Host)

        const routes: RouteConfig[] = [
          {
            path: '/region/:regionId/hosts/:hostId',
            component: HostRoute,
            title: 'Host overview',
          },

          {
            path: '/region/:regionId/hosts/:hostId/:hostType(allocator)',
            component: HostRoute,
            title: 'Allocator',
          },

          {
            path: '/region/:regionId/hosts/:hostId/:hostType(proxy)',
            component: HostRoute,
            title: 'Proxy',
          },

          {
            path: '/region/:regionId/hosts/:hostId/:hostType(control-plane)',
            component: HostRoute,
            title: 'Control plane',
          },
        ]

        return routes
      }
    }

    function getAdminconsolesRoutes(): RouteConfig[] {
      if (!isFeatureActivated(state, Feature.adminConsoles)) {
        return []
      }

      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/admin-consoles',
          component: Adminconsoles,
          title: 'Admin consoles',
        },

        {
          path: '/region/:regionId/admin-consoles/:adminconsoleId',
          component: AdminconsoleOverview,
          title: 'Admin console :adminconsoleId',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getContainerSetRoutes(): RouteConfig[] {
      if (!isFeatureActivated(state, Feature.containerSets)) {
        return []
      }

      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/container-sets',
          component: ContainerSets,
          title: 'Container Sets',
        },

        {
          path: '/region/:regionId/container-sets/:containerSetId',
          component: ContainerSet,
          title: 'Container Set',
        },

        {
          path: '/region/:regionId/container-sets/:containerSetId/containers/:containerId',
          component: Container,
          title: 'Container',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getElasticStackRoutes(): RouteConfig[] {
      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/elastic-stack',
          component: ElasticStackVersions,
          title: 'Elastic Stack versions',
        },
        {
          path: '/region/:regionId/elastic-stack/:versionId',
          component: ElasticStackVersions,
          title: 'Elastic Stack version details',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getTrustManagementRoutes(): RouteConfig[] {
      if (!isFeatureActivated(state, Feature.crossEnvCCSCCR)) {
        return []
      }

      const routes = [
        {
          path: '/region/:regionId/trust-management',
          component: TrustManagement,
          title: 'Trust Management',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getSnapshotRepositoryRoutes(): RouteConfig[] {
      const defaultSnapshotRepository = isFeatureActivated(state, Feature.defaultSnapshotRepository)

      if (defaultSnapshotRepository) {
        return []
      }

      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/repositories',
          component: SnapshotRepositoryManagement,
          title: 'Snapshot Repositories',
        },
        {
          path: '/region/:regionId/repositories/create',
          component: CreateSnapshotRepository,
          title: 'Create Snapshot Repository',
        },
        {
          path: '/region/:regionId/repositories/:repositoryId/edit',
          component: EditSnapshotRepository,
          title: 'Edit Snapshot Repository',
        },
      ]

      return routes.map(withRouteChain(Region))
    }

    function getDeploymentTemplatesRoutes(): RouteConfig[] {
      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/templates/deployments',
          component: TopologyDeploymentTemplateList,
          title: 'Deployment Templates',
        },
        {
          path: '/region/:regionId/templates/deployments/create',
          component: TopologyDeploymentTemplateCreate,
          title: 'Create Deployment Template',
        },
        {
          path: '/region/:regionId/templates/deployments/:templateId/edit',
          component: TopologyDeploymentTemplateEdit,
          title: 'Edit Deployment Template :templateId',
        },
        {
          path: '/region/:regionId/templates/deployments/:templateId',
          component: TopologyDeploymentTemplateView,
          title: 'Deployment Template :templateId',
        },

        {
          path: '/region/:regionId/templates/instance-configurations',
          component: TopologyInstanceConfigurationList,
          title: 'Instance Configurations',
        },
        {
          path: '/region/:regionId/templates/instance-configurations/create',
          component: TopologyInstanceConfigurationCreate,
          title: 'Create Instance Configuration',
        },
        {
          path: '/region/:regionId/templates/instance-configurations/:instanceId/edit',
          component: TopologyInstanceConfigurationEdit,
          title: 'Edit Instance Configuration :instanceId',
        },
        {
          path: '/region/:regionId/templates/instance-configurations/:instanceId',
          component: TopologyInstanceConfigurationView,
          title: 'Instance Configuration :instanceId',
        },
      ]

      // @ts-ignore ??
      return routes.map(withRouteChain(Region, Topology))
    }

    function getManageRbacRoutes(): RouteConfig[] {
      if (!manageRbac) {
        return []
      }

      const routes: RouteConfig[] = [
        {
          path: '/region/:regionId/manage/authentication-providers',
          component: ExternalAuthenticationList,
          title: 'Authentication providers',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/active-directory/create',
          component: AddActiveDirectoryProvider,
          title: 'Add Active Directory provider',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/active-directory/:realmId',
          component: EditActiveDirectoryProvider,
          title: 'Edit Active Directory provider :realmId',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/ldap/create',
          component: AddLdapProvider,
          title: 'Add LDAP provider',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/ldap/:realmId',
          component: EditLdapProvider,
          title: 'Edit LDAP provider :realmId',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/saml/create',
          component: AddSamlProvider,
          title: 'Add SAML provider',
        },
        {
          path: '/region/:regionId/manage/authentication-providers/saml/:realmId',
          component: EditSamlProvider,
          title: 'Edit SAML provider :realmId',
        },

        {
          path: '/region/:regionId/manage/users',
          component: ManageUsers,
          title: 'Native users',
        },
        {
          path: '/region/:regionId/manage/keys',
          component: ManageApiKeys,
          title: 'API keys',
        },
      ]

      return routes.map(
        // @ts-ignore ??
        withRouteChain(Region, SecurityClusterWrapper),
      )
    }
  }

  function getAccountLevelRoutes(): RouteConfig[] {
    return [
      {
        // this is an RBAC thing in ECE that renders an almost blank page in ESS AC 🤷‍♂️
        path: '/settings',
        component: UserSettings,
        title: 'Settings',
      },

      { path: '/keys', component: UserApiKeys, title: 'API keys' },
    ]
  }

  function getActivityFeedRoutes(): RouteConfig[] {
    return [
      {
        path: '/activity-feed',
        component: StackActivityFeedRoute,
        title: 'Activity Feed',
      },
    ]
  }
}

function getDeploymentOverviewTitle({ match: { params } }): string {
  const { deploymentId } = params
  const shortId = deploymentId.substr(0, 6)

  return `[${shortId}] Overview`
}

function getPostLoginLocation(state) {
  const {
    config: { postLoginLocation = defaultPostLoginLocation },
  } = state

  return postLoginLocation
}
