/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { majorAndMinor } from '../../lib/semver'

import StackDocLink from './StackDocLink'

import type { FunctionComponent } from 'react'
import type { DocLink, WellKnownSliderInstanceType } from '@/types'

interface SliderToDocLinkMapping {
  root: string | null
  pageTitle?: string
}

const SLIDER_TO_DOCLINK: Record<WellKnownSliderInstanceType, SliderToDocLinkMapping> = {
  elasticsearch: { root: `elasticsearch/reference`, pageTitle: `es-release-notes` },
  kibana: { root: `kibana` },
  apm: { root: `apm/server` },
  enterprise_search: { root: `enterprise-search` },
  appsearch: { root: `app-search` },
  integrations_server: { root: null }, // No docs available
}

interface Props {
  sliderInstanceType: WellKnownSliderInstanceType
  version: string
}

const ReleaseNotesLink: FunctionComponent<Props> = ({ sliderInstanceType, version, children }) => {
  const doclink = buildDocLink({ sliderInstanceType, version })

  if (!doclink) {
    return null
  }

  return (
    <StackDocLink showExternalLinkIcon={true} {...doclink}>
      {children}
    </StackDocLink>
  )
}

// Necessary until/if we refactor our doclink component to allow dynamic paths
function buildDocLink({ sliderInstanceType, version }: Props): DocLink | null {
  const { root, pageTitle = `release-notes` } = SLIDER_TO_DOCLINK[sliderInstanceType]

  if (!root) {
    return null
  }

  // Ideally we'd link to `/release-notes-${version}.html` and deep link
  // to the breaking changes via the hash, but version formatting in the urls
  // is inconsistent as only some include the patch version (eg '7.12.0' vs '7.11').
  // We'd need to consolidate these before linking to them reliably, or create a new
  // page in the docs specifically for the purpose of this link.
  return {
    href: `${root}/${majorAndMinor(version)}/${pageTitle}.html`,
    hash: undefined,
  }
}

export default ReleaseNotesLink
