/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { get, isArray } from 'lodash'
import cx from 'classnames'

import { EuiBetaBadge, EuiFlexGroup, EuiFlexItem, EuiSideNav } from '@elastic/eui'

import { getRouteParams, matchRoute } from '../../lib/router'
import { isFeatureActivated } from '../../store'
import {
  getDisplayName,
  getVersion,
  isSliderSupportedForDeployment,
} from '../../lib/stackDeployments/selectors'
import { getSliderPrettyName, getSupportedSliderInstanceTypesWithoutEs } from '../../lib/sliders'
import history from '../../lib/history'
import { isPermitted } from '../../lib/requiresPermission'
import Permission from '../../lib/api/v1/permissions'
import Feature from '../../lib/feature'
import {
  activityFeedUrl,
  adminconsoleOverviewUrl,
  adminconsolesUrl,
  apiKeysUrl,
  authenticationProvidersUrl,
  clusterSnapshotUrl,
  containerSetsUrl,
  containerSetUrl,
  containerUrl,
  createDeploymentUrl,
  createSnapshotRepositoryUrl,
  deploymentAdvancedEditUrl,
  deploymentsUrl,
  deploymentUrl,
  deploymentUrls,
  editSnapshotRepositoryUrl,
  elasticStackVersionsUrl,
  heapDumpsUrl,
  hostsUrl,
  hostUrl,
  logsMonitoringUrl,
  manageApiKeysUrl,
  managementUrl,
  manageNativeUsersUrl,
  manageRegionUrl,
  organizationOverviewUrl,
  organizationsUrl,
  platformTrustManagementUrl,
  platformUrl,
  regionSecurityUrl,
  regionSettingsUrl,
  regionsUrl,
  regionUrl,
  snapshotRepositoriesUrl,
  topologyUrl,
  topologyViewDeploymentTemplateUrl,
  userCreateUrl,
  userOverviewUrl,
  usersUrl,
} from '../../lib/urlBuilder'

import messages from './navigationMessages'

import type { ElasticsearchCluster } from '../../types'
import type {
  SaasUserResponse,
  DeploymentGetResponse,
  DeploymentTemplateInfoV2,
  Organization,
} from '../../lib/api/v1/types'
import type { UnregisterCallback } from 'history'
import type { RouteComponentProps } from 'react-router-dom'
import type { RouteConfig } from 'react-router-config'
import type { IntlShape } from 'react-intl'
import type { ReactNode, ReactElement } from 'react'

import './chromeNavigation.scss'

type SideNavItem = {
  name: string
  href?: string
  isSelected?: boolean
  items?: EuiSideNavItem[]
  isActive?: () => boolean
  ['data-test-id']?: string
}

type EuiSideNavItem = SideNavItem & {
  id: string
  forceOpen: boolean
}

type RenderNavLinkProps = {
  href?: string
  children: ReactNode
  className?: string
  exact?: boolean
  beta?: boolean
}

export type Props = {
  intl: IntlShape
  routes: RouteConfig[]
  location: RouteComponentProps['location']
  deployment?: ElasticsearchCluster | null
  deploymentTemplate?: DeploymentTemplateInfoV2
  defaultRegionId?: string
  regionId?: string
  deploymentId?: string
  stackDeploymentId?: string
  stackDeployment?: DeploymentGetResponse | null
  fetchDeploymentTemplates: (params: {
    regionId: string
    stackVersion: string
    showMaxZones: boolean
  }) => void
  saasUserProfile?: SaasUserResponse
  organization?: Organization
  lookupSaasUsers: boolean
  manageRbacFeature: boolean
  trafficFilteringFeature: boolean
  showApiKeys: boolean
  showUserApiKeys: boolean
  hasPermission: (permission: Permission) => boolean
}

type State = {
  isSideNavOpenOnMobile: boolean
}

class ChromeNavigation extends Component<Props, State> {
  state: State = {
    isSideNavOpenOnMobile: false,
  }

  clearOnNavigateHook: UnregisterCallback | null = null

  componentDidMount() {
    const deploymentActive = isDeploymentActive(this.props)

    if (deploymentActive) {
      this.fetchDeploymentTemplatesWithStackVersion()
    }

    this.clearOnNavigateHook = history.listen(this.onNavigate)
  }

  componentDidUpdate(prevProps) {
    const prevDeploymentVersion = get(prevProps.deployment, [`plan`, `version`], null)
    const deploymentVersion = get(this.props.deployment, [`plan`, `version`], null)
    const isActive = isDeploymentActive(this.props)

    if (!isActive) {
      return
    }

    const wasActive = isDeploymentActive(prevProps)

    if (
      !wasActive ||
      get(prevProps, [`deployment`, `id`]) !== get(this.props, [`deployment`, `id`]) ||
      deploymentVersion !== prevDeploymentVersion
    ) {
      this.fetchDeploymentTemplatesWithStackVersion()
    }
  }

  componentWillUnmount() {
    if (this.clearOnNavigateHook) {
      this.clearOnNavigateHook()
      this.clearOnNavigateHook = null
    }
  }

  render() {
    const {
      location,
      intl: { formatMessage },
      saasUserProfile,
      organization,
      lookupSaasUsers,
      manageRbacFeature,
      hasPermission,
      showUserApiKeys,
      routes,
    } = this.props

    const params = getRouteParams<{ userId: string; organizationId: string }>(
      routes,
      location.pathname,
    )
    const { userId, organizationId } = params

    const deploymentActive = isDeploymentActive(this.props)
    const platformActive = isPlatformActive(this.props)
    const managementActive = isManagementActive(this.props)
    const createDeploymentActive = isCreateDeploymentActive(this.props)

    const euiSideNavItems: EuiSideNavItem[] = []

    if (hasPermission(Permission.searchDeployments)) {
      euiSideNavItems.push(this.buildDeploymentMenu(createDeploymentActive, deploymentActive))
    }

    if (hasPermission(Permission.searchAllocators)) {
      euiSideNavItems.push(this.buildPlatformMenu(platformActive))
    }

    if (manageRbacFeature && hasPermission(Permission.getUsers)) {
      euiSideNavItems.push(this.buildUsersMenu(managementActive))
    }

    if (hasPermission(Permission.searchDeployments)) {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.activityFeed),
          href: activityFeedUrl(),
          'data-test-id': `activity-feed-section-link`,
        }),
      )
    }

    const organizationItems = this.organizationNavigation({
      organizationId: organizationId!,
      organization,
    })

    if (lookupSaasUsers) {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.organizations),
          href: organizationsUrl(),
          items: organizationItems,
        }),
      )
    }

    const userItems = this.userNavigation({ userId: userId!, profile: saasUserProfile })

    if (lookupSaasUsers) {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.saasUsers),
          href: usersUrl(),
          items: userItems,
        }),
      )
    }

    if (hasPermission(Permission.getApiKeys) && showUserApiKeys) {
      euiSideNavItems.push(
        createItem({
          name: formatMessage(messages.userApiKeys),
          href: apiKeysUrl(),
          'data-test-id': `top-level-api-keys-link`,
        }),
      )
    }

    const euiSideNavItemsWithSelectedItem = setSelectedItem(euiSideNavItems, itemIsSelected)

    return (
      <EuiSideNav
        mobileTitle={formatMessage(messages.mobileTitle)}
        isOpenOnMobile={this.state.isSideNavOpenOnMobile}
        toggleOpenOnMobile={this.toggleOpenOnMobile}
        items={euiSideNavItemsWithSelectedItem}
        renderItem={renderNavLink}
        className='cloud-navigation'
      />
    )

    function itemIsSelected(item: EuiSideNavItem): boolean {
      if (!item.href) {
        return false
      }

      return matchRoute(routes, location.pathname, item.href)
    }
  }

  buildDeploymentMenu(createDeploymentActive, deploymentActive) {
    const {
      intl: { formatMessage },
    } = this.props

    const createDeploymentItem = createDeploymentActive
      ? [
          createItem({
            name: formatMessage(messages.createDeployment),
            href: createDeploymentUrl(),
            'data-test-id': `deployment-section-create-deployment-link`,
          }),
        ]
      : undefined

    const deploymentItems = deploymentActive ? this.deploymentNavigation() : createDeploymentItem

    return createItem({
      name: formatMessage(messages.deployments),
      href: deploymentsUrl(),
      items: deploymentItems,
      'data-test-id': `deployment-section-link`,
    })
  }

  buildPlatformMenu(platformActive) {
    const {
      defaultRegionId,
      regionId,
      intl: { formatMessage },
    } = this.props

    const platformItems =
      platformActive !== false && regionId != null ? this.platformNavigation(regionId) : undefined

    return createItem({
      name: formatMessage(messages.platform),
      href: defaultRegionId ? regionUrl(defaultRegionId) : regionsUrl(),
      items: platformItems,

      // necessary because otherwise Platform lights up when Users is active
      isActive: () => platformActive,
      'data-test-id': `platform-link`,
    })
  }

  buildUsersMenu(usersActive) {
    const {
      defaultRegionId,
      regionId,
      intl: { formatMessage },
    } = this.props

    const manageUsersItems =
      usersActive !== false && regionId != null
        ? this.manageUsersNavigation(regionId || defaultRegionId)
        : undefined

    return createItem({
      name: formatMessage(messages.users),
      href: defaultRegionId ? manageRegionUrl(defaultRegionId) : managementUrl(),
      'data-test-id': `management-section-link`,
      items: manageUsersItems,
    })
  }

  toggleOpenOnMobile = () => {
    this.setState({
      isSideNavOpenOnMobile: !this.state.isSideNavOpenOnMobile,
    })
  }

  onNavigate = () =>
    this.setState({
      isSideNavOpenOnMobile: false,
    })

  fetchDeploymentTemplatesWithStackVersion = () => {
    const { regionId, deployment, stackDeployment, fetchDeploymentTemplates } = this.props
    const stackVersion = stackDeployment
      ? getVersion({ deployment: stackDeployment })
      : get(deployment, [`plan`, `version`])

    if (regionId == null) {
      return // avoid loading templates without region
    }

    if (stackVersion == null) {
      return // avoid loading templates without version qualification
    }

    fetchDeploymentTemplates({ regionId, stackVersion, showMaxZones: true })
  }

  deploymentNavigation() {
    const {
      deployment,
      deploymentTemplate,
      intl: { formatMessage },
      routes,
      location,
      stackDeploymentId,
      stackDeployment,
    } = this.props

    const params = getRouteParams<{ snapshotName: string }>(routes, location.pathname)
    const { snapshotName } = params

    const id = stackDeploymentId
    const urls = deploymentUrls(id!)
    const version = stackDeployment ? getVersion({ deployment: stackDeployment }) : undefined

    const advancedDeploymentEditActive = isAdvancedDeploymentEditActive(this.props)

    const editSubItems: EuiSideNavItem[] = []

    if (advancedDeploymentEditActive) {
      editSubItems.push(
        createItem({
          name: formatMessage(messages.advancedEdit),
          href: urls.advancedEdit,
          'data-test-id': `deployment-advanced-edit-link`,
        }),
      )
    }

    const elasticsearchSubItems = [
      createItem({
        name: formatMessage(messages.clusterSnapshots),
        href: urls.snapshots,
        'data-test-id': `deployment-index-elasticsearch-snapshots-link`,
        items: snapshotName
          ? [
              createItem({
                name: snapshotName,
                href: clusterSnapshotUrl(id!, snapshotName),
              }),
            ]
          : undefined,
      }),
      createItem({
        name: formatMessage(messages.apiConsole),
        href: urls.console,
        'data-test-id': `deployment-console-link`,
      }),
    ]

    const editItem = isPermitted(Permission.updateDeployment)
      ? createItem({
          name: formatMessage(messages.deploymentEdit),
          href: urls.edit,
          'data-test-id': `deployment-edit-link`,
          items: editSubItems,
        })
      : undefined

    const elasticsearchItems = createItem({
      name: formatMessage(messages.deploymentElasticsearch),
      href: urls.elasticsearch,
      'data-test-id': `deployment-index-elasticsearch-overview-link`,
      items: elasticsearchSubItems,
    })

    const sliderItems = getSupportedSliderInstanceTypesWithoutEs()
      .filter((sliderInstanceType) =>
        isSliderSupportedForDeployment({
          deployment: stackDeployment || undefined,
          deploymentTemplate: deploymentTemplate?.deployment_template,
          sliderInstanceType,
        }),
      )
      .map((sliderInstanceType) =>
        createItem({
          name: formatMessage(getSliderPrettyName({ sliderInstanceType, version })),
          href: urls[sliderInstanceType],
          'data-test-id': `deployment-index-${sliderInstanceType}-overview-link`,
        }),
      )

    const logsItem = createItem({
      name: formatMessage(messages.loggingMonitoring),
      href: logsMonitoringUrl(id!),
      'data-test-id': `deployment-index-logging-monitoring-link`,
    })

    const activityItem = createItem({
      name: formatMessage(messages.deploymentActivity),
      href: urls.deploymentActivity,
      'data-test-id': `deployment-index-activity-link`,
      isSelected: location.pathname.startsWith(urls.deploymentActivity),
    })

    const securityItem = createItem({
      name: formatMessage(messages.deploymentSecurity),
      href: urls.security,
      'data-test-id': `deployment-index-elasticsearch-security-link`,
    })

    const operationsItem = createItem({
      name: formatMessage(messages.deploymentOperations),
      href: urls.operations,
      'data-test-id': `deployment-index-elasticsearch-operations-link`,
      items: [
        createItem({
          name: formatMessage(messages.deploymentHeapDumps),
          href: heapDumpsUrl(id!),
          'data-test-id': `deployment-index-elasticsearch-heap-dumps-link`,
        }),
      ],
    })

    const deploymentItems = [
      ...(editItem ? [editItem] : []),
      elasticsearchItems,
      ...sliderItems,
      logsItem,
      activityItem,
      securityItem,
      operationsItem,
    ]

    return [
      createItem({
        name: getDeploymentName(),
        href: urls.root,

        /* WARNING: This data-test-id is being used to exclude tracked items from FullStory.
          This is a matter of PRIVACY, any changes here need approval of @amoldovan or @osman */
        'data-test-id': `deployment-index-overview-link`,
        items: deploymentItems,
      }),
    ]

    function getDeploymentName() {
      if (stackDeployment) {
        return getDisplayName({ deployment: stackDeployment })
      }

      if (stackDeploymentId) {
        return stackDeploymentId.slice(0, 6)
      }

      if (deployment) {
        return deployment.displayName
      }

      return id!.slice(0, 6)
    }
  }

  platformNavigation(regionId) {
    const {
      defaultRegionId,
      intl: { formatMessage },
      routes,
      location,
      trafficFilteringFeature,
    } = this.props

    const params = getRouteParams<{ repositoryId: string }>(routes, location.pathname)
    const { repositoryId } = params

    const platformLink = platformUrl(regionId)
    const adminconsolesLink = adminconsolesUrl(regionId)
    const containerSetsLink = containerSetsUrl(regionId)
    const hostsLink = hostsUrl(regionId)
    const elasticStackVersionsLink = elasticStackVersionsUrl(regionId)
    const trustManagementLink = platformTrustManagementUrl(regionId)
    const snapshotRepositoriesLink = snapshotRepositoriesUrl(regionId)
    const regionSettingsLink = regionSettingsUrl(regionId)
    const regionSecurityLink = regionSecurityUrl(regionId)
    const createSnapshotRepositoryLink = createSnapshotRepositoryUrl(regionId)
    const editSnapshotRepositoryLink = editSnapshotRepositoryUrl(regionId, repositoryId!)

    const adminconsoleItems = this.adminconsoleNavigation(regionId)
    const containerSetItems = this.containerSetNavigation(regionId)
    const hostItems = this.hostNavigation(regionId)
    const topologyItems = this.topologyNavigation()
    const repositoryItems: EuiSideNavItem[] = []

    const defaultSnapshotRepository = isFeatureActivated(Feature.defaultSnapshotRepository)

    if (!defaultSnapshotRepository) {
      if (isCreateRepositoryActive(this.props)) {
        repositoryItems.push(
          createItem({
            name: formatMessage(messages.createRepository),
            href: createSnapshotRepositoryLink,
            'data-test-id': `platform-create-repository-link`,
          }),
        )
      }

      if (isEditSnapshotRepositoryActive(this.props)) {
        repositoryItems.push(
          createItem({
            name: repositoryId!,
            href: editSnapshotRepositoryLink,
            'data-test-id': `platform-edit-repository-link-${repositoryId}`,
          }),
        )
      }
    }

    const platformItems = [
      createItem({
        name: formatMessage(messages.platformHosts),
        href: hostsLink,
        items: hostItems,
        'data-test-id': `platform-hosts-link`,
      }),

      ...(isFeatureActivated(Feature.adminConsoles)
        ? [
            createItem({
              name: formatMessage(messages.platformAdminconsoles),
              href: adminconsolesLink,
              items: adminconsoleItems,
              'data-test-id': `platform-admin-consoles-link`,
            }),
          ]
        : []),

      ...(isFeatureActivated(Feature.containerSets)
        ? [
            createItem({
              name: formatMessage(messages.platformContainerSets),
              href: containerSetsLink,
              items: containerSetItems,
              'data-test-id': `platform-container-sets-link`,
            }),
          ]
        : []),

      createItem({
        name: formatMessage(messages.platformElasticStack),
        href: elasticStackVersionsLink,
        'data-test-id': `platform-stack-link`,
      }),

      ...(isFeatureActivated(Feature.crossEnvCCSCCR)
        ? [
            createItem({
              name: formatMessage(messages.platformTrustManagement),
              href: trustManagementLink,
              'data-test-id': `platform-trust-link`,
            }),
          ]
        : []),

      createItem({
        name: formatMessage(messages.platformTopology),
        href: topologyUrl(regionId),
        isSelected:
          topologyItems != null ||
          matchRoute(routes, location.pathname, topologyUrl(`:regionId`), { exact: false }),
        items: topologyItems,
        'data-test-id': `platform-templates-link`,
      }),

      ...(defaultSnapshotRepository
        ? []
        : [
            createItem({
              name: formatMessage(messages.platformRepositories),
              href: snapshotRepositoriesLink,
              'data-test-id': `platform-repositories-link`,
              items: repositoryItems.length > 0 ? repositoryItems : undefined,
            }),
          ]),

      ...(trafficFilteringFeature
        ? [
            createItem({
              name: formatMessage(messages.platformSecurity),
              href: regionSecurityLink,
              'data-test-id': `platform-security-link`,
            }),
          ]
        : []),

      createItem({
        name: formatMessage(messages.platformSettings),
        href: regionSettingsLink,
        'data-test-id': `platform-settings-link`,
      }),
    ]

    // Only introduce an extra navigation level if there are multiple regions,
    // i.e. no default.
    if (defaultRegionId == null) {
      return [
        createItem({
          name: regionId,
          href: platformLink,
          items: platformItems,
        }),
      ]
    }

    platformItems.unshift(
      createItem({
        name: formatMessage(messages.platformSummary),
        href: platformLink,
      }),
    )

    return platformItems
  }

  manageUsersNavigation(regionId) {
    const {
      defaultRegionId,
      intl: { formatMessage },
      hasPermission,
      showApiKeys,
    } = this.props

    const href = authenticationProvidersUrl(regionId)
    const managementItems = [
      createItem({
        name: formatMessage(messages.managementAuthenticationProviders),
        href,
      }),
      createItem({
        name: formatMessage(messages.managementUsers),
        href: manageNativeUsersUrl(regionId),
        'data-test-id': `manage-users-link`,
      }),
    ]

    if (hasPermission(Permission.getApiKeys) && showApiKeys) {
      managementItems.push(
        createItem({
          name: formatMessage(messages.managementApiKeys),
          href: manageApiKeysUrl(regionId),
          'data-test-id': `manage-api-keys-link`,
        }),
      )
    }

    // No extra level of menu items where there is a default region
    if (defaultRegionId != null) {
      return managementItems
    }

    return [
      createItem({
        name: regionId,
        href: manageRegionUrl(regionId),
        items: managementItems,
      }),
    ]
  }

  userNavigation({ userId, profile }: { userId: string; profile?: SaasUserResponse }) {
    const {
      intl: { formatMessage },
    } = this.props
    const userActive = isUserActive(this.props)
    const userCreateActive = isUserCreateActive(this.props)

    if (userActive) {
      return [
        createItem({
          name: profile != null ? profile.user.email : userId,
          href: userOverviewUrl(userId),
        }),
      ]
    }

    if (userCreateActive) {
      return [
        createItem({
          name: formatMessage(messages.createSaasUser),
          href: userCreateUrl(),
        }),
      ]
    }

    return undefined
  }

  organizationNavigation({
    organizationId,
    organization,
  }: {
    organizationId: string
    organization?: Organization
  }) {
    const organizationActive = isOrganizationActive(this.props)

    if (organizationActive) {
      return [
        createItem({
          name: organization != null ? organization.name : organizationId,
          href: organizationOverviewUrl(organizationId),
        }),
      ]
    }

    return undefined
  }

  adminconsoleNavigation(regionId: string) {
    const { routes, location } = this.props

    const params = getRouteParams<{ adminconsoleId: string }>(routes, location.pathname)
    const { adminconsoleId } = params

    const isAdminconsoleUrlActive =
      adminconsoleId != null &&
      matchRoute(
        routes,
        location.pathname,
        adminconsoleOverviewUrl(`:regionId`, `:adminconsoleId`),
        {
          exact: false,
        },
      )

    if (!adminconsoleId) {
      return
    }

    if (!isAdminconsoleUrlActive) {
      return
    }

    const overviewUrl = adminconsoleOverviewUrl(regionId, adminconsoleId)

    return [
      createItem({
        name: adminconsoleId,
        href: overviewUrl,
      }),
    ]
  }

  containerSetNavigation(regionId) {
    const { routes, location } = this.props

    const params = getRouteParams<{ containerSetId: string }>(routes, location.pathname)
    const { containerSetId } = params

    const isContainerSetUrlActive =
      containerSetId != null &&
      matchRoute(routes, location.pathname, containerSetUrl(`:regionId`, `:containerSetId`), {
        exact: false,
      })

    if (!containerSetId) {
      return
    }

    if (!isContainerSetUrlActive) {
      return
    }

    const containerSetNavigationItems = this.containerSetNavigationItems(regionId, containerSetId)

    return [
      createItem({
        name: containerSetId,
        href: containerSetUrl(regionId, containerSetId),
        items: containerSetNavigationItems,
      }),
    ]
  }

  containerSetNavigationItems(regionId, containerSetId) {
    const { routes, location } = this.props

    const params = getRouteParams<{ containerId: string }>(routes, location.pathname)
    const { containerId } = params

    const isContainerUrlActive =
      containerId != null &&
      matchRoute(
        routes,
        location.pathname,
        containerUrl(`:regionId`, `:containerSetId`, `:containerId`),
        {
          exact: false,
        },
      )

    if (!containerId) {
      return
    }

    if (!isContainerUrlActive) {
      return
    }

    return [
      createItem({
        name: containerId,
        href: containerUrl(regionId, containerSetId, containerId),
      }),
    ]
  }

  hostNavigation(regionId) {
    const { routes, location } = this.props

    const params = getRouteParams<{ hostId: string }>(routes, location.pathname)
    const { hostId } = params

    const isHostUrlActive =
      hostId != null &&
      matchRoute(routes, location.pathname, hostUrl(`:regionId`, `:hostId`), {
        exact: false,
      })

    if (!hostId) {
      return
    }

    if (!isHostUrlActive) {
      return
    }

    return [
      createItem({
        name: hostId,
        href: hostUrl(regionId, hostId),
        'data-test-id': `platform-host-link`,
      }),
    ]
  }

  topologyNavigation() {
    const { routes, location, deploymentTemplate } = this.props

    const url = topologyViewDeploymentTemplateUrl(`:regionId`, `:templateId`)

    if (!matchRoute(routes, location.pathname, url)) {
      return
    }

    const { templateId } = getRouteParams<{ templateId: string }>(routes, location.pathname)

    return [
      createItem({
        name: deploymentTemplate ? deploymentTemplate.name : templateId!,
        href: url,
        isSelected: true,
      }),
    ]
  }
}

export default injectIntl(ChromeNavigation)

function renderNavLink({
  href = `/`,
  children,
  className,
  exact = false,
  beta = false,
  ...rest
}: RenderNavLinkProps): ReactElement {
  const classes = cx(className, {
    'euiSideNavItemButton-beta': beta,
  })

  const link = (
    <NavLink
      to={href}
      className={classes}
      activeClassName='euiSideNavItemButton-isSelected'
      exact={exact}
      {...rest}
    >
      {children}
    </NavLink>
  )

  if (!beta) {
    return link
  }

  return (
    <EuiFlexGroup gutterSize='s'>
      <EuiFlexItem grow={false}>{link}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiBetaBadge
          label={<FormattedMessage {...messages.beta} />}
          tooltipContent={<FormattedMessage {...messages.betaDescription} />}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

function createItem({ name, items, ...rest }: SideNavItem): EuiSideNavItem {
  return {
    name,
    id: name,
    items,
    forceOpen: true,
    ...rest,
  }
}

function setSelectedItem(
  items: EuiSideNavItem[],
  isSelected: (item: EuiSideNavItem) => boolean,
): EuiSideNavItem[] {
  return items.map(handleItemSelection)

  function handleItemSelection(item: EuiSideNavItem): EuiSideNavItem {
    if (isSelected(item)) {
      return {
        ...item,
        isSelected: true,
      }
    }

    if (isArray(item.items)) {
      return {
        ...item,
        items: setSelectedItem(item.items, isSelected),
      }
    }

    return item
  }
}

function isDeploymentActive({ routes, location }) {
  return matchRoute(routes, location.pathname, deploymentUrl(`:deploymentId`), {
    exact: false,
  })
}

function isCreateDeploymentActive({ routes, location }) {
  return matchRoute(routes, location.pathname, createDeploymentUrl())
}

function isCreateRepositoryActive({ routes, location }) {
  return matchRoute(routes, location.pathname, createSnapshotRepositoryUrl(`:regionId`))
}

function isEditSnapshotRepositoryActive({ routes, location }) {
  return matchRoute(
    routes,
    location.pathname,
    editSnapshotRepositoryUrl(`:regionId`, `:deploymentId`),
  )
}

function isPlatformActive(props) {
  const { routes, location } = props

  if (matchRoute(routes, location.pathname, regionsUrl(), { exact: false })) {
    return true
  }

  return (
    !isDeploymentActive(props) &&
    !isManagementActive(props) &&
    matchRoute(routes, location.pathname, regionUrl(`:regionId`), { exact: false })
  )
}

function isUserActive({ routes, location }) {
  return matchRoute(routes, location.pathname, userOverviewUrl(`:userId`), { exact: false })
}

function isUserCreateActive({ routes, location }) {
  return matchRoute(routes, location.pathname, userCreateUrl())
}

function isOrganizationActive({ routes, location }) {
  return matchRoute(routes, location.pathname, organizationOverviewUrl(`:organizationId`), {
    exact: false,
  })
}

function isManagementActive({ routes, location }) {
  return matchRoute(routes, location.pathname, manageRegionUrl(`:regionId`), { exact: false })
}

function isAdvancedDeploymentEditActive({ routes, location }) {
  return matchRoute(routes, location.pathname, deploymentAdvancedEditUrl(`:deploymentId`))
}
