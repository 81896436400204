/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../reducers'

import CustomSettingButton from './CustomSettingButton'

import type { AsyncRequestState } from '../../../types'
import type { ReactNode } from 'react'

type StateProps = {
  setCustomSettingRequest: AsyncRequestState
}

type DispatchProps = {
  setCustomSetting: () => void
  resetSetCustomSetting: () => void
}

type ConsumerProps = {
  organizationId: string
  allowPlugins: boolean
  allowBundles: boolean
  checked: boolean
  action: ReactNode
}

const mapStateToProps = (state): StateProps => ({
  setCustomSettingRequest: callStoredProcedureRequest(
    state,
    `toggle_bundles_and_custom_plugins_organization`,
  ),
})

const mapDispatchToProps = (
  dispatch,
  { organizationId, allowPlugins, allowBundles }: ConsumerProps,
): DispatchProps => ({
  setCustomSetting: () =>
    dispatch(
      callStoredProcedure({
        procedureName: `toggle_bundles_and_custom_plugins_organization`,
        parameters: [organizationId, String(Boolean(allowBundles)), String(Boolean(allowPlugins))],
        organizationId,
      }),
    ),
  resetSetCustomSetting: () => {
    dispatch(resetCallStoredProcedureRequest(`toggle_bundles_and_custom_plugins_organization`))
    return dispatch(clearProcedure(`toggle_bundles_and_custom_plugins_organization`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CustomSettingButton)
