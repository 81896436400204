/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { callStoredProcedureRequest } from '../../../../reducers'

import OrganizationFeatures from './OrganizationFeatures'

import type { AsyncRequestState, ReduxState } from '../../../../types'
import type { SaasOrganizationResponse } from '../../../../lib/api/v1/types'

type StateProps = {
  setCustomSettingRequest: AsyncRequestState
}

type ConsumerProps = {
  saasOrganization: SaasOrganizationResponse
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  setCustomSettingRequest: callStoredProcedureRequest(
    state,
    `toggle_bundles_and_custom_plugins_organization`,
  ),
})

export default connect<StateProps, null, ConsumerProps>(mapStateToProps)(OrganizationFeatures)
