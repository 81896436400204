/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiButtonEmpty,
  EuiCallOut,
  EuiSpacer,
} from '@elastic/eui'

import { supportUrl } from '@/lib/urlBuilder'
import { CuiLink } from '@/cui'

import CreditCardWithCircles from './CreditCardWithCircles'

import type { ReactElement } from 'react'

import './styles.scss'
export interface Props {
  onClose: () => void
  onSubmit: (code: string) => void
  inputError?: string
  generalError: boolean
  isLoading?: boolean
  isSubmitted: boolean
  onCodeChange: () => void
}

const formId = 'prepaid-credit-activation-form'

class PrepaidCreditActivationModal extends PureComponent<Props> {
  render(): ReactElement {
    const { onClose, onSubmit, onCodeChange, isLoading, isSubmitted, inputError, generalError } =
      this.props

    return (
      <EuiModal
        onClose={onClose}
        className={classNames('prepaidModal', { modalIsAnimating: isSubmitted })}
      >
        <CreditCardWithCircles />
        <EuiModalHeader>
          <EuiModalHeaderTitle className='modalHeaderTitle' data-test-id='modalTitle'>
            <h1>{this.getTitle()}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody data-test-id='modalBody'>
          <EuiForm
            id={formId}
            component='form'
            onSubmit={(event) => {
              event.preventDefault()
              const data = new FormData(event.target as HTMLFormElement)
              const code = data.get('activationCode') as string

              onSubmit(code)
            }}
          >
            <EuiFormRow
              isInvalid={Boolean(inputError)}
              error={inputError}
              fullWidth={true}
              label={
                <FormattedMessage
                  id='prepaid-credit-modal.label'
                  defaultMessage='Activation code'
                />
              }
            >
              <EuiFieldText
                name='activationCode'
                placeholder='XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
                fullWidth={true}
                isInvalid={Boolean(inputError)}
                isLoading={isLoading}
                disabled={isLoading || isSubmitted}
                onChange={onCodeChange}
                data-test-id='activationCodeTextField'
              />
            </EuiFormRow>
          </EuiForm>

          {generalError && this.renderGeneralError()}
        </EuiModalBody>

        <EuiModalFooter>{this.getFooter()}</EuiModalFooter>
      </EuiModal>
    )
  }

  renderGeneralError = (): JSX.Element => (
    <Fragment>
      <EuiSpacer size='m' />
      <EuiCallOut
        title={
          <FormattedMessage
            id='prepaid-credit-modal.general-error-title'
            defaultMessage='Something went wrong'
          />
        }
        color='danger'
        iconType='alert'
        data-test-id='generalError'
      >
        <FormattedMessage
          id='prepaid-credit-modal.general-error-message'
          defaultMessage="Try again. {support} if it still isn't working."
          values={{
            support: (
              <CuiLink to={supportUrl()}>
                <FormattedMessage
                  id='prepaid-credit-modal.contact-support'
                  defaultMessage='Contact support'
                />
              </CuiLink>
            ),
          }}
        />
      </EuiCallOut>
    </Fragment>
  )

  getTitle = (): JSX.Element => {
    const { isSubmitted } = this.props

    return isSubmitted ? (
      <FormattedMessage id='prepaid-credit-modal.title-submitted' defaultMessage='Credit applied' />
    ) : (
      <FormattedMessage id='prepaid-credit-modal.title' defaultMessage='Apply credit' />
    )
  }

  getFooter = (): JSX.Element => {
    const { onClose, isLoading, isSubmitted } = this.props

    return isSubmitted ? (
      <EuiButton fill={true} onClick={onClose} data-test-id='closeButton'>
        <FormattedMessage id='prepaid-credit-modal.close' defaultMessage='Close' />
      </EuiButton>
    ) : (
      <Fragment>
        <EuiButtonEmpty onClick={onClose} disabled={isLoading} data-test-id='closeButton'>
          <FormattedMessage id='prepaid-credit-modal.cancel' defaultMessage='Cancel' />
        </EuiButtonEmpty>

        <EuiButton
          type='submit'
          form={formId}
          fill={true}
          disabled={isLoading}
          data-test-id='submitButton'
        >
          <FormattedMessage id='prepaid-credit-modal.add' defaultMessage='Add' />
        </EuiButton>
      </Fragment>
    )
  }
}

export default PrepaidCreditActivationModal
