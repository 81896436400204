/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { cloneDeep, noop } from 'lodash'

import { EuiCallOut, EuiSwitch, EuiText, EuiTitle } from '@elastic/eui'

import { getEsPlan } from '@/lib/stackDeployments/selectors'

import type { DeploymentTemplateRequestBody } from '@/lib/api/v1/types'

export interface Props {
  deploymentTemplate: DeploymentTemplateRequestBody
  updateDeploymentTemplate?: (deploymentTemplate: DeploymentTemplateRequestBody) => void
}

class TemplateAutoscalingToggle extends Component<Props> {
  render() {
    const { deploymentTemplate, updateDeploymentTemplate } = this.props

    const plan = getEsPlan({ deployment: deploymentTemplate.deployment_template })
    const rawAutoscalingEnabled = plan?.autoscaling_enabled

    if (typeof rawAutoscalingEnabled === 'undefined') {
      return null
    }

    const autoscalingEnabled = Boolean(rawAutoscalingEnabled)

    return (
      <EuiCallOut>
        <EuiSwitch
          id='template-autoscaling-enable'
          data-test-id='template-autoscaling-enable'
          label={
            <Fragment>
              <EuiTitle size='xxs'>
                <h4>
                  <FormattedMessage
                    id='template-autoscaling-toggle.label'
                    defaultMessage='Enable autoscaling by default'
                  />
                </h4>
              </EuiTitle>
              <EuiText color='subdued' size='s'>
                <FormattedMessage
                  id='template-autoscaling-toggle.description'
                  defaultMessage='Autoscaling allows you to automatically monitor and adjust capacity, and maintain steady, predictable performance.'
                />
              </EuiText>
            </Fragment>
          }
          checked={autoscalingEnabled}
          onChange={updateDeploymentTemplate ? this.onChange : noop}
          disabled={!updateDeploymentTemplate}
        />
      </EuiCallOut>
    )
  }

  onChange = () => {
    const { updateDeploymentTemplate } = this.props

    if (!updateDeploymentTemplate) {
      return
    }

    const deploymentTemplate = cloneDeep(this.props.deploymentTemplate)

    const plan = getEsPlan({ deployment: deploymentTemplate.deployment_template })

    if (!plan) {
      return // sanity
    }

    plan.autoscaling_enabled = !plan.autoscaling_enabled

    updateDeploymentTemplate(deploymentTemplate)
  }
}

export default TemplateAutoscalingToggle
