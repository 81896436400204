/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiAlert } from '../../cui'
import schedule from '../../lib/schedule'

import type { FunctionComponent } from 'react'
import type { AsyncRequestState, Allocator as AllocatorType, Runner } from '../../types'

type Props = {
  regionId: string
  allocatorId: string
  allocator?: AllocatorType
  allocatorRequest: AsyncRequestState
  runner?: Runner
  fetchAllocator: (regionId: string, allocatorId: string) => void
  startManagingRoles: () => void
}

const Allocator: FunctionComponent<Props> = ({ runner, children }) => {
  if (!runner) {
    return (
      <CuiAlert type='error'>
        <FormattedMessage id='allocator-wrapper.host-not-found' defaultMessage='Host not found' />
      </CuiAlert>
    )
  }

  return <Fragment>{children}</Fragment>
}

export default schedule(
  Allocator,
  ({ regionId, allocatorId, fetchAllocator }: Props) => {
    fetchAllocator(regionId, allocatorId)
  },
  [`regionId`, `allocatorId`],
)
