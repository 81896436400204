/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withSmallErrorBoundary } from '../../cui'
import { fetchDeploymentTemplates } from '../../actions/topology/deploymentTemplates'
import {
  getCluster,
  getDeploymentTemplate,
  getOrganization,
  getSaasUser,
  getStackDeployment,
} from '../../reducers'
import { isFeatureActivated, getConfigForKey } from '../../selectors'
import { getVersion, getDeploymentTemplateId } from '../../lib/stackDeployments/selectors'
import { getRouteParams } from '../../lib/router'
import { withStackDeploymentRouteParams } from '../StackDeploymentEditor'
import { showApiKeys } from '../UserSettings/helpers'
import { hasPermission } from '../../lib/requiresPermission'
import Feature from '../../lib/feature'

import ChromeNavigation from './ChromeNavigation'

import type Permission from '../../lib/api/v1/permissions'
import type { ElasticsearchCluster, ReduxState } from '../../types'
import type {
  SaasUserResponse,
  DeploymentGetResponse,
  DeploymentTemplateInfoV2,
  Organization,
} from '../../lib/api/v1/types'
import type { WithStackDeploymentRouteParamsProps } from '../StackDeploymentEditor'
import type { RouteConfig } from 'react-router-config'

type StateProps = {
  deployment?: ElasticsearchCluster | null
  deploymentTemplate?: DeploymentTemplateInfoV2
  stackDeployment?: DeploymentGetResponse | null
  defaultRegionId?: string
  regionId?: string
  deploymentId?: string
  stackDeploymentId?: string
  saasUserProfile?: SaasUserResponse
  organization?: Organization
  lookupSaasUsers: boolean
  manageRbacFeature: boolean
  trafficFilteringFeature: boolean
  showApiKeys: boolean
  showUserApiKeys: boolean
  hasPermission: (permission: Permission) => boolean
}

type DispatchProps = {
  fetchDeploymentTemplates: (params: {
    regionId: string
    stackVersion: string
    showMaxZones: boolean
  }) => void
}

type OwnProps = {
  routes: RouteConfig[]
}

type ConsumerProps = WithStackDeploymentRouteParamsProps & OwnProps

type RouteParams = { userId: string; organizationId: string; templateId: string }

const mapStateToProps = (
  state: ReduxState,
  { regionId: regionIdParam, deploymentId, stackDeploymentId, routes, location }: ConsumerProps,
): StateProps => {
  const params = getRouteParams<RouteParams>(routes, location.pathname)
  const { userId, organizationId } = params
  const defaultRegionId = getConfigForKey(state, `DEFAULT_REGION`)
  const regionId = regionIdParam || defaultRegionId

  const rbacPermissions = isFeatureActivated(state, Feature.rbacPermissions)
  const manageRbacFeature = isFeatureActivated(state, Feature.manageRbac)
  const stackDeployment = getStackDeployment(state, stackDeploymentId)
  const deployment = getCluster(state, regionId, deploymentId!)

  const deploymentTemplate = resolveDeploymentTemplate({
    state,
    params,
    deployment,
    regionId,
    stackDeployment,
  })

  const isAnySaas = getConfigForKey(state, `APP_PLATFORM`) === `saas`

  return {
    regionId,
    deployment,
    deploymentTemplate,
    defaultRegionId,
    trafficFilteringFeature: isFeatureActivated(state, Feature.trafficFiltering),
    manageRbacFeature,
    lookupSaasUsers: isFeatureActivated(state, Feature.lookupSaasUsers),
    saasUserProfile: getSaasUser(state, userId!),
    organization: getOrganization(state, organizationId!),
    hasPermission: rbacPermissions || manageRbacFeature ? hasPermission : () => true,
    showUserApiKeys: isAnySaas,
    showApiKeys: showApiKeys(state),
    stackDeployment,
  }
}

const mapDispatchToProps: DispatchProps = {
  fetchDeploymentTemplates,
}

export default withSmallErrorBoundary(
  withStackDeploymentRouteParams<OwnProps>(
    connect<StateProps, DispatchProps, ConsumerProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(ChromeNavigation),
  ),
)

function resolveDeploymentTemplate({
  state,
  params,
  deployment,
  regionId,
  stackDeployment,
}: {
  state: ReduxState
  params: Partial<RouteParams>
  deployment?: ElasticsearchCluster | null
  stackDeployment?: DeploymentGetResponse | null
  regionId?: string
}) {
  // navigation inside a deployment, so use the template ID referenced in the deployment
  if (stackDeployment) {
    const deploymentTemplateId = getDeploymentTemplateId({ deployment: stackDeployment })
    const version = getVersion({ deployment: stackDeployment })

    return getDeploymentTemplate(state, regionId!, deploymentTemplateId!, version)
  }

  if (deployment && deployment.plan) {
    return getDeploymentTemplate(
      state,
      regionId!,
      deployment.deploymentTemplateId!,
      deployment.plan.version,
    )
  }

  const { templateId } = params

  // navigation in deployment template CRUD area, so use the template ID from url params
  if (regionId && templateId) {
    return getDeploymentTemplate(state, regionId, templateId)
  }
}
