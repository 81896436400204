/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

import { supportUrl } from '@/lib/urlBuilder'
import { CuiLink } from '@/cui'

import type { FunctionComponent } from 'react'
import type { AjaxRequestError } from '@/types'

type Props = {
  error: string | Error | AjaxRequestError
}

const MarketplaceErrorMessage: FunctionComponent<Props> = ({ error }) => {
  const errorCode = get(error, [`body`, `errors`, `0`, `code`], ``)

  if (errorCode === `user.marketplace.expired_token`) {
    return (
      <FormattedMessage
        id='user.marketplace.expired_token'
        defaultMessage='The token from the cloud provider has expired. Re-subscribe to the marketplace offer and try again.'
      />
    )
  }

  if (errorCode === `user.marketplace.invalid_token`) {
    return (
      <FormattedMessage
        id='user.marketplace.invalid_token'
        defaultMessage='We had some trouble, re-subscribe to the marketplace offer. If you still need help contact {support}.'
        values={{
          support: (
            <CuiLink to={supportUrl()}>
              <FormattedMessage
                id='user.marketplace-error.support'
                defaultMessage='support@elastic.co'
              />
            </CuiLink>
          ),
        }}
      />
    )
  }

  if (errorCode === `user.marketplace.already_subscribed`) {
    return (
      <FormattedMessage
        id='user.marketplace.already-subscribed'
        defaultMessage="This email account can't be used to subscribe to the marketplace. Use a different email address or contact {support}."
        values={{
          support: (
            <CuiLink to={supportUrl()}>
              <FormattedMessage
                id='user.marketplace-error.support'
                defaultMessage='support@elastic.co'
              />
            </CuiLink>
          ),
        }}
      />
    )
  }

  if (errorCode === `user.marketplace.incompatible_clusters`) {
    return (
      <FormattedMessage
        id='user.marketplace.incompatible_clusters'
        defaultMessage="There are active deployments in this account that don't match the marketplace cloud provider. Delete the deployment and re-subscribe to the marketplace offer. If you need help, contact {support}."
        values={{
          support: (
            <CuiLink to={supportUrl()}>
              <FormattedMessage
                id='user.marketplace-error.support'
                defaultMessage='support@elastic.co'
              />
            </CuiLink>
          ),
        }}
      />
    )
  }

  if (errorCode === `user.marketplace.invalid_marketplace`) {
    return (
      <FormattedMessage
        id='user.marketplace.invalid_marketplace'
        defaultMessage='There was a problem with your subscription, re-subscribe to the marketplace offer. If you need help, contact {support}'
        values={{
          support: (
            <CuiLink to={supportUrl()}>
              <FormattedMessage
                id='user.marketplace-error.support'
                defaultMessage='support@elastic.co'
              />
            </CuiLink>
          ),
        }}
      />
    )
  }

  if (errorCode === `user.marketplace.membership_not_found`) {
    return (
      <FormattedMessage
        id='user.marketplace.invalid_user'
        defaultMessage='User does not belong to any organization.'
      />
    )
  }

  return (
    <FormattedMessage
      id='user.marketplace.generic_error'
      defaultMessage='Re-subscribe to the marketplace offer. If the problem persists, contact {support}.'
      values={{
        support: (
          <CuiLink to={supportUrl()}>
            <FormattedMessage
              id='user.marketplace-error.support'
              defaultMessage='support@elastic.co'
            />
          </CuiLink>
        ),
      }}
    />
  )
}

export default MarketplaceErrorMessage
