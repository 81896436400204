/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui'

import type { ReactElement } from 'react'

interface Props {
  errors: Record<string, string>
  values: Record<string, string>
  onUpdate: ({ name, value }: { name: string; value: string }) => void
}

class MarketplaceActivationForm extends PureComponent<Props> {
  render(): ReactElement {
    const { errors, values } = this.props
    const { company, fullName } = values

    return (
      <EuiForm>
        <EuiFormRow
          isInvalid={!!errors.fullName}
          error={errors.fullName}
          label={
            <FormattedMessage
              id='marketplace-conversion-form.full-name'
              defaultMessage='Full name'
            />
          }
        >
          <EuiFieldText
            name='fullName'
            isInvalid={!!errors.fullName}
            value={fullName}
            onChange={this.onChange}
          />
        </EuiFormRow>

        <EuiFormRow
          isInvalid={!!errors.company}
          error={errors.company}
          label={
            <FormattedMessage
              id='marketplace-conversion-form.company'
              defaultMessage='Company or Organization'
            />
          }
        >
          <EuiFieldText
            name='company'
            isInvalid={!!errors.company}
            value={company}
            onChange={this.onChange}
          />
        </EuiFormRow>
      </EuiForm>
    )
  }

  onChange = (e: React.SyntheticEvent): void => {
    const { onUpdate } = this.props
    const value = (e.target as HTMLSelectElement).value
    const name = (e.target as HTMLSelectElement).name
    onUpdate({ name, value })
  }
}

export default MarketplaceActivationForm
