/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'url'

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash'

import LandingPage from '../../../../../components/LandingPage'
import { loginUrl, rootUrl } from '../../../../../lib/urlBuilder'

import type { RouteComponentProps } from 'react-router'

interface QueryStringParameters {}

interface StateProps {}

type DispatchProps = {
  saveToken: (token: string) => void
  redirect: (redirectTo: string) => void
}

type ConsumerProps = RouteComponentProps<QueryStringParameters>

type Props = StateProps & DispatchProps & ConsumerProps

class SsoReceiveToken extends Component<Props> {
  componentDidMount() {
    const { saveToken, redirect, location } = this.props
    getTokenAndStateThenRedirect({ saveToken, redirect, location })
  }

  render() {
    return (
      <LandingPage loading={true}>
        <div>
          <FormattedMessage
            id='sso-receive-token.authenticating'
            defaultMessage='Authenticating …'
          />
        </div>
      </LandingPage>
    )
  }
}

function getToken(token) {
  return token.replace(`#`, ``)
}

export function getTokenAndStateThenRedirect({
  saveToken,
  redirect,
  location,
}: {
  saveToken: Props['saveToken']
  redirect: Props['redirect']
  location: Props['location']
}) {
  const { hash } = location

  // if for whatever reason we don't have a hash, then we don't have a token or a state, so just fail gracefully
  if (isEmpty(hash)) {
    return redirect(loginUrl())
  }

  // The API sends back the nextState and the token in this format:
  // /oauth/token/#:tokenId?state=%7B%22redirectTo%22:%22/login%22%7D
  // So first we split off the tokenId and the state and parse each one to get us the values we need
  const splitHash = hash.split(`?`)

  const tokenRedirect = getTokenRedirect(hash.slice(1))
  const token = getToken(splitHash[0])

  // the token can sometimes be empty, in which case they can't login anyways so fail gracefully
  if (isEmpty(token)) {
    return redirect(loginUrl())
  }

  saveToken(token)
  redirect(tokenRedirect)
}

/**
  @input encodedState: looks like ?state=%7B%22redirectTo%22:%22/login%22%7D
  @return redirectTo: e.g /deployments or null
*/
export function getTokenRedirect(hash) {
  const { query } = parse(hash, true)
  const stateRaw = query.state

  // this can be weird if there is no `state` in the url, in which case it is undefined
  if (!stateRaw) {
    return rootUrl()
  }

  const stateString = Array.isArray(stateRaw) ? stateRaw.join(`,`) : stateRaw
  const state = JSON.parse(stateString)
  const { redirectTo } = state

  return redirectTo
}

export default SsoReceiveToken
