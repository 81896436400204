/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

import {
  EuiLoadingContent,
  EuiButtonEmpty,
  EuiText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiSteps,
} from '@elastic/eui'

import history from '@/lib/history'
import { createDeploymentTrustRelationshipUrl, securityUrl } from '@/lib/urlBuilder'
import { getOrganizationId } from '@/lib/stackDeployments/selectors'

import { CuiAlert } from '../../../cui'
import {
  createUpdateTrustRequestFromGetResponse,
  getTrustLevelFromRelationship,
  getTrustRelationshipFromDeployment,
  getTrustRelationshipId,
} from '../../../lib/stackDeployments/trustRelationships'
import EnterOrganizationId from '../components/EnterOrganizationId'
import EnterOtherAccountRemoteDeployments from '../components/EnterOtherAccountRemoteDeployments'
import TrustLevelSelector from '../components/TrustLevelSelector'
import SelectOwnAccountRemoteDeployments from '../components/SelectOwnAccountRemoteDeployments'

import type { WrappedComponentProps } from 'react-intl'
import type { TrustLevel } from '@/lib/stackDeployments/selectors/crossClusterReplication'
import type { AccountTrustRelationship } from '../../../lib/api/v1/types'
import type { AllProps } from './types'

interface State {
  trustLevel: TrustLevel
  trustedEnvironmentId: string
  trustedClusterIds: string[]
}

const messages = defineMessages({
  organizationStepTitle: {
    id: 'deploymentTrustManagement.account.stepTitles.organization',
    defaultMessage: 'Add organization ID',
  },
  deploymentsStepTitle: {
    id: 'deploymentTrustManagement.account.stepTitles.deployments',
    defaultMessage: 'Select trusted deployments',
  },
})

class ManageAccountTrustRelationship extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = this.createInitialState()

  componentDidMount(): void {
    this.props.fetchCurrentAccount()
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element {
    const { fetchCurrentAccountRequest } = this.props

    if (!fetchCurrentAccountRequest.isDone) {
      return <EuiLoadingContent />
    }

    return (
      <Fragment>
        {this.renderForm()}
        {this.renderError()}
        {this.renderButtons()}
      </Fragment>
    )
  }

  renderForm(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props

    const trustDetailsStep = {
      title: formatMessage(messages.deploymentsStepTitle),
      children: this.renderDeploymentsFields(),
    }

    if (this.isDeploymentInUsersOrg()) {
      return trustDetailsStep.children
    }

    if (this.isEditing()) {
      return trustDetailsStep.children
    }

    const organizationStep = {
      title: formatMessage(messages.organizationStepTitle),
      children: this.renderOrganizationFields(),
    }

    return <EuiSteps steps={[organizationStep, trustDetailsStep]} />
  }

  renderOrganizationFields(): JSX.Element | null {
    return (
      <Fragment>
        <EuiText>
          <FormattedMessage
            id='deploymentTrustManagement.account.organizationInstructions'
            defaultMessage='Find the ID near the Organization name.'
          />
        </EuiText>
        <EuiSpacer />
        <EuiFormRow>
          <EnterOrganizationId
            organizationId={this.state.trustedEnvironmentId}
            onChange={(organizationId) => {
              this.setState({ trustedEnvironmentId: organizationId })
            }}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderDeploymentsFields(): JSX.Element {
    const { deployment, userOrgId } = this.props
    const { trustLevel, trustedClusterIds } = this.state

    return (
      <Fragment>
        <EuiFormRow>
          <TrustLevelSelector
            trustLevel={trustLevel}
            onChange={(trustLevel) => {
              this.setState({ trustLevel })
            }}
            showNone={userOrgId === getOrganizationId({ deployment })}
          />
        </EuiFormRow>

        {trustLevel === `specific` && (
          <Fragment>
            <EuiSpacer />

            {this.isDeploymentInUsersOrg() ? (
              <SelectOwnAccountRemoteDeployments
                deployment={deployment}
                trustedClusterIds={trustedClusterIds}
                onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
              />
            ) : (
              <EnterOtherAccountRemoteDeployments
                trustedClusterIds={trustedClusterIds}
                onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderError(): JSX.Element | null {
    const { updateStackDeploymentRequest } = this.props

    if (!updateStackDeploymentRequest.error) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <CuiAlert type='danger' data-test-id='update-deployment-request-error'>
          {updateStackDeploymentRequest.error}
        </CuiAlert>
      </EuiFlexItem>
    )
  }

  renderButtons(): JSX.Element {
    const { deployment, updateStackDeploymentRequest } = this.props

    const isDisabled =
      !this.state.trustedEnvironmentId ||
      (this.state.trustLevel === 'specific' && this.state.trustedClusterIds.length === 0)

    return (
      <Fragment>
        <EuiSpacer size='xl' />
        <EuiFlexGroup justifyContent='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiButton
              type='button'
              data-test-id='save-trust-relationship-button'
              disabled={isDisabled}
              onClick={() => this.onSave()}
              isLoading={updateStackDeploymentRequest.inProgress}
              fill={true}
            >
              {this.isEditing() ? (
                <FormattedMessage
                  id='deploymentTrustManagement.account.submitButton.edit'
                  defaultMessage='Update trust'
                />
              ) : (
                <FormattedMessage
                  id='deploymentTrustManagement.account.submitButton.create'
                  defaultMessage='Create trust'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
          {!this.isEditing() && (
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                onClick={() =>
                  history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))
                }
              >
                <FormattedMessage id='deploymentTrustManagement.back' defaultMessage='< Back' />
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
              <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  isEditing(): boolean {
    return Boolean(this.props.match.params.trustRelationshipId)
  }

  isDeploymentInUsersOrg(): boolean {
    const { userOrgId } = this.props
    return userOrgId === this.state.trustedEnvironmentId
  }

  createInitialState(): State {
    const {
      deployment,
      match: {
        params: { trustRelationshipId },
      },
    } = this.props

    if (trustRelationshipId) {
      const trustRelationship = getTrustRelationshipFromDeployment({
        deployment,
        trustRelationshipType: 'accounts',
        trustRelationshipId,
      })

      if (trustRelationship) {
        return {
          trustLevel: getTrustLevelFromRelationship(trustRelationship),
          trustedEnvironmentId: getTrustRelationshipId({ trustRelationship }),
          trustedClusterIds: trustRelationship.trust_allowlist || [],
        }
      }
    }

    return {
      trustLevel: `all`,
      trustedEnvironmentId: ``,
      trustedClusterIds: [],
    }
  }

  getTrustRelationshipFromState(): AccountTrustRelationship {
    const { trustedEnvironmentId, trustLevel, trustedClusterIds } = this.state

    const trustFields = {
      account_id: trustedEnvironmentId,
      trust_all: trustLevel === `all`,
    }

    const optionalFields = trustLevel === `specific` ? { trust_allowlist: trustedClusterIds } : {}

    return { ...trustFields, ...optionalFields }
  }

  onSave(): void {
    const { deployment, updateStackDeployment } = this.props

    const trustRelationship = this.getTrustRelationshipFromState()

    const payload = createUpdateTrustRequestFromGetResponse({
      deployment,
      trustRelationships: [trustRelationship],
      type: `accounts`,
    })

    updateStackDeployment(payload).then(() => {
      history.push(securityUrl(deployment.id))
    })
  }
}

export default injectIntl(ManageAccountTrustRelationship)
