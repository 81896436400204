/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { withStackDeploymentRouteParams } from '@/components/StackDeploymentEditor'
import {
  fetchTrustRelationshipsRequest,
  getStackDeployment,
  getTrustRelationships,
  updateStackDeploymentRequest,
} from '@/reducers'
import { fetchTrustRelationships, resetFetchTrustRelationships } from '@/actions/trustManagement'

import { updateDeployment, resetUpdateDeployment } from '../../../actions/stackDeployments'

import ManageExternalTrustRelationship from './ManageExternalTrustRelationship'

import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { ReduxState, ThunkDispatch } from '../../../types'
import type { StateProps, DispatchProps, ReceivedProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  { regionId, stackDeploymentId }: ReceivedProps & WithStackDeploymentRouteParamsProps,
): StateProps => ({
  deployment: getStackDeployment(state, stackDeploymentId)!,
  trustRelationships: getTrustRelationships(state, regionId),
  fetchTrustRelationshipsRequest: fetchTrustRelationshipsRequest(state, regionId),
  updateStackDeploymentRequest: updateStackDeploymentRequest(state, stackDeploymentId),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { regionId, stackDeploymentId }: ReceivedProps & WithStackDeploymentRouteParamsProps,
): DispatchProps => ({
  fetchTrustRelationships: () =>
    dispatch(
      fetchTrustRelationships({
        regionId,
      }),
    ),
  resetFetchTrustRelationships: () => dispatch(resetFetchTrustRelationships(regionId)),
  updateStackDeployment: (payload) =>
    dispatch(
      updateDeployment({ deploymentId: stackDeploymentId, deployment: payload, redirect: false }),
    ),
  resetUpdateStackDeployment: () => dispatch(resetUpdateDeployment(stackDeploymentId!)),
})

export default withStackDeploymentRouteParams(
  connect<StateProps, DispatchProps, ReceivedProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(ManageExternalTrustRelationship),
)
