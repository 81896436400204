/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { EuiButtonIcon, EuiContextMenuPanel, EuiPopover } from '@elastic/eui'

import DeleteDeploymentButton from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/DeleteDeploymentButton'
import RestartStoppedDeployment from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/RestartStoppedDeployment'
import RestartDeployment from '@/components/StackDeployments/StackDeploymentAdminActions/StackDeploymentAdminActionsDropdown/RestartDeployment'
import { isEsStopped } from '@/lib/stackDeployments/selectors'

import type { WrappedComponentProps } from 'react-intl'
import type { DeploymentSearchResponse } from '@/lib/api/v1/types'

interface Props extends WrappedComponentProps {
  deployment: DeploymentSearchResponse
  onDeploymentAction: () => void
}

interface State {
  isOpen: boolean
}

class DeploymentActionsPopover extends PureComponent<Props, State> {
  state = {
    isOpen: false,
  }

  render() {
    const { isOpen } = this.state

    return (
      <EuiPopover
        button={this.renderButton()}
        isOpen={isOpen}
        closePopover={this.onClose}
        initialFocus={false}
        anchorPosition='leftCenter'
        panelPaddingSize='none'
      >
        <EuiContextMenuPanel items={this.getButtons()} />
      </EuiPopover>
    )
  }

  renderButton() {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiButtonIcon
        onClick={this.onButtonClick}
        iconType='boxesVertical'
        aria-label={formatMessage({
          id: 'deployments-table.actions',
          defaultMessage: 'Deployment actions',
        })}
      />
    )
  }

  renderRestartButton = () => {
    const { deployment } = this.props
    const stopped = isEsStopped({ deployment })
    const deploymentId = deployment.id

    if (stopped) {
      return (
        <RestartStoppedDeployment
          key={`RestartStoppedDeployment-${deploymentId}`}
          deployment={deployment}
          onRestart={this.onDeploymentAction}
        />
      )
    }

    return (
      <RestartDeployment
        key={`RestartDeployment-${deploymentId}`}
        deployment={deployment}
        onRestart={this.onDeploymentAction}
      />
    )
  }

  getButtons() {
    const { deployment } = this.props
    return [
      this.renderRestartButton(),
      <DeleteDeploymentButton
        deployment={deployment}
        key={`DeleteDeployment-${deployment.id}`}
        onHideDeploymentInUserconsole={this.onDeploymentAction}
      />,
    ]
  }

  onDeploymentAction = () => {
    const { onDeploymentAction } = this.props
    onDeploymentAction()
    this.onClose()
  }

  onButtonClick = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }
}

export default injectIntl(DeploymentActionsPopover)
