/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { head, flatMap } from 'lodash'

import type { EuiSuperSelectOption } from '@elastic/eui'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiIcon,
  EuiLink,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert, CuiButton, addToast } from '../../cui'
import { getDeploymentTopologyInstances } from '../../lib/stackDeployments/selectors'
import { getSliderPrettyName, getSliderDefinition } from '../../lib/sliders'
import { getInstanceNumber } from '../../reducers/clusters/createCluster'

import HeapDumpsTable from './HeapDumpsTable'

import type { HeapDump } from '../../types/heapDump'
import type {
  StackDeployment,
  AsyncRequestState,
  SliderInstanceType,
  InstanceSummary,
} from '../../types'

export interface Props {
  deployment: StackDeployment
  fetchHeapDumps: (deployment: StackDeployment) => void
  fetchHeapDumpsRequest: AsyncRequestState
  getHeapDumpDownloadUrl: (resourceKind: string, refId: string, instanceId: string) => string
  heapDumps?: HeapDump[]
  startHeapDumpCapture: (
    deployment: StackDeployment,
    resourceKind: SliderInstanceType,
    refId: string,
    instanceId: string,
  ) => Promise<void>
  startHeapDumpCaptureRequest: (refId: string, instanceId: string) => AsyncRequestState
  showSupportInstructions?: boolean
}

interface State {
  selectedInstance: InstanceSummary | undefined
}

const supportedSliderInstanceTypes: SliderInstanceType[] = ['elasticsearch', 'enterprise_search']

class ManageHeapDumps extends Component<Props, State> {
  state: State = this.getInitialState()

  getInitialState(): State {
    const instances = flatMap(supportedSliderInstanceTypes, (sliderInstanceType) =>
      getDeploymentTopologyInstances({
        deployment: this.props.deployment,
        sliderInstanceType,
      }),
    )

    return {
      selectedInstance: head(instances),
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderTopSection()}
        {this.renderTable()}
      </Fragment>
    )
  }

  renderTopSection() {
    const { showSupportInstructions } = this.props

    return (
      <EuiFlexGroup gutterSize='m' justifyContent='spaceBetween'>
        <EuiFlexItem>
          <EuiText>
            <strong>
              <FormattedMessage
                id='heapDumps.capture-subtitle'
                defaultMessage='Capture on-demand heap dumps'
              />
            </strong>

            <EuiSpacer size='s' />

            <FormattedMessage
              id='heapDumps.capture-description'
              defaultMessage='Capture an on-demand heap dump to see how JVM memory is being used. Note that the JVM is paused and unresponsive during a capture.'
            />
            {showSupportInstructions && ` `}
            {showSupportInstructions && (
              <FormattedMessage
                id='heapDumps.capture-description-support'
                data-test-id='heapDumps.supportInstructions'
                defaultMessage=" Consult with the deployment owner before proceeding and follow Elastic's {dataPolicy} when handling heap dumps."
                values={{
                  dataPolicy: (
                    <EuiLink
                      href='https://wiki.elastic.co/display/CC/Handling+Customer+Data'
                      target='_blank'
                    >
                      <FormattedMessage
                        id='heapDumps.capture-description-support-data-policy'
                        defaultMessage='data policy'
                      />
                    </EuiLink>
                  ),
                }}
              />
            )}
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>{this.renderStartCaptureSection()}</EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderStartCaptureSection() {
    const { startHeapDumpCaptureRequest } = this.props
    const { selectedInstance } = this.state

    const instances = flatMap(supportedSliderInstanceTypes, (sliderInstanceType) =>
      getDeploymentTopologyInstances({
        deployment: this.props.deployment,
        sliderInstanceType,
      }),
    )

    if (instances.length === 0 || !selectedInstance) {
      return null
    }

    function formatValue(resourceKind: SliderInstanceType, instanceId: string): string {
      return `${resourceKind}:${instanceId}`
    }

    function parseValue(value: string): { resourceKind: SliderInstanceType; instanceId: string } {
      const parts = value.split(':')
      return {
        resourceKind: parts[0],
        instanceId: parts[1],
      }
    }

    const options: Array<EuiSuperSelectOption<string>> = instances.map((instanceInfo) => {
      const { messages: sliderMessages, iconType } = getSliderDefinition({
        sliderInstanceType: instanceInfo.kind,
      })
      const instanceLabel = ` - #${getInstanceNumber(instanceInfo.instance.instance_name)}`

      const display = (
        <EuiFlexGroup gutterSize='s' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiIcon type={iconType} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <span>
              <FormattedMessage {...sliderMessages.prettyName} />
              {instanceLabel}
            </span>
          </EuiFlexItem>
        </EuiFlexGroup>
      )

      return {
        inputDisplay: display,
        dropdownDisplay: display,
        value: formatValue(instanceInfo.kind, instanceInfo.instance.instance_name),
      }
    })

    const startInstanceHeapDumpCaptureRequest = startHeapDumpCaptureRequest(
      selectedInstance.resource.ref_id,
      selectedInstance.instance.instance_name,
    )

    return (
      <EuiFlexGroup gutterSize='m' style={{ width: '25rem' }} direction='column'>
        <EuiFlexItem grow={false}>
          <EuiFormLabel>
            <FormattedMessage id='heapDumps.capture-instance-label' defaultMessage='Instance' />
          </EuiFormLabel>

          <EuiSpacer size='xs' />

          <EuiSuperSelect
            data-test-id='heapDumps.instanceSelect'
            options={options}
            valueOfSelected={formatValue(
              selectedInstance.kind,
              selectedInstance.instance.instance_name,
            )}
            onChange={(value) => {
              const parsed = parseValue(value)

              const newSelectedInstance = instances.find(
                (instanceInfo) =>
                  instanceInfo.instance.instance_name === parsed.instanceId &&
                  instanceInfo.kind === parsed.resourceKind,
              )

              this.setState({ selectedInstance: newSelectedInstance })
            }}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <CuiButton
            color='primary'
            spin={startInstanceHeapDumpCaptureRequest.inProgress}
            onClick={() =>
              this.startCapture(
                selectedInstance.kind,
                selectedInstance.resource.ref_id,
                selectedInstance.instance.instance_name,
              )
            }
            confirm={true}
            confirmModalProps={{
              title: (
                <FormattedMessage
                  id='heapDumps.start-capture-confirmation-title'
                  defaultMessage='Capture heap dump?'
                />
              ),
              body: (
                <FormattedMessage
                  id='heapDumps.start-capture-confirmation-body'
                  defaultMessage='Take a heap dump capture of {sliderName} {instanceId}? The JVM will be paused and unresponsive during a capture.'
                  values={{
                    sliderName: (
                      <FormattedMessage
                        {...getSliderPrettyName({ sliderInstanceType: selectedInstance.kind })}
                      />
                    ),
                    instanceId: <strong>{selectedInstance.instance.instance_name}</strong>,
                  }}
                />
              ),
              confirm: (
                <FormattedMessage
                  id='heapDumps.start-capture-confirmation-button'
                  defaultMessage='Start capture'
                />
              ),
            }}
          >
            <FormattedMessage id='heapDumps.start-capture-button' defaultMessage='Start capture' />
          </CuiButton>
        </EuiFlexItem>

        {startInstanceHeapDumpCaptureRequest.error && (
          <EuiFlexItem grow={false}>
            <Fragment>
              <CuiAlert type='error'>{startInstanceHeapDumpCaptureRequest.error}</CuiAlert>
            </Fragment>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  renderTable() {
    const { deployment, heapDumps, fetchHeapDumps, getHeapDumpDownloadUrl } = this.props

    return (
      <Fragment>
        <EuiSpacer size='xxl' />

        <EuiTitle>
          <h2>
            <FormattedMessage id='heapDumps.tableTitle' defaultMessage='Heap dump captures' />
          </h2>
        </EuiTitle>

        <EuiSpacer size='m' />

        <EuiText>
          <FormattedMessage
            id='heapDumps.tableBody'
            defaultMessage='On-demand and out-of-memory (OOM) heap dump captures will be listed below. OOM heap dumps are captured by default when an out-of-memory error is thrown. For each instance, only one capture can exist at a time; a new capture overwrites the old.'
          />
        </EuiText>

        <EuiSpacer size='m' />

        <HeapDumpsTable
          heapDumps={heapDumps}
          fetchHeapDumps={() => fetchHeapDumps(deployment)}
          getHeapDumpDownloadUrl={getHeapDumpDownloadUrl}
        />
      </Fragment>
    )
  }

  startCapture(resourceKind, refId, instanceId) {
    const { deployment, fetchHeapDumps, startHeapDumpCapture } = this.props

    startHeapDumpCapture(deployment, resourceKind, refId, instanceId)
      .then(() => fetchHeapDumps(deployment))
      .then(startHeapDumpCaptureSuccessToast, startHeapDumpCaptureFailToast)
  }
}

export default ManageHeapDumps

export const startHeapDumpCaptureSuccessToast = () =>
  addToast({
    id: 'startHeapDumpCaptureSuccess',
    family: 'heapDumps.start-capture.success',
    iconType: 'inspect',
    title: (
      <FormattedMessage id='heapDumps.start-capture.title' defaultMessage='Heap dump capture' />
    ),
    text: (
      <FormattedMessage
        id='heapDumps.start-capture.success-text'
        defaultMessage='Started capturing heap dump.'
      />
    ),
    color: 'success',
  })

export const startHeapDumpCaptureFailToast = () =>
  addToast({
    id: 'startHeapDumpCaptureFail',
    family: 'heapDumps.start-capture.fail',
    iconType: 'inspect',
    title: (
      <FormattedMessage id='heapDumps.start-capture.title' defaultMessage='Heap dump capture' />
    ),
    text: (
      <FormattedMessage
        id='heapDumps.start-capture.fail-text'
        defaultMessage='Heap dump capture failed to start.'
      />
    ),
    color: 'danger',
  })
