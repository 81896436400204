/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { activateMarketplaceUserRequest } from '../../../../../reducers/asyncRequests/registry'
import { fetchProfile } from '../../../actions/profile'
import { activateMarketplaceUser } from '../../../../../actions/saasUsers'
import { getProfile, getMarketplacePartner } from '../../../reducers'

import MarketplaceActivation from './MarketplaceActivation'

import type { ReduxState, ThunkDispatch } from '../../../../../types'
import type { StateProps, DispatchProps } from './types'

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  activateMarketplaceUser: (payload) => dispatch(activateMarketplaceUser(payload)),
  fetchProfile: () => dispatch(fetchProfile()),
})

const mapStateToProps = (state: ReduxState): StateProps => ({
  partner: getMarketplacePartner(state),
  profile: getProfile(state),
  activateMarketplaceUserRequest: activateMarketplaceUserRequest(state),
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MarketplaceActivation)
