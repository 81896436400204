/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHealth } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@/cui/formatters/CuiElasticConsumptionUnits'
import { CuiTable, CuiHelpTipIcon } from '@/cui'

import { buildCapacityItems, buildDataTransferItems, buildStorageItems } from './lib/tableHelpers'

import type { AllProps, DimensionData } from './types'
import type { CuiTableColumn } from '@/cui'

import './deploymentUsageTable.scss'

class DeploymentUsageTable extends Component<AllProps> {
  render() {
    const { itemCosts, deployment, deploymentTemplate, isLoading } = this.props
    const columns = this.buildColumns()
    const hasMissingData = !itemCosts || !deployment

    if (isLoading || hasMissingData) {
      return <CuiTable rows={[]} initialLoading={true} columns={columns} />
    }

    return (
      <CuiTable
        className='billing-deployment-usage-table'
        responsive={false}
        hasFooterRow={true}
        rows={[
          ...buildCapacityItems(deployment, deploymentTemplate, itemCosts),
          ...buildDataTransferItems(itemCosts),
          ...buildStorageItems(itemCosts),
        ]}
        columns={columns}
      />
    )
  }

  buildColumns(): Array<CuiTableColumn<DimensionData>> {
    const { itemCosts } = this.props

    return [
      {
        key: 'usage-type',
        label: (
          <FormattedMessage id='billing-deployment-usage.usage-type' defaultMessage='Usage type' />
        ),
        render: ({ dimension, color }) =>
          dimension ? <EuiHealth color={color}>{dimension}</EuiHealth> : '',
        width: '150px',
        footer: {
          render: () => (
            <span>
              <FormattedMessage id='billing-deployment-usage.total' defaultMessage='Total usage' />
              <CuiHelpTipIcon iconType='iInCircle'>
                <FormattedMessage
                  id='billing-deployment-usage.tip-icon'
                  defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
                />
              </CuiHelpTipIcon>
            </span>
          ),
        },
      },
      {
        key: 'components',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-component'
            defaultMessage='Components'
          />
        ),
        render: ({ component }) => component,
        footer: { render: () => null },
        header: {
          className: 'components-header',
        },
      },
      {
        key: 'size',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-size'
            defaultMessage='Latest size (zones x storage | RAM | vCPU)'
          />
        ),
        render: ({ size }) => size,
        footer: { render: () => null },
        header: {
          className: 'size-header',
        },
      },
      {
        key: 'quantity',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-quantity'
            defaultMessage='Quantity'
          />
        ),
        render: ({ quantity }) => quantity,
        width: '150px',
        footer: { render: () => null },
        align: 'right',
      },
      {
        key: 'hourly-rate',
        label: (
          <FormattedMessage
            id='billing-deployment-usage.usage-rate'
            defaultMessage='Latest hourly rate'
          />
        ),
        render: ({ rate }) => rate,
        footer: { render: () => null },
        width: '180px',
        align: 'right',
      },
      {
        key: 'total',
        label: (
          <FormattedMessage id='billing-deployment-usage.usage-total' defaultMessage='Total' />
        ),
        render: ({ total }) => <CuiElasticConsumptionUnits value={total as number} unit='none' />,
        width: '100px',
        footer: {
          render: () => (
            <CuiElasticConsumptionUnits value={itemCosts?.costs.total || 0} unit='none' />
          ),
        },
        align: 'right',
      },
    ]
  }
}

export default DeploymentUsageTable
