/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescriptionList } from '@elastic/eui'
import type { EuiDescriptionListProps } from '@elastic/eui'

import { CuiDate } from '@/cui'

import PageSection from '../../PageSection'

import type { UserProfile } from '@/types'
import type { SaasOrganizationResponse } from '@/lib/api/v1/types'
import type { FunctionComponent } from 'react'

type EuiDescriptionListItems = Required<EuiDescriptionListProps>['listItems']

interface CommonMarketplaceInfo<Domain extends UserProfile['domain'] | undefined = undefined> {
  domain?: Domain
}

interface AzureMarketplaceInfo extends CommonMarketplaceInfo<'azure'> {
  azure_subscribed: boolean
  azure_account_id?: string
  azure_plan_id?: string
  azure_subscription_id?: string
  azure_tenant_id?: string
  azure_unsubscribed_at?: string
}

interface GCPMarketplaceInfo extends CommonMarketplaceInfo<'gcp'> {
  gcp_customer_id?: string
  gcp_entitlement_id?: string
  gcp_subscribed: boolean
  gcp_subscribed_annual?: boolean
  gcp_unsubscribed_at?: string
}

interface AWSMarketplaceInfo extends CommonMarketplaceInfo<'aws'> {
  aws_customer_id?: string
  aws_subscribed: boolean
}

type MarketplaceInfoType = AzureMarketplaceInfo | GCPMarketplaceInfo | AWSMarketplaceInfo

type GetListItems<Info> = (info: Info) => EuiDescriptionListItems

const notAvailableMsg = (
  <FormattedMessage id='marketplace-information.not-available.text' defaultMessage='N/A' />
)

const getAzureMarketplaceInfoList: GetListItems<AzureMarketplaceInfo> = ({
  azure_account_id,
  azure_plan_id,
  azure_subscribed,
  azure_subscription_id,
  azure_tenant_id,
  azure_unsubscribed_at,
}) => [
  {
    title: <FormattedMessage id='azure-account-id.title' defaultMessage='Azure user account' />,
    description: azure_account_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage
        id='azure-subscribed.title'
        defaultMessage='Azure marketplace subscription'
      />
    ),
    description: azure_subscribed ? (
      <FormattedMessage id='subscription-active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='subscription-inactive' defaultMessage='Inactive' />
    ),
  },
  {
    title: <FormattedMessage id='azure-plan-id.title' defaultMessage='Azure marketplace plan ID' />,
    description: azure_plan_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage
        id='azure-subscription-id.title'
        defaultMessage='Azure marketplace subscription ID'
      />
    ),
    description: azure_subscription_id || notAvailableMsg,
  },
  {
    title: <FormattedMessage id='azure-tenant-id.title' defaultMessage='Azure tenant ID' />,
    description: azure_tenant_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage
        id='azure-unsubscribed-at.title'
        defaultMessage='Azure marketplace unsubscribe date'
      />
    ),
    description: azure_unsubscribed_at ? <CuiDate date={azure_unsubscribed_at} /> : notAvailableMsg,
  },
]

const getGCPMarketplaceInfoList: GetListItems<GCPMarketplaceInfo> = ({
  gcp_customer_id,
  gcp_subscribed,
  gcp_entitlement_id,
  gcp_subscribed_annual,
  gcp_unsubscribed_at,
}) => [
  {
    title: <FormattedMessage id='gcp-customer-id.title' defaultMessage='GCP customer ID' />,
    description: gcp_customer_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage id='gcp-subscribed.title' defaultMessage='GCP marketplace subscription' />
    ),
    description: gcp_subscribed ? (
      <FormattedMessage id='subscription-active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='subscription-inactive' defaultMessage='Inactive' />
    ),
  },
  {
    title: <FormattedMessage id='gcp-entitlement-id.title' defaultMessage='GCP entitlement ID' />,
    description: gcp_entitlement_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage
        id='gcp-subscribed-annual.title'
        defaultMessage='Is annual GCP marketplace subscription?'
      />
    ),
    description: gcp_subscribed_annual ? (
      <FormattedMessage id='subscription-yes' defaultMessage='Yes' />
    ) : (
      <FormattedMessage id='subscription-no' defaultMessage='No' />
    ),
  },
  {
    title: (
      <FormattedMessage
        id='gcp-unsubscribed-at.title'
        defaultMessage='GCP marketplace unsubscribe date'
      />
    ),
    description: gcp_unsubscribed_at ? <CuiDate date={gcp_unsubscribed_at} /> : notAvailableMsg,
  },
]

const getAWSMarketplaceInfoList: GetListItems<AWSMarketplaceInfo> = ({
  aws_customer_id,
  aws_subscribed,
}) => [
  {
    title: <FormattedMessage id='aws-customer-id.title' defaultMessage='AWS customer ID' />,
    description: aws_customer_id || notAvailableMsg,
  },
  {
    title: (
      <FormattedMessage id='aws-subscribed.title' defaultMessage='AWS marketplace subscription' />
    ),
    description: aws_subscribed ? (
      <FormattedMessage id='subscription-active' defaultMessage='Active' />
    ) : (
      <FormattedMessage id='subscription-inactive' defaultMessage='Inactive' />
    ),
  },
]

const getDescriptionListItemsByMarketplace = (
  info: MarketplaceInfoType,
): EuiDescriptionListItems => {
  switch (info.domain) {
    case 'aws': {
      return getAWSMarketplaceInfoList(info)
    }

    case 'azure': {
      return getAzureMarketplaceInfoList(info)
    }

    case 'gcp': {
      return getGCPMarketplaceInfoList(info)
    }

    default:
      return []
  }
}

const getMarketplaceInfo = (
  saasOrganization: SaasOrganizationResponse,
): MarketplaceInfoType | null => {
  const {
    organization: { domain },
    subscription,
  } = saasOrganization

  switch (domain) {
    case 'aws': {
      const { aws_customer_id, aws_subscribed } = subscription
      return {
        domain: 'aws',
        aws_customer_id,
        aws_subscribed,
      } as AWSMarketplaceInfo
    }

    case 'gcp': {
      const {
        marketplace_attributes: {
          gcp_customer_id,
          gcp_entitlement_id,
          gcp_subscribed_annual,
          gcp_unsubscribed_at,
        } = {},
        gcp_subscribed,
      } = subscription
      return {
        domain: 'gcp',
        gcp_customer_id,
        gcp_entitlement_id,
        gcp_subscribed,
        gcp_subscribed_annual,
        gcp_unsubscribed_at,
      } as GCPMarketplaceInfo
    }

    case 'azure': {
      const {
        marketplace_attributes: {
          azure_account_id,
          azure_plan_id,
          azure_subscription_id,
          azure_tenant_id,
          azure_unsubscribed_at,
        } = {},
        azure_subscribed,
      } = subscription
      return {
        domain: 'azure',
        azure_subscribed,
        azure_account_id,
        azure_plan_id,
        azure_subscription_id,
        azure_tenant_id,
        azure_unsubscribed_at,
      } as AzureMarketplaceInfo
    }

    default:
      return null
  }
}

interface Props {
  saasOrganization: SaasOrganizationResponse
}

const MarketplaceInfo: FunctionComponent<Props> = ({ saasOrganization }) => {
  const marketplaceInfo = getMarketplaceInfo(saasOrganization)

  if (!marketplaceInfo) {
    return null
  }

  const infoList = getDescriptionListItemsByMarketplace(marketplaceInfo)

  const marketplaceInfoTitle = (
    <FormattedMessage id='marketplace-info.title' defaultMessage='Marketplace Information' />
  )

  return (
    <PageSection iconType='tag' title={marketplaceInfoTitle}>
      <EuiDescriptionList compressed={true} type='responsiveColumn' listItems={infoList} />
    </PageSection>
  )
}

export default MarketplaceInfo
