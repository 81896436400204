/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { without } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiButton,
  EuiCode,
  EuiButtonIcon,
  EuiFormRow,
  EuiPopover,
  EuiText,
  EuiSpacer,
} from '@elastic/eui'

import { CuiTable } from '../../../cui'
import { filterToHex, isFullUuid } from '../../../lib/string'

import type { WrappedComponentProps } from 'react-intl'
import type { CuiTableColumn } from '../../../cui'

const messages = defineMessages({
  label: {
    id: 'deploymentTrustManagement.enter-remotes.label',
    defaultMessage: 'Cluster ID',
  },
  labelHelpPopoverText: {
    id: 'deploymentTrustManagement.enter-remotes.label-help-popover-text',
    defaultMessage:
      'Enter the Elasticsearch cluster ID of the deployments you want to trust. You can find the ID under Applications on the main Administration page of the deployment.',
  },
  helpText: {
    id: 'deploymentTrustManagement.enter-remotes.help-text',
    defaultMessage: 'Must include all 32 characters',
  },
  addButton: {
    id: 'deploymentTrustManagement.enter-remotes.add-button',
    defaultMessage: 'Add',
  },
  removeButton: {
    id: 'deploymentTrustManagement.enter-remotes.remove-button',
    defaultMessage: 'Remove {clusterId} from trusted deployments',
  },
  clusterIdHeader: {
    id: 'deploymentTrustManagement.enter-remotes.cluster-id-header',
    defaultMessage: 'Cluster ID',
  },
  actionsHeader: {
    id: 'deploymentTrustManagement.enter-remotes.actions-header',
    defaultMessage: 'Actions',
  },
})

class EnterOtherAccountRemoteDeploymentsAddLabel extends React.Component<
  unknown,
  { isPopoverOpen: boolean }
> {
  state = {
    isPopoverOpen: false,
  }

  render() {
    const button = (
      <EuiButtonIcon
        color='primary'
        onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
        iconType='iInCircle'
        aria-label='more information'
      />
    )

    return (
      <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
        <EuiFlexItem style={{ whiteSpace: `nowrap` }}>
          <FormattedMessage {...messages.label} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPopover
            anchorPosition='upCenter'
            button={button}
            isOpen={this.state.isPopoverOpen}
            closePopover={() => this.setState({ isPopoverOpen: false })}
          >
            <EuiText size='s' style={{ maxWidth: `24em` }}>
              <FormattedMessage {...messages.labelHelpPopoverText} />
            </EuiText>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }
}

type Props = WrappedComponentProps & {
  trustedClusterIds: string[]
  onChange: (ids: string[]) => void
  inputStyle?: React.CSSProperties
}

type State = {
  newClusterId: string
}

// this is only failing due to the label needing to be stateful for popover purposes, will be fixed with a CUI help tip refactor
// eslint-disable-next-line react/no-multi-comp
class EnterOtherAccountRemoteDeployments extends React.Component<Props, State> {
  state: State = {
    newClusterId: ``,
  }

  render(): JSX.Element {
    const {
      intl: { formatMessage },
      trustedClusterIds,
    } = this.props

    const columns: Array<CuiTableColumn<string>> = [
      {
        textOnly: false,
        label: formatMessage(messages.clusterIdHeader),
        render: (clusterId) => <EuiCode>{clusterId.slice(0, 6)}</EuiCode>,
      },
      {
        actions: true,
        render: (clusterId) => (
          <EuiButtonIcon
            aria-label={`${formatMessage(messages.removeButton, {
              clusterId: clusterId.slice(0, 6),
            })}`}
            iconType='cross'
            onClick={() => this.remove(clusterId)}
          />
        ),
        align: `right`,
      },
    ]

    return (
      <Fragment>
        <EuiFlexGroup gutterSize='m' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiFormRow
              label={<EnterOtherAccountRemoteDeploymentsAddLabel />}
              helpText={formatMessage(messages.helpText)}
            >
              <EuiFieldText
                maxLength={32}
                style={{ width: `24em`, ...this.props.inputStyle }}
                fullWidth={true}
                value={this.state.newClusterId}
                onChange={(e) => this.setState({ newClusterId: filterToHex(e.target.value) })}
              />
            </EuiFormRow>
            {/* SO GROSS but here to offset the greater line height of the label (which has a help tip), so that the textbox keeps alignment with the button */}
            <EuiSpacer size='s' />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ width: `6em` }}>
            <EuiButton
              color='primary'
              onClick={() => this.add()}
              disabled={!isFullUuid(this.state.newClusterId)}
            >
              <FormattedMessage {...messages.addButton} />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>

        {trustedClusterIds.length > 0 && (
          <div style={{ maxWidth: `36rem` }}>
            <EuiSpacer size='s' />
            <CuiTable<string> rows={trustedClusterIds} columns={columns} />
          </div>
        )}
      </Fragment>
    )
  }

  add = () => {
    const { trustedClusterIds } = this.props
    const { newClusterId } = this.state

    if (!trustedClusterIds.includes(newClusterId)) {
      this.props.onChange([...trustedClusterIds, newClusterId])
    }

    this.setState({
      newClusterId: ``,
    })
  }

  remove = (clusterId: string) => {
    this.props.onChange(without(this.props.trustedClusterIds, clusterId))
  }
}

export default injectIntl(EnterOtherAccountRemoteDeployments)
