/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { fetchAuthenticationInfoIfNeeded, refreshToken } from '../../actions/auth'
import { getAuthenticationInfo, fetchAuthenticationInfoRequest } from '../../reducers'
import { SAD_getAuthTokenExpiration } from '../../lib/auth'

import RefreshApiToken from './RefreshApiToken'

import type { AsyncRequestState, ThunkDispatch } from '../../types'
import type { AuthenticationInfo } from '../../lib/api/v1/types'

type StateProps = {
  authenticationInfo: AuthenticationInfo | null
  fetchAuthenticationInfoRequest: AsyncRequestState
  authTokenExpiration: Date | null
}

type DispatchProps = {
  fetchAuthenticationInfo: () => Promise<any>
  refreshToken: () => Promise<any>
}

interface ConsumerProps {}

const mapStateToProps = (state): StateProps => ({
  authenticationInfo: getAuthenticationInfo(state),
  fetchAuthenticationInfoRequest: fetchAuthenticationInfoRequest(state),
  authTokenExpiration: SAD_getAuthTokenExpiration(),
})

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  fetchAuthenticationInfo: () => dispatch(fetchAuthenticationInfoIfNeeded()),
  refreshToken: () => dispatch(refreshToken()),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RefreshApiToken)
