/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiBadge,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormLabel,
  EuiLink,
  EuiLoadingContent,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { CuiPermissibleControl } from '@/cui'
import { getVisibleTemplatesFromV2 } from '@/lib/stackDeployments'
import {
  getDeploymentTemplateId,
  getSupportedDeploymentTemplates,
} from '@/lib/stackDeployments/selectors'
import { getLatestVersion } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import Permission from '@/lib/api/v1/permissions'

import DeploymentTemplateFlyout from './DeploymentTemplateFlyout'
import messages, { getTitleText, getTooltipText } from './messages'

import type { Props, State } from './types'

class DeploymentTemplate extends Component<Props, State> {
  state = {
    pendingDeploymentTemplateId: undefined,
    isFlyoutOpen: false,
    isHelpPopoverOpen: false,
  }

  componentWillUnmount() {
    const { resetMigrateDeploymentTemplate, resetUpdateDeployment } = this.props
    const { pendingDeploymentTemplateId } = this.state

    resetUpdateDeployment()

    if (pendingDeploymentTemplateId) {
      resetMigrateDeploymentTemplate(pendingDeploymentTemplateId)
    }
  }

  render() {
    const {
      intl: { formatMessage },
      deployment,
      deploymentTemplates,
      updateDeploymentRequest,
      inTrial,
    } = this.props
    const { isFlyoutOpen, isHelpPopoverOpen, pendingDeploymentTemplateId } = this.state

    const hasLoadedTemplates = deploymentTemplates.length > 0
    const currentTemplateId = getDeploymentTemplateId({ deployment })
    const currentTemplate = deploymentTemplates.find(
      ({ id }) => currentTemplateId && currentTemplateId === id,
    )
    const supportedTemplates = getSupportedDeploymentTemplates(deploymentTemplates)?.filter(
      ({ id }) => id !== currentTemplateId,
    )
    const visibleTemplates = getVisibleTemplatesFromV2({
      deploymentTemplates: supportedTemplates,
    })

    const newerTemplate = currentTemplateId
      ? getLatestVersion(deploymentTemplates, currentTemplateId)
      : null

    if (newerTemplate && visibleTemplates.every(({ id }) => id !== newerTemplate.id)) {
      visibleTemplates.push(newerTemplate)
    }

    return (
      <div>
        <EuiFlexGroup direction='row' gutterSize='none' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiFormLabel>{getTitleText()}</EuiFormLabel>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiPopover
              button={
                <EuiButtonIcon
                  aria-label={formatMessage(messages.learnMore)}
                  onClick={() => this.setState({ isHelpPopoverOpen: true })}
                  iconType='questionInCircle'
                  color='primary'
                />
              }
              isOpen={isHelpPopoverOpen}
              closePopover={() => this.setState({ isHelpPopoverOpen: false })}
              anchorPosition='rightCenter'
              panelPaddingSize='m'
            >
              <EuiFormHelpText style={{ maxWidth: `275px` }}>{getTooltipText()}</EuiFormHelpText>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='m' />

        <EuiFlexGroup gutterSize='none' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiText size='s' data-test-id='deployment-template-name'>
              {hasLoadedTemplates ? currentTemplate?.name : <EuiLoadingContent lines={1} />}
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            {hasLoadedTemplates && visibleTemplates.length > 0 && (
              <CuiPermissibleControl
                permissions={[Permission.migrateDeploymentTemplate, Permission.updateDeployment]}
              >
                <EuiLink
                  style={{ marginLeft: `6px` }}
                  onClick={() => this.setState({ isFlyoutOpen: true })}
                  data-test-id='deployment-template-edit-button'
                >
                  {newerTemplate && (
                    <EuiBadge color='primary' style={{ marginRight: `12px` }}>
                      <FormattedMessage {...messages.newVersionAvailable} />
                    </EuiBadge>
                  )}
                  <FormattedMessage {...messages.edit} />
                </EuiLink>
              </CuiPermissibleControl>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>

        {isFlyoutOpen && (
          <DeploymentTemplateFlyout
            onClose={() => this.setState({ isFlyoutOpen: false })}
            onChange={(templateId) => this.onTemplateChange(templateId)}
            onSave={() => this.onSave()}
            onSaveRequest={updateDeploymentRequest}
            selectedId={pendingDeploymentTemplateId}
            deployment={deployment}
            deploymentTemplates={visibleTemplates}
            currentTemplate={currentTemplate}
            newerTemplate={newerTemplate}
            migratedTemplatePayload={this.getMigratedDeploymentPayload()}
            migrateDeploymentTemplateRequest={this.getMigrateDeploymentTemplateRequest()}
            inTrial={inTrial}
            updateDeploymentRequest={updateDeploymentRequest}
          />
        )}
      </div>
    )
  }

  getMigratedDeploymentPayload() {
    const { getMigratedDeploymentPayload } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return null // none set
    }

    return getMigratedDeploymentPayload(pendingDeploymentTemplateId)
  }

  getMigrateDeploymentTemplateRequest() {
    const { getMigrateDeploymentTemplateRequest } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return null // none set
    }

    return getMigrateDeploymentTemplateRequest(pendingDeploymentTemplateId)
  }

  onTemplateChange(templateId: string | undefined) {
    const { migrateDeploymentTemplate } = this.props

    this.setState({ pendingDeploymentTemplateId: templateId })

    if (templateId) {
      migrateDeploymentTemplate(templateId)
    }
  }

  onSave() {
    const { getMigratedDeploymentPayload, updateDeployment } = this.props
    const { pendingDeploymentTemplateId } = this.state

    if (!pendingDeploymentTemplateId) {
      return // sanity
    }

    const migratedTemplatePayload = getMigratedDeploymentPayload(pendingDeploymentTemplateId)

    if (!migratedTemplatePayload) {
      return // sanity
    }

    updateDeployment(migratedTemplatePayload)
  }
}

export default injectIntl(DeploymentTemplate)
