/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from '@elastic/eui'

import MarketplaceErrorMessage from '../MarketplaceErrorMessage'

import type { AjaxRequestError } from '../../../../../types'
import type { FunctionComponent, ReactElement } from 'react'

interface Props {
  onClose: () => void
  error: string | Error | AjaxRequestError
}

const MarketplaceActivationError: FunctionComponent<Props> = ({ onClose, error }): ReactElement => (
  <EuiModal onClose={onClose} style={{ maxWidth: '475px' }}>
    <EuiModalHeader>
      <EuiModalHeaderTitle>
        <h1>
          <FormattedMessage id='marketplace-conversion.error.title' defaultMessage='Error' />
        </h1>
      </EuiModalHeaderTitle>
    </EuiModalHeader>

    <EuiModalBody>
      <EuiText size='s'>
        <MarketplaceErrorMessage error={error} />
      </EuiText>
    </EuiModalBody>

    <EuiModalFooter>
      <EuiButton onClick={onClose}>
        <FormattedMessage id='marketplace-conversion-error.done-button' defaultMessage='Close' />
      </EuiButton>
    </EuiModalFooter>
  </EuiModal>
)

export default MarketplaceActivationError
