/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiSpacer, EuiText } from '@elastic/eui'

import EcuHelpText from '@/components/EcuHelp/EcuHelpText'

import ExternalLink from '../../../ExternalLink'
import BillingDocuments from '../BillingDocuments'
import BillingHistoryPanel from '../BillingHistoryPanel'
import CuiPrice from '../../../../cui/formatters/CuiPrice'
import CuiElasticConsumptionUnits from '../../../../cui/formatters/CuiElasticConsumptionUnits'
import { toBillingPeriodFormat } from '../lib/date'
import { isPrepaidConsumptionCustomer } from '../../../../lib/billing'

import type { CuiTableColumn } from '../../../../cui'
import type { Props as CuiTableProps } from '../../../../cui/Table/types'
import type { ReactElement } from 'react'
import type { UserProfile } from '../../../../types'
import type { Statement } from '../../../../lib/api/v1/types'

export interface Props {
  loading: boolean
  profile: UserProfile
  statements: Statement[]
  title?: ReactElement
}

class BillingStatements extends Component<Props> {
  render(): ReactElement {
    const { loading, profile, statements, title } = this.props

    const { columns, initialSort, initialSortDirection } = this.getGridColumns()

    return (
      <div>
        <BillingHistoryPanel
          title={title}
          showEcuTooltip={isPrepaidConsumptionCustomer(profile)}
          data-test-id='billing-statements-panel'
        >
          <BillingDocuments
            initialLoading={loading}
            documents={statements}
            columns={columns}
            initialSort={initialSort}
            initialSortDirection={initialSortDirection}
          />
        </BillingHistoryPanel>

        {isPrepaidConsumptionCustomer(profile) && (
          <EuiText>
            <EuiSpacer size='m' />
            <EcuHelpText />
          </EuiText>
        )}
      </div>
    )
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<Statement>
    initialSortDirection: CuiTableProps<Statement>['initialSortDirection']
    columns: Array<CuiTableColumn<Statement>>
  } {
    const { profile } = this.props

    const billingPeriodColumn = {
      label: <FormattedMessage id='billing-documents.period' defaultMessage='Billing period' />,
      render: ({ period_start_date, period_end_date, number }) => (
        <span data-test-id={`billing-period-${number}`}>
          {toBillingPeriodFormat({
            periodStartDate: period_start_date,
            periodEndDate: period_end_date,
          })}
        </span>
      ),
      sortKey: [
        ({ period_start_date }) => new Date(period_start_date),
        ({ period_end_date }) => new Date(period_end_date),
      ],
    }

    const columns: Array<CuiTableColumn<Statement>> = [
      billingPeriodColumn,
      {
        label: <FormattedMessage id='billing-documents.amount' defaultMessage='Invoiced amount' />,
        render: ({ invoiced_amount_in_cents, currency }) => (
          <CuiPrice
            value={Math.max(0, invoiced_amount_in_cents)}
            unit='cents'
            currency={currency}
            data-test-id='invoiced-amount'
          />
        ),
        sortKey: `invoiced_amount_in_cents`,
      },
      {
        label: (
          <FormattedMessage
            id='billing-statements.statement-number'
            defaultMessage='Usage statement'
          />
        ),
        render: ({ usage_pdf_download, number }) =>
          usage_pdf_download ? (
            <ExternalLink data-test-id='usage-pdf-link' href={usage_pdf_download}>
              {number}
            </ExternalLink>
          ) : (
            number
          ),
        sortKey: `number`,
      },
      {
        label: <FormattedMessage id='billing-documents.status' defaultMessage='Status' />,
        render: ({ status }) => {
          if (status === `paid_from_balance`) {
            return (
              <EuiBadge color='default'>
                {isPrepaidConsumptionCustomer(profile) ? (
                  <FormattedMessage
                    id='billing-statements.paid-from-balance'
                    defaultMessage='Paid from balance'
                  />
                ) : (
                  <FormattedMessage id='billing-documents.paid' defaultMessage='Paid' />
                )}
              </EuiBadge>
            )
          }

          return (
            <EuiBadge color='warning'>
              <FormattedMessage id='billing-documents.invoiced' defaultMessage='Invoiced' />
            </EuiBadge>
          )
        },
        sortKey: `status`,
      },
    ]

    if (isPrepaidConsumptionCustomer(profile)) {
      columns.splice(1, 0, {
        key: 'billing-statements.usage-units',
        label: (
          <FormattedMessage id='billing-statements.usage-units' defaultMessage='Usage (ECUs)' />
        ),
        render: ({ ecu_total }) => (
          <CuiElasticConsumptionUnits
            unit='none'
            value={Math.max(0, ecu_total)}
            withSymbol={false}
          />
        ),
        sortKey: `ecu_total`,
      })
    }

    return { columns, initialSort: billingPeriodColumn, initialSortDirection: 'desc' }
  }
}

export default BillingStatements
