/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { Problem } from '../../problems'
import type {
  DeploymentHealth,
  HealthComponent,
  HealthIndicator,
  ResourceHealth,
} from '@/lib/api/v1/types'

function isHealthy(status: DeploymentHealth['status']): boolean {
  return status === 'Green'
}

function isCritical(status: DeploymentHealth['status']): boolean {
  return status === 'Red'
}

/**
 * Converts responses from the deployment health API into Problems, which can be presented in the StackDeploymentHealthProblems
 * component. The response from this API should eventually replace the majority of Problems presented in this component.
 * However, at this time it only covers a subset of Elasticsearch related problems.
 *
 * @see {@link getDeploymentHealthUrl} for more information on the Deployment Health API
 * @param {DeploymentHealth} health - the deployment health API response, representing the current health of a deployment
 */
export function getProblemsFromDeploymentHealthApi(health?: DeploymentHealth | null): Problem[] {
  if (!health || isHealthy(health.status)) {
    return []
  }

  return getDeploymentHealthApiProblems(health)
}

function getDeploymentHealthApiProblems(health: DeploymentHealth): Problem[] {
  // Currently, we only consume the elasticsearch resource
  if (!health) {
    return []
  }

  const resourceTypes = [
    'elasticsearch',
    'kibana',
    'apm',
    'integrations_server',
    'appsearch',
    'enterprise_search',
  ]
  const problems: Problem[] = []

  for (const resource of resourceTypes) {
    if (health[resource]) {
      problems.push(...getDeploymentProblemsFromResource(health[resource]))
    }
  }

  return problems
}

function getDeploymentProblemsFromResource(resource: ResourceHealth): Problem[] {
  if (!resource.components) {
    return []
  }

  return Object.values(resource.components)
    .filter((component) => !isHealthy(component.status))
    .flatMap(mapComponentToProblems)
}

function mapComponentToProblems(component: HealthComponent): Problem[] {
  return Object.entries(component.indicators || [])
    .filter(([indicatorName, indicator]) => !indicatorIsHidden(indicatorName, indicator))
    .map(mapIndicatorToProblem)
}

function indicatorIsHidden(indicatorName: string, indicator: HealthIndicator) {
  return isHealthy(indicator.status) || !indicatorIsHandledByDeploymentHealthApi(indicatorName)
}

function indicatorIsHandledByDeploymentHealthApi(indicatorName: string) {
  const indicatorsAddressedByDeploymentHealthApi = [
    'master',
    'ilm-migration-failed',
    'es-shard',
    'instances-unhealthy',
    'failed-initial-plan',
    'deployment-unhealthy-allocators',
  ]
  return indicatorsAddressedByDeploymentHealthApi.some((indicator) =>
    indicatorName.includes(indicator),
  )
}

function mapIndicatorToProblem([indicatorName, indicator]: [string, HealthIndicator]): Problem {
  return {
    'data-test-id': indicatorName,
    kind: `deployment`,
    id: indicatorName,
    level: isCritical(indicator.status) ? `danger` : `warning`,
    message: indicator.summary,
    helpUrl: indicator.help_url,
  }
}
