/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSwitch } from '@elastic/eui'

import type { ReactNode } from 'react'
import type { AsyncRequestState } from '../../../types'

type Props = {
  organizationId: string
  checked: boolean
  allowPlugins: boolean
  allowBundles: boolean
  action: ReactNode
  setCustomSetting: () => void
  setCustomSettingRequest: AsyncRequestState
  resetSetCustomSetting: () => void
}

export default class CustomSettingButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetSetCustomSetting()
  }

  render() {
    const { checked, setCustomSetting, setCustomSettingRequest } = this.props

    return (
      <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            checked={checked}
            label={''}
            showLabel={false}
            onChange={() => setCustomSetting()}
            disabled={setCustomSettingRequest.inProgress}
          />
        </EuiFlexItem>

        {setCustomSettingRequest.inProgress && (
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size='m' />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }
}
