/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Sticky from 'react-sticky-el'
import cx from 'classnames'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  EuiSplitPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiHorizontalRule,
  EuiLoadingContent,
  EuiButtonEmpty,
  EuiAccordion,
  EuiPanel,
} from '@elastic/eui'

import DeploymentArchitecture from '@/components/DeploymentArchitecture/DeploymentArchitecture'
import PriceButton from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/PriceButton'
import { AjaxRequestError } from '@/types'

import CreateStackDeploymentTitle from '../CreateStackDeploymentTitle'
import { CuiAlert } from '../../../cui'
import MarketplaceActivation from '../../../apps/userconsole/components/PartnerSignup/MarketplaceActivation'
import {
  changeRestoreFromSnapshot,
  getCreatePayload,
  getDeploymentNameSetter,
  getDeploymentVersionSetter,
  setEsSettings,
} from '../../../lib/stackDeployments'
import {
  getUpsertVersion,
  getRegionIdForCreate,
  isAutoscalingEnabled,
  getFirstEsClusterFromGet,
  getRegionId,
  getLatestSnapshotSuccess,
  getDeploymentNodeConfigurations,
} from '../../../lib/stackDeployments/selectors'
import { inTrial, inActiveTrial } from '../../../lib/trial'

import CreateDeploymentButton from './CreateDeploymentButton'
import ConfigureDeploymentSteps from './ConfigureDeploymentSteps'
import SelectTemplate from './SelectTemplate'

import type { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer'
import type { StackVersionConfig, DeploymentSearchResponse } from '../../../lib/api/v1/types'
import type {
  ClusterSnapshot,
  AsyncRequestState,
  ProfileState,
  RegionId,
  AnyTopologyElement,
  AsyncRequestError,
} from '../../../types'
import type { CreateEditorComponentConsumerProps as ConsumerProps } from '../types'
import type { WrappedComponentProps } from 'react-intl'

import './createStackDeploymentEditor.scss'

type StateProps = {
  showPrice: boolean
  stackVersions: StackVersionConfig[] | null
  createStackDeploymentRequest: AsyncRequestState
  claimInstantStackDeploymentRequest: AsyncRequestState
  trialMaxedOut: boolean
  profile: ProfileState
  isLaunchdarklyEnabled: boolean
  isUserconsole: boolean
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & ConsumerProps & WrappedComponentProps & LDProps

type State = {
  restoreSnapshotSource: DeploymentSearchResponse | null
  showArchitectureSummary: boolean
  priceViewSelected: `hourly` | `monthly`
}

class CreateStackDeploymentEditor extends Component<Props, State> {
  state: State = {
    restoreSnapshotSource: null,
    showArchitectureSummary: false,
    priceViewSelected: `hourly`,
  }

  render() {
    return <div className='create-deployment-from-template'>{this.renderContent()}</div>
  }

  renderContent() {
    const { profile, isUserconsole, showTrialExperience } = this.props
    /* only show settings and advanced settings link if
     *   a) the user isn't in a trial
     *   b) the user is in a trial but already created at least one deployment
     */
    const showSettings = !inTrial({ profile }) || inActiveTrial({ profile })

    return (
      <Fragment>
        <EuiSplitPanel.Outer className='create-deployment-panel'>
          <EuiSplitPanel.Inner className='create-deployment-panel-title'>
            <CreateStackDeploymentTitle showTrialExperience={showTrialExperience} />
            <EuiHorizontalRule margin='s' />
          </EuiSplitPanel.Inner>
          {this.renderPage(showSettings)}
          {this.renderFooter(showSettings)}
        </EuiSplitPanel.Outer>
        {isUserconsole && profile && <MarketplaceActivation />}
      </Fragment>
    )
  }

  renderPage(showSettings: boolean) {
    const {
      availableVersions,
      createStackDeploymentRequest,
      editorState,
      onChange,
      region,
      setDeploymentTemplate,
      setRegion,
      showPrice,
      showRegion,
      trialMaxedOut,
      whitelistedVersions,
      globalDeploymentTemplates,
      setGlobalTemplate,
      deploymentTemplates,
    } = this.props

    const { restoreSnapshotSource } = this.state

    return (
      <EuiSplitPanel.Inner className='create-deployment-panel-body'>
        <SelectTemplate
          disabled={createStackDeploymentRequest.inProgress}
          editorState={editorState}
          globalDeploymentTemplates={globalDeploymentTemplates}
          setDeploymentName={getDeploymentNameSetter({ onChange })}
          setDeploymentTemplate={setDeploymentTemplate}
          setGlobalTemplate={setGlobalTemplate}
          availableVersions={availableVersions}
          whitelistedVersions={whitelistedVersions}
          setVersion={getDeploymentVersionSetter({ editorState, onChange })}
          setRegion={setRegion}
          showRegion={showRegion!}
          region={region}
          showPrice={showPrice}
          onChange={onChange}
          onChangeSnapshotSource={this.onChangeSnapshotSource}
          onChangeSnapshot={this.onChangeSnapshot}
          setEsSettings={this.updateEsSettings}
          restoreFromSnapshot={restoreSnapshotSource !== null}
          trialMaxedOut={trialMaxedOut}
          deploymentTemplates={deploymentTemplates}
          showSettings={showSettings}
        />

        <EuiSpacer size='l' />

        {showSettings && (
          <Fragment>
            <EuiAccordion
              className='configure-deployment-steps-accordion'
              id='configure-deployment-steps-accordion'
              data-test-id='configure-deployment-steps-accordion'
              buttonContent={
                <FormattedMessage
                  id='create-deployment-from-template.advanced-settings'
                  defaultMessage='Advanced settings'
                />
              }
              buttonProps={{
                /* @ts-expect-error data-test-id is not declared on the EuiButton */
                'data-test-id': 'configure-deployment-steps',
              }}
              paddingSize='xs'
            >
              <EuiSpacer size='l' />
              <ConfigureDeploymentSteps
                editorState={editorState}
                onChange={onChange}
                firstStepNumber={this.getSelectTemplateStepCount() + 1}
                region={region!}
                snapshotDetails={this.getSnapshotDetails()}
                showPrice={showPrice}
              />
            </EuiAccordion>
            <EuiSpacer size='l' />
          </Fragment>
        )}
      </EuiSplitPanel.Inner>
    )
  }

  renderFooter(showSettings: boolean) {
    const { createStackDeploymentRequest, editorState, region } = this.props

    const disableBottomNav = this.shouldDisableBottomNav()
    const { deploymentTemplate } = editorState
    return (
      <Sticky positionRecheckInterval={0.1} mode='bottom'>
        <EuiPanel hasShadow={false} className='create-deployment-footer'>
          {this.renderSummaryArchitecture()}
          <EuiFlexGroup justifyContent='spaceBetween'>
            {showSettings && region && deploymentTemplate && (
              <EuiFlexItem grow={false}>{this.renderArchitectureButton()}</EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <CreateDeploymentButton
                disabled={disableBottomNav || createStackDeploymentRequest.inProgress}
                editorState={editorState}
                showApiRequest={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          {this.renderRequestErrors()}
        </EuiPanel>
      </Sticky>
    )
  }

  renderRequestErrors() {
    const { createStackDeploymentRequest, claimInstantStackDeploymentRequest } = this.props
    const createStackDeploymentRequestError = createStackDeploymentRequest.error
    const claimInstantStackDeploymentRequestError = claimInstantStackDeploymentRequest.error

    if (createStackDeploymentRequestError || claimInstantStackDeploymentRequestError) {
      return (
        <Fragment>
          <EuiSpacer />

          {createStackDeploymentRequestError && (
            <CuiAlert type='error'>{createStackDeploymentRequestError}</CuiAlert>
          )}
          {this.renderClaimStackDeploymentError(claimInstantStackDeploymentRequestError)}
        </Fragment>
      )
    }

    return null
  }

  renderClaimStackDeploymentError(error?: AsyncRequestError) {
    if (!error) {
      return null
    }

    if (!(error instanceof AjaxRequestError) || error.response.status === 404) {
      return null
    }

    return <CuiAlert type='error'>{error}</CuiAlert>
  }

  renderArchitectureButton() {
    const { showPrice, region, editorState, profile } = this.props
    const { deploymentTemplate } = editorState
    const { showArchitectureSummary } = this.state

    if (showPrice && !inTrial({ profile })) {
      return (
        <PriceButton
          showArchitectureSummary={showArchitectureSummary}
          nodeConfigurations={this.getNodeConfigurations()}
          priceViewSelected={this.state.priceViewSelected}
          deploymentTemplate={deploymentTemplate!}
          regionId={region!.id}
          showPrice={showPrice}
          onChangePriceView={(id) => this.setState({ priceViewSelected: id })}
          onClickPriceButton={this.onClickPriceButton}
        />
      )
    }

    return (
      <div>
        <EuiButtonEmpty
          data-test-id='show-architecture-button'
          onClick={() =>
            this.setState({ showArchitectureSummary: !this.state.showArchitectureSummary })
          }
          iconSide='right'
          iconType={showArchitectureSummary ? 'arrowDown' : 'arrowUp'}
          size='s'
        >
          {showArchitectureSummary ? (
            <FormattedMessage
              id='architecture-button.hide-configuration'
              defaultMessage='Hide configuration'
            />
          ) : (
            <FormattedMessage
              id='architecture-button.show-configuration'
              defaultMessage='Show configuration'
            />
          )}
        </EuiButtonEmpty>
      </div>
    )
  }

  renderSummaryArchitecture() {
    const { editorState } = this.props
    const { showArchitectureSummary } = this.state
    const { regionId, deployment, deploymentTemplate } = editorState

    if (!deploymentTemplate || !regionId || !deployment) {
      return <EuiLoadingContent />
    }

    const autoscalingEnabled = isAutoscalingEnabled({ deployment })
    const instanceConfigurations = deploymentTemplate!.instance_configurations
    const version = getUpsertVersion(editorState)

    return (
      <div
        className={cx({
          'deployment-architecture-summary-hidden': !showArchitectureSummary,
          'deployment-architecture-summary-visible': showArchitectureSummary,
        })}
      >
        <DeploymentArchitecture
          priceViewSelected={this.state.priceViewSelected}
          version={version!}
          regionId={regionId}
          autoscalingEnabled={autoscalingEnabled}
          instanceConfigurations={instanceConfigurations}
          nodeConfigurations={this.getNodeConfigurations()}
        />

        <EuiHorizontalRule />
      </div>
    )
  }

  onClickPriceButton = (): void => {
    this.setState({ showArchitectureSummary: !this.state.showArchitectureSummary })
  }

  onChangeSnapshotSource = (
    nextRestoreSnapshotSource?: DeploymentSearchResponse | null,
    regionId?: RegionId,
  ) => {
    const { onChange } = this.props

    if (nextRestoreSnapshotSource == null) {
      this.setState({ restoreSnapshotSource: null })
      changeRestoreFromSnapshot({ onChange, source: null })
      return
    }

    this.setState({ restoreSnapshotSource: nextRestoreSnapshotSource })

    changeRestoreFromSnapshot({ onChange, regionId, source: nextRestoreSnapshotSource })
  }

  onChangeSnapshot = (nextSnapshot?: ClusterSnapshot | null) => {
    const { onChange } = this.props
    const { restoreSnapshotSource } = this.state
    changeRestoreFromSnapshot({
      onChange,
      source: restoreSnapshotSource,
      snapshotName: nextSnapshot ? nextSnapshot.snapshot : undefined,
    })
  }

  getNodeConfigurations(): AnyTopologyElement[] {
    const { editorState, stackVersions } = this.props

    const { deployment } = editorState

    if (deployment == null) {
      return []
    }

    const region = this.props.region!

    const payload = getCreatePayload({
      region,
      editorState,
      stackVersions,
    })

    return getDeploymentNodeConfigurations({ deployment: payload! })
  }

  updateEsSettings = (settings) => {
    const { onChange } = this.props
    setEsSettings({ onChange, settings })
  }

  shouldDisableBottomNav = (): boolean => {
    const { region, editorState, trialMaxedOut } = this.props
    const { deployment, regionId, _joltVersion } = editorState

    if (trialMaxedOut) {
      return true
    }

    if (
      deployment.settings?.observability &&
      !deployment.settings?.observability?.metrics &&
      !deployment.settings?.observability?.logging
    ) {
      return true
    }

    /*
     * A missing region — or a region mismatch — would indicate we haven't finished
     * fetching the currently selected region.
     */
    const actualRegionId = deployment && getRegionIdForCreate({ deployment })
    const missingRegion = !region || !regionId
    const regionMismatch = actualRegionId !== regionId

    if (missingRegion || regionMismatch) {
      return true
    }

    /*
     * `_joltVersion` is the currently selected version.
     * `actualVersion` comes from the deployment, based on the deployment template.
     * A mismatch means the template hasn't yet been fetched, and passed along
     * to the `editorState`.
     */
    const actualVersion = getUpsertVersion({ deployment, _fromJolt: false })
    const missingVersion = !_joltVersion || !actualVersion
    const versionMismatch = _joltVersion !== actualVersion

    if (missingVersion || versionMismatch) {
      return true
    }

    return false
  }

  getSelectTemplateStepCount() {
    const { showRegion } = this.props

    let count = 3 // name step, setup step, optimize step

    if (showRegion) {
      count += 2 // platform step, region step
    }

    return count // NOTE: pricing step doesn't count!
  }

  getSnapshotDetails() {
    const { restoreSnapshotSource } = this.state
    const { showRegion } = this.props
    const firstEs = restoreSnapshotSource
      ? getFirstEsClusterFromGet({ deployment: restoreSnapshotSource })
      : null
    const snapshotSourceRegionId = restoreSnapshotSource
      ? getRegionId({ deployment: restoreSnapshotSource })
      : null
    const latestSnapshotDate = firstEs ? getLatestSnapshotSuccess({ resource: firstEs }) : null
    return restoreSnapshotSource === null
      ? {}
      : {
          deploymentId: firstEs!.id,
          deploymentName: restoreSnapshotSource.name,
          regionId: showRegion ? snapshotSourceRegionId : null,
          snapshotDate: latestSnapshotDate || ``,
        }
  }
}

export default injectIntl(withLDConsumer()(CreateStackDeploymentEditor))
