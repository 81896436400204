/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiIcon,
  EuiLoadingContent,
  EuiOverlayMask,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
} from '@elastic/eui'
import type { EuiSuperSelectOption } from '@elastic/eui'

import { CuiAlert } from '@/cui'
import { isTrialEligibleTemplate } from '@/lib/deploymentTemplates/metadata'
import { isTemplateUpgradeAvailable } from '@/lib/stackDeployments/selectors/deploymentTemplates'
import { getConfigForKey } from '@/store'

import messages, { getDescriptionText } from '../messages'

import HardwareComparison from './HardwareComparison'
import HardwareProfileDropdown from './HardwareProfileDropdown'
import NewVersionBadge from './NewVersionBadge'
import TemplateTitle from './TemplateTitle'

import type { FunctionComponent } from 'react'
import type { Props } from './types'

const DeploymentTemplateFlyout: FunctionComponent<Props> = ({
  deployment,
  deploymentTemplates,
  currentTemplate,
  newerTemplate,
  migratedTemplatePayload,
  migrateDeploymentTemplateRequest,
  inTrial,
  onSaveRequest,
  selectedId,
  onChange,
  onSave,
  onClose,
  updateDeploymentRequest,
}) => {
  let options: Array<EuiSuperSelectOption<string>> = []

  // We need a placeholder item to tell people to choose a value
  const placeholderOptionValue = `placeholder`
  const placeholderOption = {
    value: placeholderOptionValue,
    disabled: true,
    inputDisplay: <FormattedMessage {...messages.placeholderOptionDisplay} />,
  }
  options.push(placeholderOption)

  const templateOptions: Array<EuiSuperSelectOption<string>> = deploymentTemplates.map(
    (template) => {
      const disabled = inTrial && !isTrialEligibleTemplate(template)
      const isUpgradeable =
        currentTemplate && isTemplateUpgradeAvailable(currentTemplate.id, template.id)

      return {
        value: template.id,
        disabled,
        inputDisplay: isUpgradeable ? (
          <Fragment>
            {template.name} <NewVersionBadge />
          </Fragment>
        ) : (
          template.name
        ),
        dropdownDisplay: (
          <HardwareProfileDropdown
            template={template}
            isDisabled={disabled}
            isUpgradeable={isUpgradeable}
          />
        ),
      }
    },
  )
  options = options.concat(templateOptions)

  const isUserConsole = getConfigForKey(`APP_NAME`) === `userconsole`
  const isDisabled =
    !selectedId ||
    migrateDeploymentTemplateRequest?.inProgress ||
    Boolean(migrateDeploymentTemplateRequest?.error)

  return (
    <EuiOverlayMask headerZindexLocation='below'>
      <EuiFlyout maxWidth='65rem' size='l' onClose={onClose}>
        <EuiFlyoutHeader hasBorder={true}>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage {...messages.editTemplate} values={{ isUserConsole }} />
            </h2>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiText color='subdued' size='s'>
            <p>{getDescriptionText()}</p>
          </EuiText>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          {newerTemplate && (
            <Fragment>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <EuiTitle size='xxs'>
                  <h4>
                    <EuiIcon type='cheer' style={{ marginRight: `8px` }} />
                    <FormattedMessage
                      {...messages.previewNewVersionTitle}
                      values={{ title: currentTemplate?.name }}
                    />
                  </h4>
                </EuiTitle>
                <EuiSpacer size='m' />
                <EuiText size='s'>
                  <FormattedMessage
                    {...messages.previewNewVersionDescription1}
                    values={{
                      currentTitle: currentTemplate?.name,
                    }}
                  />
                  <br />
                  <FormattedMessage {...messages.previewNewVersionDescription2} />
                </EuiText>
                <EuiSpacer />
                <EuiButton onClick={() => onChange(newerTemplate.id)}>
                  <FormattedMessage {...messages.previewNewVersionButton} />
                </EuiButton>
              </EuiPanel>
              <EuiSpacer />
            </Fragment>
          )}

          <EuiPanel color='subdued' borderRadius='none' hasShadow={false}>
            <EuiFlexGrid columns={2} responsive={false} gutterSize='none'>
              <EuiFlexItem
                style={{ flexBasis: `215px`, maxWidth: `215px`, wordBreak: `break-word` }}
              >
                <EuiText size='xs'>
                  <strong>
                    <FormattedMessage {...messages.currentTemplate} values={{ isUserConsole }} />
                  </strong>
                </EuiText>

                <EuiSpacer size='m' />

                {currentTemplate && (
                  <TemplateTitle
                    template={currentTemplate}
                    style={{ display: `flex`, alignItems: `center`, minHeight: `40px` }}
                  />
                )}
              </EuiFlexItem>
              <EuiFlexItem grow={1}>
                <EuiText size='xs'>
                  <strong>
                    <FormattedMessage {...messages.newTemplate} values={{ isUserConsole }} />
                  </strong>
                </EuiText>

                <EuiSpacer size='m' />

                <EuiSuperSelect
                  fullWidth={true}
                  hasDividers={true}
                  isLoading={migrateDeploymentTemplateRequest?.inProgress}
                  options={options}
                  valueOfSelected={selectedId || placeholderOptionValue}
                  onChange={(id) => onChange(id)}
                  data-test-id='migrate-deployment-template-id'
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiPanel>

          {currentTemplate?.id && (
            <Fragment>
              <EuiSpacer />

              {migrateDeploymentTemplateRequest?.inProgress && <EuiLoadingContent />}

              {migrateDeploymentTemplateRequest?.error && (
                <Fragment>
                  <CuiAlert type='error' data-test-id='migrate-deployment-template-error'>
                    {migrateDeploymentTemplateRequest.error}
                  </CuiAlert>
                  <EuiSpacer />
                </Fragment>
              )}

              {!isDisabled && migratedTemplatePayload && (
                <HardwareComparison
                  deployment={deployment}
                  deploymentTemplates={[...deploymentTemplates, currentTemplate]}
                  migratedTemplatePayload={migratedTemplatePayload}
                />
              )}

              {updateDeploymentRequest.error && (
                <Fragment>
                  <EuiSpacer size='s' />
                  <CuiAlert type='error' data-test-id='update-deployment-template-error'>
                    {updateDeploymentRequest.error}
                  </CuiAlert>
                  <EuiSpacer />
                </Fragment>
              )}
            </Fragment>
          )}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={onClose} flush='left'>
                <FormattedMessage {...messages.cancel} />
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={onSave}
                isLoading={onSaveRequest.inProgress}
                isDisabled={isDisabled}
                type='button'
                fill={true}
                data-test-id='migrate-deployment-template-button'
              >
                <FormattedMessage {...messages.update} />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiOverlayMask>
  )
}

export default DeploymentTemplateFlyout
