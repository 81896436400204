/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import type { FunctionComponent } from 'react'

const TrialNotStartedTitle: FunctionComponent = () => (
  <Fragment>
    <EuiTitle size='s'>
      <h1 className='marketplace-conversion-modal-title'>
        <FormattedMessage id='marketplace-conversion.title' defaultMessage='Setup your account' />
      </h1>
    </EuiTitle>

    <EuiSpacer size='s' />

    <h2 className='marketplace-conversion-modal-subtitle'>
      <EuiText size='s' color='subdued'>
        <FormattedMessage
          id='marketplace-conversion.sub-title'
          defaultMessage='Before you create your first deployment, tell us a bit about yourself.'
        />
      </EuiText>
    </h2>
  </Fragment>
)

export default TrialNotStartedTitle
