/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, createRef } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import type { EuiDatePickerProps } from '@elastic/eui'
import { EuiDatePickerRange, EuiDatePicker, EuiFormRow } from '@elastic/eui'

import { messages } from '../messages'

import type { Moment } from 'moment'
import type { WrappedComponentProps } from 'react-intl'
import type { SelectedDateRange } from '../../../../../types'

import './datePicker.scss'

interface Props extends WrappedComponentProps {
  isDisabled: boolean
  onChangeDates: ({ selectedStartDate, selectedEndDate }: SelectedDateRange) => void
  selectedStartDate: Moment
  selectedEndDate: Moment
  maxDate: Moment
  minDate: Moment
}

interface State {
  startDate: Moment
  endDate: Moment
}

class DatePicker extends Component<Props, State> {
  endDatePickerRef = createRef<Component<EuiDatePickerProps>>()

  state: State = {
    startDate: this.props.selectedStartDate,
    endDate: this.props.selectedEndDate,
  }

  render() {
    const { isDisabled, intl, selectedStartDate, selectedEndDate, maxDate, minDate } = this.props
    const { formatMessage } = intl
    const isInvalid = selectedStartDate > selectedEndDate
    const { startDate, endDate } = this.state

    return (
      <EuiFormRow
        className='date-range-form-row'
        label={
          <FormattedMessage
            id='cost-analysis.filter.date-picker.label'
            defaultMessage='Date range'
          />
        }
        helpText={
          <FormattedMessage
            id='cost-analysis.filter.date-picker.inclusion-text'
            defaultMessage='Selected range includes start and end dates.'
            data-test-id='cost-analysis.date-picker.inclusion-text'
          />
        }
      >
        <EuiDatePickerRange
          startDateControl={
            <EuiDatePicker
              disabled={isDisabled}
              popoverPlacement='bottom-end'
              selected={startDate}
              isInvalid={isInvalid}
              aria-label={formatMessage(messages.startDate)}
              showTimeSelect={false}
              maxDate={endDate || maxDate}
              minDate={minDate}
              adjustDateOnChange={false}
              onSelect={this.onSelectStartDate}
            />
          }
          endDateControl={
            // We should replace KeyDown and ClickOutside event handlers by OnClose handler when EUI start support it
            <EuiDatePicker
              disabled={isDisabled}
              popoverPlacement='bottom-end'
              selected={endDate}
              onSelect={this.onSelectEndDate}
              isInvalid={isInvalid}
              aria-label={formatMessage(messages.endDate)}
              showTimeSelect={false}
              maxDate={maxDate}
              minDate={startDate || minDate}
              adjustDateOnChange={false}
              inputRef={this.endDatePickerRef}
              onKeyDown={({ key }) => {
                const { onChangeDates } = this.props
                const { startDate: newStartDate, endDate: newEndDate } = this.state
                const allowedKeys = ['Escape', 'Enter']

                if (allowedKeys.includes(key)) {
                  onChangeDates({
                    selectedStartDate: newStartDate,
                    selectedEndDate: newEndDate,
                  })
                }
              }}
              onClickOutside={this.onClickOutsideEndDate}
              onBlur={this.onClickOutsideEndDate}
            />
          }
        />
      </EuiFormRow>
    )
  }

  onSelectStartDate = (startDate: Moment): void => {
    this.setState({ startDate })

    // @ts-ignore Property 'setFocus' does not exist on type 'Component<ReactDatePickerProps, {}, any>'. The reason for this error is that `EuiDatePicker` is an overlay on `ReactDatePicker` but returns a generic Component instead of a specific instance so not all methods are visible in TS definition
    this.endDatePickerRef.current?.setFocus?.()
  }

  onSelectEndDate = (endDate: Moment): void => {
    const { onChangeDates } = this.props
    const { startDate } = this.state

    this.setState({ endDate })

    onChangeDates({
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    })
  }

  onClickOutsideEndDate = () => {
    const { onChangeDates } = this.props
    const { startDate, endDate } = this.state

    onChangeDates({
      selectedStartDate: startDate,
      selectedEndDate: endDate,
    })
  }
}

export default injectIntl(DatePicker)
