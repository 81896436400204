/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiModalFooter,
} from '@elastic/eui'

import ProvideBillingDetailsButton from '../ProvideBillingDetailsButton'
import hourglassIllustration from '../../../../../files/illustration-hour-glass-128-white-bg.svg'
import hourglassIllustrationDark from '../../../../../files/illustration-hour-glass-128-ink-bg.svg'

import NeedMoreTimeLink from './NeedMoreTimeLink'
import messages from './messages'

import type { Theme } from '../../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

interface Props extends WrappedComponentProps {
  canExtendTrial: boolean
  theme: Theme
  onClick: () => void
  showExtendTrialModal: boolean
  close: () => void
  isGovCloud: boolean
}

const ExpiredTrialModalBody: FunctionComponent<Props> = ({
  onClick,
  theme,
  intl: { formatMessage },
  canExtendTrial,
  close,
  isGovCloud,
}) => (
  <Fragment>
    <EuiModalHeader>
      <EuiFlexGroup direction='column' alignItems='center' className='trialModalHeader'>
        <EuiFlexItem>
          <img
            src={theme === 'light' ? hourglassIllustration : hourglassIllustrationDark}
            alt={formatMessage(messages.hourglassAlt)}
            width='100px'
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiModalHeaderTitle>
            <FormattedMessage
              data-test-id='expired-trial-modal'
              {...messages.expiredTrialModalTitle}
            />
          </EuiModalHeaderTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiModalHeader>

    <EuiModalBody className='trialModalBody'>
      <EuiFlexGroup direction='column' alignItems='center'>
        <EuiFlexItem style={{ maxWidth: '600px' }}>
          <EuiText size='m' color='subdued'>
            <FormattedMessage
              data-test-id='expired-trial-modal.description'
              id='expired-trial-modal.description'
              defaultMessage='We hope you enjoyed your free trial. If you want to continue with Elasticsearch Service and unlock more features, {domain, select, govCloud {contact us about our subscription options} other {provide your credit card details}}. {needMoreTime}'
              values={{
                needMoreTime: (
                  <NeedMoreTimeLink onClick={onClick} canExtendTrial={canExtendTrial} />
                ),
                domain: isGovCloud ? 'govCloud' : 'other',
              }}
            />
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiModalBody>
    <EuiModalFooter>
      <EuiFlexGroup justifyContent='center'>
        {isGovCloud ? (
          <EuiButton data-test-id='govcloud-contact-us' href='mailto:gov-onboarding@elastic.co'>
            <FormattedMessage {...messages.contactUs} />
          </EuiButton>
        ) : (
          <ProvideBillingDetailsButton grow={false} fill={true} close={close} />
        )}
      </EuiFlexGroup>
    </EuiModalFooter>
  </Fragment>
)

export default injectIntl(ExpiredTrialModalBody)
