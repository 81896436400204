/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLink } from '@elastic/eui'

import ExternalLink from '../../../../../components/ExternalLink'

import messages from './messages'

import type { FunctionComponent } from 'react'

type Props = {
  canExtendTrial: boolean
  onClick: () => void
}

const NeedMoreTimeLink: FunctionComponent<Props> = ({ onClick, canExtendTrial }) => {
  if (canExtendTrial) {
    return (
      <EuiLink
        onClick={() => {
          onClick()
        }}
      >
        <FormattedMessage data-test-id='extendTrial' {...messages.needMoreTime} />
      </EuiLink>
    )
  }

  return (
    <ExternalLink href='https://info.elastic.co/cloud-trial-extension.html'>
      <FormattedMessage data-test-id='requestExtension' {...messages.needMoreTime} />
    </ExternalLink>
  )
}

export default NeedMoreTimeLink
