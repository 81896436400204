/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getSaasOrganizationUrl } from '../../lib/api/v1/urls'
import { FETCH_SAAS_ORGANIZATION } from '../../constants/actions'
import asyncRequest from '../asyncRequests'

import type { SaasOrganizationResponse } from '../../lib/api/v1/types'
import type { GetSaasOrganizationUrlParams } from '../../lib/api/v1/urls'
import type { ThunkAction } from '@/types/redux'

export function fetchSaasOrganization(
  params: GetSaasOrganizationUrlParams,
): ThunkAction<Promise<any>> {
  const url = getSaasOrganizationUrl(params)
  const { organizationId } = params

  return asyncRequest<SaasOrganizationResponse>({
    type: FETCH_SAAS_ORGANIZATION,
    url,
    meta: { organizationId },
    crumbs: [organizationId],
  })
}
