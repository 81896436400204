/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui'

import ProvideBillingDetailsButton from '../ProvideBillingDetailsButton'
import unpackFeaturesIllustration from '../../../../../files/illustration-feature-packed.svg'
import unpackFeaturesIllustrationDark from '../../../../../files/illustration-feature-packed-dark.svg'

import messages from './messages'
import TrialBillingMoreFeatures from './TrialBillingMoreFeatures'

import type { Theme } from '../../../../../types'
import type { WrappedComponentProps } from 'react-intl'
import type { FunctionComponent } from 'react'

import '../trialModal.scss'

interface Props extends WrappedComponentProps {
  theme: Theme
  close: () => void
  isGovCloud: boolean
}

const TrialModalBody: FunctionComponent<Props> = ({
  close,
  intl: { formatMessage },
  theme,
  isGovCloud,
}) => (
  <Fragment>
    <EuiModalHeader className='trialModalHeader'>
      <EuiFlexGroup direction='column' alignItems='center'>
        <EuiFlexItem>
          <img
            src={theme === 'light' ? unpackFeaturesIllustration : unpackFeaturesIllustrationDark}
            alt={formatMessage(messages.unlockAlt)}
            width='100px'
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiModalHeaderTitle>
            <FormattedMessage data-test-id='trial-modal' {...messages.unlockTitle} />
          </EuiModalHeaderTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiModalHeader>

    <EuiModalBody className='trialModalBody unlockFeatures'>
      <EuiFlexItem style={{ maxWidth: '400px' }}>
        <TrialBillingMoreFeatures isGovCloud={isGovCloud} />
      </EuiFlexItem>
    </EuiModalBody>
    <EuiModalFooter className='trialModalFooter'>
      {isGovCloud ? (
        <EuiButton href='mailto:gov-onboarding@elastic.co' fill={true}>
          <FormattedMessage {...messages.contactUs} />
        </EuiButton>
      ) : (
        <EuiFlexGroup justifyContent='center'>
          <ProvideBillingDetailsButton grow={false} close={close} fill={true} />
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={close}>
              <FormattedMessage {...messages.maybeLater} />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </EuiModalFooter>
  </Fragment>
)

export default injectIntl(TrialModalBody)
