/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'

import { EuiOverlayMask } from '@elastic/eui'

import { isTrialNotStartedUser } from '@/lib/billing'
import { createDeploymentUrl } from '@/lib/urlBuilder'

import MarketplaceActivationError from './MarketplaceActivationError'
import MarketplaceActivationModal from './MarketplaceActivationModal'

import type { ReactElement } from 'react'
import type { FieldAttributeValue, AllProps as Props } from './types'

import './marketplaceActivation.scss'

interface State {
  isErrorModalOpen: boolean
  isFormModalOpen: boolean
}

class MarketplaceActivation extends PureComponent<Props, State> {
  state: State = {
    isErrorModalOpen: false,
    isFormModalOpen: true,
  }

  componentDidMount(): void {
    const { fetchProfile } = this.props
    return fetchProfile()
  }

  render(): ReactElement | null {
    const { activateMarketplaceUserRequest, profile } = this.props
    const { isErrorModalOpen, isFormModalOpen } = this.state

    if (!profile || !profile.pending_marketplace_subscription) {
      return null
    }

    if (activateMarketplaceUserRequest.error && isErrorModalOpen) {
      return (
        <EuiOverlayMask>
          <MarketplaceActivationError
            error={activateMarketplaceUserRequest.error}
            onClose={this.onCloseError}
          />
        </EuiOverlayMask>
      )
    }

    const isTrialStartedUser = !isTrialNotStartedUser(profile)

    if (isFormModalOpen) {
      return (
        <EuiOverlayMask>
          <MarketplaceActivationModal
            data-test-id='MarketplaceActivationModal'
            activateMarketplaceUserRequest={activateMarketplaceUserRequest}
            isTrialStartedUser={isTrialStartedUser}
            onSubmit={this.onSubmit}
            onClose={this.onCloseForm}
          />
        </EuiOverlayMask>
      )
    }

    return null
  }

  onSubmit = ({ values }: { values: FieldAttributeValue }): void => {
    const { activateMarketplaceUser, profile, fetchProfile } = this.props

    if (!profile) {
      return
    }

    if (profile.marketplace_attributes && profile.marketplace_attributes.pending_subscription) {
      const partner = (profile.marketplace_attributes.pending_subscription as any).partner as string

      activateMarketplaceUser({
        firmographics: {
          company_name: values?.company,
        },
        partner,
      })
        .then(() => {
          fetchProfile()

          if (window.location.pathname === createDeploymentUrl()) {
            window.location.reload()
          }
        })
        .catch(() => this.setState({ isErrorModalOpen: true }, this.onCloseForm))
    }
  }

  onCloseError = (): void => this.setState({ isErrorModalOpen: false })

  onCloseForm = (): void => this.setState({ isFormModalOpen: false })
}

export default MarketplaceActivation
