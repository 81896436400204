/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiLoadingContent, EuiSpacer } from '@elastic/eui'

import { startPageActions } from '@/lib/apm'
import { CuiAlert } from '@/cui'
import { isMarketPlaceUser } from '@/lib/marketPlace'

import BillingStatements from './BillingStatements'
import BillingInvoices from './BillingInvoices'
import BillingHistoryPanel from './BillingHistoryPanel'
import BillingHistoryEmptyPrompt from './BillingHistoryEmptyPrompt'
import MarketplaceUserContent from './MarketplaceUserContent'

import type { ReactElement } from 'react'
import type { AllProps } from './types'

class BillingHistory extends Component<AllProps> {
  // This component manages its own page transactions because it interweaves
  // AWS and non-AWS behaviour, making it unsuitable to use with `withLoading`.
  // Probably this should be two separate components.
  startedTransaction = false

  componentDidMount(): void {
    const { fetchBillingHistory, profile } = this.props

    if (!profile) {
      return
    }

    const { organization_id } = profile

    if (!isMarketPlaceUser(profile)) {
      fetchBillingHistory({
        organizationId: organization_id!,
      })
    }
  }

  componentDidUpdate(): void {
    if (!this.startedTransaction) {
      this.startedTransaction = true
      startPageActions(`Billing history`)
    }
  }

  render(): ReactElement | null {
    const { fetchBillingHistoryRequest, profile, billingHistory } = this.props
    const { error, inProgress } = fetchBillingHistoryRequest

    if (!profile) {
      return null
    }

    if (error) {
      return (
        <BillingHistoryPanel>
          <CuiAlert type='error'>{error}</CuiAlert>
        </BillingHistoryPanel>
      )
    }

    if (isMarketPlaceUser(profile)) {
      return (
        <MarketplaceUserContent
          domain={profile.domain}
          data-test-id='billing-statements.billing-history.marketplace-info'
        />
      )
    }

    if (typeof billingHistory === 'undefined') {
      return <EuiLoadingContent />
    }

    const { statements, invoices } = billingHistory
    const hasStatements = statements.length > 0
    const hasInvoices = invoices.length > 0

    if (!hasStatements && !hasInvoices) {
      return <BillingHistoryEmptyPrompt profile={profile} />
    }

    return (
      <Fragment>
        {hasStatements && (
          <Fragment>
            <BillingStatements
              profile={profile}
              loading={inProgress}
              statements={statements}
              title={
                hasStatements && hasInvoices ? (
                  <FormattedMessage
                    id='billing-statements.statements.heading'
                    defaultMessage='Paid by balance'
                  />
                ) : undefined
              }
            />
            <EuiSpacer />
          </Fragment>
        )}

        {hasInvoices && (
          <BillingInvoices
            invoices={invoices}
            loading={inProgress}
            title={
              hasStatements && hasInvoices ? (
                <FormattedMessage
                  id='billing-statements.statements.heading'
                  defaultMessage='Paid by balance'
                />
              ) : undefined
            }
          />
        )}
      </Fragment>
    )
  }
}

export default BillingHistory
