/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexItem } from '@elastic/eui'
// @ts-ignore
import type { FlexItemGrowSize } from '@elastic/eui'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCardModalButton'

import type { FunctionComponent } from 'react'

interface Props {
  close: () => void
  fill: boolean
  grow: FlexItemGrowSize
}

const ProvideBillingDetailsButton: FunctionComponent<Props> = ({ close, fill, grow }) => (
  <EuiFlexItem grow={grow}>
    <CreditCardModalButton fill={fill} type='full' onSendBillingDetailsSuccess={close}>
      <FormattedMessage
        id='trial-modal.provide-billing-details'
        defaultMessage='Provide billing details'
      />
    </CreditCardModalButton>
  </EuiFlexItem>
)

export default ProvideBillingDetailsButton
