/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '../../../../actions/storedProcedures'
import { callStoredProcedureRequest } from '../../../../reducers'

import ConvertOrganizationToInvoicingButton from './ConvertOrganizationToInvoicingButton'

import type { AsyncRequestState, ReduxState } from '../../../../types'

type StateProps = {
  convertToInvoicingRequest: AsyncRequestState
}

type DispatchProps = {
  convertToInvoicing: () => void
  resetConvertToInvoicing: () => void
}

type ConsumerProps = {
  organizationId: string
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  convertToInvoicingRequest: callStoredProcedureRequest(state, `convert_organization_to_invoicing`),
})

const mapDispatchToProps = (dispatch, { organizationId }: ConsumerProps): DispatchProps => ({
  convertToInvoicing: () =>
    dispatch(
      callStoredProcedure({
        procedureName: `convert_organization_to_invoicing`,
        parameters: [organizationId],
        organizationId,
      }),
    ),
  resetConvertToInvoicing: () => {
    dispatch(resetCallStoredProcedureRequest(`convert_organization_to_invoicing`))
    return dispatch(clearProcedure(`convert_organization_to_invoicing`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ConvertOrganizationToInvoicingButton)
