/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { capitalize } from 'lodash'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import {
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiHorizontalRule,
  EuiCallOut,
} from '@elastic/eui'

import waves from '@/files/waves-lightblue.svg'
import SuggestedContentPanel from '@/apps/userconsole/components/Billing/BillingOverview/SuggestedContentPanel'
import SubscriptionManager from '@/components/User/BillingSubscription/components/SubscriptionManager'
import OrganizationPrepaids from '@/components/Organization/OrganizationPrepaids'

import PrepaidAccountDetailsPanel from '../BillingOverview/PrepaidAccountDetailsPanel'

import SubscriptionDetailsCard from './components/SubscriptionDetailsCard'
import PaymentDetailsCard from './components/PaymentDetailsCard'

import type { FunctionComponent } from 'react'
import type { UserProfile } from '@/types'

import './prepaidConsumptionSubscription.scss'

export interface Props {
  profile: UserProfile
  showPrepaidDetails: boolean
}

const PrepaidConsumptionSubscription: FunctionComponent<Props> = ({
  profile,
  showPrepaidDetails,
}) => (
  <Fragment>
    {profile.pendingSubscriptionUpdate && (
      <EuiCallOut
        size='s'
        title={
          <FormattedMessage
            id='billing-overview.subscription-updating.message'
            defaultMessage='Your subscription update is in process. Confirmation of the changes might take a while to appear. During the first month, you can lower the subscription level at any time. After that, lowering the subscription goes into effect at the end of the month.'
          />
        }
        iconType='clock'
        data-test-id='prepaid-consumption-subscription.pending-subscription'
      />
    )}

    <EuiSpacer size='m' />

    <EuiFlexGroup gutterSize='m' wrap={true}>
      <EuiFlexItem grow={7}>
        <EuiFlexGroup>
          <EuiFlexItem className='content-item'>
            <SubscriptionDetailsCard
              subscriptionLevel={capitalize(profile.level)}
              details={
                <FormattedMessage
                  id='prepaid-consumption-subscription.conversion-summary'
                  defaultMessage='Annual prepaid subscription since {date}.'
                  values={{
                    date: moment(profile.conversion_timestamp).format(`MMM DD, YYYY`),
                  }}
                />
              }
              footer={
                <SubscriptionManager
                  isButtonDisabled={Boolean(profile.pendingSubscriptionUpdate)}
                  buttonText={
                    <FormattedMessage
                      id='billing-subscription.change-plan-cta'
                      defaultMessage='Change plan'
                    />
                  }
                />
              }
              backgroundImage={waves}
            />
          </EuiFlexItem>
          <EuiFlexItem className='content-item'>
            <PaymentDetailsCard
              details={
                <FormattedMessage
                  id='billing-subscription.payment-details'
                  defaultMessage='Paid by purchase order'
                />
              }
              footer={
                <FormattedMessage
                  id='billing-subscription.payment-footer'
                  defaultMessage='To add more funds, contact your account sales executive.'
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xl' />

        <EuiFlexGroup>
          <EuiFlexItem className='content-item'>
            <PrepaidAccountDetailsPanel
              hasBorder={true}
              profile={profile}
              title={
                <EuiText>
                  <h6>
                    <FormattedMessage
                      id='billing-subscription.available-credits'
                      defaultMessage='Available credits'
                    />
                  </h6>
                </EuiText>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        {showPrepaidDetails && (
          <Fragment>
            <EuiSpacer size='s' />

            <EuiHorizontalRule />

            <EuiSpacer size='s' />

            <OrganizationPrepaids organizationId={profile.organization_id} />
          </Fragment>
        )}
      </EuiFlexItem>

      <EuiFlexItem>
        <SuggestedContentPanel
          hasBorder={true}
          data-test-id='billing-details-summary.suggested-content'
          showEcuMessage={true}
          title={
            <EuiText>
              <h6>
                <FormattedMessage
                  id='billing-history.suggested-content-title'
                  defaultMessage='Suggested content'
                />
              </h6>
            </EuiText>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

export default PrepaidConsumptionSubscription
