/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { getCapacityCost, getTotalCostForDimensions } from '@/lib/billing'
import { getVersion } from '@/lib/stackDeployments/selectors'
import {
  getSliderPrettyName,
  getSupportedSliderInstanceTypes,
  getTopologyElementName,
} from '@/lib/sliders'
import { getTopologiesFromTemplate } from '@/lib/deploymentTemplates/getTopologiesFromTemplate'
import {
  getKeys,
  getSizeOptionText,
} from '@/components/Topology/DeploymentTemplates/components/DeploymentInfrastructure/TopologyElement/helpers'
import { getInstanceConfigurationById } from '@/lib/instanceConfigurations/instanceConfiguration'

import { CapacityColor, StorageColor, DataTransferColor } from '../../../constants'

import type { ReactNode } from 'react'
import type { StackDeployment } from '@/types'
import type {
  DeploymentTemplateInfoV2,
  Dimension,
  DtsDimensionCosts,
  ItemsCosts,
} from '@/lib/api/v1/types'
import type { DimensionData } from '../types'

function buildEmptyItem(dimension: ReactNode, color: string): DimensionData {
  return {
    dimension,
    color,
    component: '',
    size: '-',
    quantity: 0,
    rate: '0',
    total: 0,
  }
}

function getFormattedHourlyRate(rate?: number) {
  return (
    <FormattedMessage
      id='billing-deployment-usage.price-per-hour'
      defaultMessage='{pricePerHour} / hour'
      values={{ pricePerHour: rate?.toFixed(4) ?? '-' }}
    />
  )
}

function getFormattedQuantity(quantity?: number) {
  return (
    <FormattedMessage
      id='billing-deployment-usage.hour-quantity'
      defaultMessage='{hours} hours'
      values={{ hours: quantity || 0 }}
    />
  )
}

function buildCapacityItemsBasedOnCosts(label: ReactNode, itemsCosts: ItemsCosts) {
  return itemsCosts.resources.map((item, index) => ({
    dimension: index === 0 ? label : null,
    component: <FormattedMessage {...getSliderPrettyName({ sliderInstanceType: item.kind })} />,
    color: CapacityColor,
    size: item.name,
    quantity: getFormattedQuantity(item.hours),
    rate: getFormattedHourlyRate(item.price_per_hour),
    total: item.price,
  }))
}

function buildCapacityItemsBasedOnTemplate(
  label: ReactNode,
  deployment: StackDeployment,
  deploymentTemplate: DeploymentTemplateInfoV2,
  itemsCosts: ItemsCosts,
) {
  const result: DimensionData[] = []
  const version = getVersion({ deployment })

  for (const sliderInstanceType of getSupportedSliderInstanceTypes()) {
    const instanceTopologies = getTopologiesFromTemplate({
      deploymentTemplate: deploymentTemplate.deployment_template,
      sliderInstanceType,
    })

    for (const topologyElement of instanceTopologies) {
      if (!topologyElement.size?.value) {
        continue
      }

      const name = getTopologyElementName({ sliderInstanceType, topologyElement, version })
      const {
        storage_multiplier,
        cpu_multiplier,
        id: instanceConfigurationId,
      } = getInstanceConfigurationById(
        deploymentTemplate.instance_configurations,
        topologyElement.instance_configuration_id!,
      )!
      const sliderInstanceCosts = itemsCosts.resources
        .filter(({ sku }) => sku.includes(instanceConfigurationId || ''))
        .reduce(
          (curr, item) => ({
            hours: curr.hours + item.hours,
            price_per_hour: curr.price_per_hour + item.price_per_hour,
            price: curr.price + item.price,
          }),
          {
            hours: 0,
            price_per_hour: 0,
            price: 0,
          },
        )

      const { primaryKey, secondaryKey } = getKeys({
        sliderInstanceType,
        instanceResource: `memory`,
        storageMultiplier: storage_multiplier,
      })
      const size = getSizeOptionText({
        instanceResource: `memory`,
        storageMultiplier: storage_multiplier,
        cpuMultiplier: cpu_multiplier,
        value: topologyElement.size.value,
        primaryKey,
        secondaryKey,
      })

      result.push({
        size: `${topologyElement.zone_count || 1} x ${size}`,
        color: CapacityColor,
        dimension: result.length === 0 ? label : null,
        component: <div>{name}</div>,
        quantity: getFormattedQuantity(sliderInstanceCosts?.hours),
        rate: getFormattedHourlyRate(sliderInstanceCosts?.price_per_hour),
        total: sliderInstanceCosts?.price || 0,
      })
    }
  }

  return result
}

function buildCapacityItemsWithAvailableData(
  label: ReactNode,
  deployment: StackDeployment,
  deploymentTemplate: DeploymentTemplateInfoV2 | null,
  itemsCosts: ItemsCosts,
) {
  // if we don't have a template, let's try our best to display
  // the data we have available
  if (!deploymentTemplate) {
    // if we don't have costs information then we don't have anything to show
    if (!itemsCosts.resources.length) {
      return []
    }

    return buildCapacityItemsBasedOnCosts(label, itemsCosts)
  }

  return buildCapacityItemsBasedOnTemplate(label, deployment, deploymentTemplate, itemsCosts)
}

export function buildCapacityItems(
  deployment: StackDeployment,
  deploymentTemplate: DeploymentTemplateInfoV2 | null,
  itemsCosts: ItemsCosts,
) {
  const capacityLabel = (
    <FormattedMessage id='billing-usage.capacity-dimension' defaultMessage='Capacity' />
  )
  const result = buildCapacityItemsWithAvailableData(
    capacityLabel,
    deployment,
    deploymentTemplate,
    itemsCosts,
  )

  if (!result.length) {
    return [buildEmptyItem(capacityLabel, CapacityColor)]
  }

  return [
    ...result,
    {
      dimension: null,
      color: CapacityColor,
      component: '',
      size: '',
      quantity: null,
      rate: '',
      total: getCapacityCost(itemsCosts.costs),
    },
  ]
}

function buildDtsItems(
  label: ReactNode,
  types: Array<Dimension['type']>,
  items: DtsDimensionCosts[],
  itemsCosts: ItemsCosts,
  color: string,
) {
  const itemsByType = items.filter(({ type }) => types.includes(type as Dimension['type']))

  if (!itemsByType.length) {
    return [buildEmptyItem(label, color)]
  }

  const result: DimensionData[] = itemsByType.map((item, index) => ({
    dimension: index === 0 ? label : null,
    component: item.name,
    color,
    size: '',
    quantity: item.quantity.formatted_value,
    rate: item.rate.formatted_value,
    total: item.cost,
  }))

  result.push({
    color,
    dimension: null,
    component: '',
    size: '',
    quantity: null,
    rate: '',
    total: getTotalCostForDimensions(types, itemsCosts.costs),
  })

  return result
}

export function buildDataTransferItems(itemsCosts: ItemsCosts) {
  return buildDtsItems(
    <FormattedMessage id='billing-usage.data-transfer-dimension' defaultMessage='Data transfer' />,
    ['data_in', 'data_internode', 'data_out'],
    itemsCosts.data_transfer_and_storage,
    itemsCosts,
    DataTransferColor,
  )
}

export function buildStorageItems(itemsCosts: ItemsCosts) {
  return buildDtsItems(
    <FormattedMessage id='billing-usage.storage-dimension' defaultMessage='Storage' />,
    ['storage_bytes', 'storage_api'],
    itemsCosts.data_transfer_and_storage,
    itemsCosts,
    StorageColor,
  )
}
