/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

export const VACATE_ES_CLUSTER: 'VACATE_ES_CLUSTER' = `VACATE_ES_CLUSTER`
export const VACATE_ES_CLUSTER_VALIDATE: 'VACATE_ES_CLUSTER_VALIDATE' = `VACATE_ES_CLUSTER_VALIDATE`
export const VACATE_ALLOCATOR: 'VACATE_ALLOCATOR' = `VACATE_ALLOCATOR`
export const VACATE_ALLOCATOR_VALIDATE: 'VACATE_ALLOCATOR_VALIDATE' = `VACATE_ALLOCATOR_VALIDATE`
export const SET_CONFIG: 'SET_CONFIG' = `SET_CONFIG`
export const RESET_ASYNC_REQUEST: 'RESET_ASYNC_REQUEST' = `RESET_ASYNC_REQUEST`
export const ASYNC_REQUEST_RESPONSE: 'ASYNC_REQUEST_RESPONSE' = `ASYNC_REQUEST_RESPONSE`
export const FETCH_PROXIES: 'FETCH_PROXIES' = `FETCH_PROXIES`
export const FETCH_ACTIVITY_DETAIL: 'FETCH_ACTIVITY_DETAIL' = `FETCH_ACTIVITY_DETAIL`
export const FETCH_ROOT: 'FETCH_ROOT' = `FETCH_ROOT`
export const SAVE_CLUSTER_PLAN: 'SAVE_CLUSTER_PLAN' = `SAVE_CLUSTER_PLAN`
export const AUTH_TOKEN: 'AUTH_TOKEN' = `AUTH_TOKEN`
export const LOG_IN: 'LOG_IN' = `LOG_IN`
export const LOG_OUT: 'LOG_OUT' = `LOG_OUT`
export const NEW_TEMP_SHIELD_USER_SUCCESS: 'NEW_TEMP_SHIELD_USER_SUCCESS' = `NEW_TEMP_SHIELD_USER_SUCCESS`
export const SET_LICENSE: 'SET_LICENSE' = `SET_LICENSE`
export const DELETE_RUNNER: 'DELETE_RUNNER' = `DELETE_RUNNER`
export const ACCEPT_EULA: 'ACCEPT_EULA' = `ACCEPT_EULA`
export const FETCH_LICENSE: 'FETCH_LICENSE' = `FETCH_LICENSE`
export const FETCH_RUNNER: 'FETCH_RUNNER' = `FETCH_RUNNER`
export const FETCH_BLUEPRINT_ROLES: 'FETCH_BLUEPRINT_ROLES' = `FETCH_BLUEPRINT_ROLES`
export const REMOVE_LICENSE: 'REMOVE_LICENSE' = `REMOVE_LICENSE`
export const UPDATE_RUNNER_ROLES: 'UPDATE_RUNNER_ROLES' = `UPDATE_RUNNER_ROLES`
export const DEMOTE_COORDINATOR: 'DEMOTE_COORDINATOR' = `DEMOTE_COORDINATOR`
export const FETCH_REGION: 'FETCH_REGION' = `FETCH_REGION`
export const FETCH_COORDINATOR_CANDIDATES: 'FETCH_COORDINATOR_CANDIDATES' = `FETCH_COORDINATOR_CANDIDATES`
export const FETCH_RECOVERY_INFO: 'FETCH_RECOVERY_INFO' = `FETCH_RECOVERY_INFO`
export const FETCH_CLUSTER_HEALTH: 'FETCH_CLUSTER_HEALTH' = `FETCH_CLUSTER_HEALTH`
export const FETCH_USER: 'FETCH_USER' = `FETCH_USER`
export const DELETE_ALLOCATOR: 'DELETE_ALLOCATOR' = `DELETE_ALLOCATOR`
export const SET_INSTANCE_STATUS: 'SET_INSTANCE_STATUS' = `SET_INSTANCE_STATUS`
export const FETCH_VERSIONS: 'FETCH_VERSIONS' = `FETCH_VERSIONS`
export const SET_MAINTENANCE_MODE: 'SET_MAINTENANCE_MODE' = `SET_MAINTENANCE_MODE`
export const SAVE_CLUSTER_ACL: 'SAVE_CLUSTER_ACL' = `SAVE_CLUSTER_ACL`
export const FETCH_ALLOCATOR: 'FETCH_ALLOCATOR' = `FETCH_ALLOCATOR`
export const RESET_CLUSTER_PASSWORD: 'RESET_CLUSTER_PASSWORD' = `RESET_CLUSTER_PASSWORD`
export const SET_ALLOCATOR_MAINTENANCE_MODE: 'SET_ALLOCATOR_MAINTENANCE_MODE' = `SET_ALLOCATOR_MAINTENANCE_MODE`
export const UPDATE_PHONE_HOME_ENABLED: 'UPDATE_PHONE_HOME_ENABLED' = `UPDATE_PHONE_HOME_ENABLED`
export const FETCH_PHONE_HOME_ENABLED: 'FETCH_PHONE_HOME_ENABLED' = `FETCH_PHONE_HOME_ENABLED`
export const CREATE_INSTANCE_CONFIGURATION: 'CREATE_INSTANCE_CONFIGURATION' = `CREATE_INSTANCE_CONFIGURATION`
export const DELETE_INSTANCE_CONFIGURATION: 'DELETE_INSTANCE_CONFIGURATION' = `DELETE_INSTANCE_CONFIGURATION`
export const UPDATE_INSTANCE_CONFIGURATION: 'UPDATE_INSTANCE_CONFIGURATION' = `UPDATE_INSTANCE_CONFIGURATION`
export const FETCH_SNAPSHOT_REPOSITORY: 'FETCH_SNAPSHOT_REPOSITORY' = `FETCH_SNAPSHOT_REPOSITORY`
export const FETCH_SNAPSHOT_REPOSITORIES: 'FETCH_SNAPSHOT_REPOSITORIES' = `FETCH_SNAPSHOT_REPOSITORIES`
export const DELETE_SNAPSHOT_REPOSITORY: 'DELETE_SNAPSHOT_REPOSITORY' = `DELETE_SNAPSHOT_REPOSITORY`
export const UPSERT_SNAPSHOT_REPOSITORY: 'UPSERT_SNAPSHOT_REPOSITORY' = `UPSERT_SNAPSHOT_REPOSITORY`
export const FETCH_PLATFORM: 'FETCH_PLATFORM' = `FETCH_PLATFORM`
export const FETCH_TLS_CERTIFICATE: 'FETCH_TLS_CERTIFICATE' = `FETCH_TLS_CERTIFICATE`
export const UPLOAD_TLS_CERTIFICATE: 'UPLOAD_TLS_CERTIFICATE' = `UPLOAD_TLS_CERTIFICATE`
export const UPDATE_KIBANA_PLAN: 'UPDATE_KIBANA_PLAN' = `UPDATE_KIBANA_PLAN`
export const CLEAR_CLUSTER_CREDENTIALS: 'CLEAR_CLUSTER_CREDENTIALS' = `CLEAR_CLUSTER_CREDENTIALS`
export const DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE: 'DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE' = `DOWNLOAD_CLUSTER_DIAGNOSTIC_BUNDLE`
export const DOWNLOAD_CLUSTER_LOGS: 'DOWNLOAD_CLUSTER_LOGS' = `DOWNLOAD_CLUSTER_LOGS`
export const UPLOAD_STACK_PACK: 'UPLOAD_STACK_PACK' = `UPLOAD_STACK_PACK`
export const DISABLE_PHONE_HOME: 'DISABLE_PHONE_HOME' = `DISABLE_PHONE_HOME`
export const SET_DISK_QUOTA: 'SET_DISK_QUOTA' = `SET_DISK_QUOTA`
export const SET_INSTANCE_CAPACITY: 'SET_INSTANCE_CAPACITY' = `SET_INSTANCE_CAPACITY`
export const RETRY_FAILED_SHARD_ALLOCATIONS: 'RETRY_FAILED_SHARD_ALLOCATIONS' = `RETRY_FAILED_SHARD_ALLOCATIONS`
export const FETCH_SNAPSHOTS: 'FETCH_SNAPSHOTS' = `FETCH_SNAPSHOTS`
export const RESTORE_SNAPSHOT: 'RESTORE_SNAPSHOT' = `RESTORE_SNAPSHOT`
export const DELETE_STACK_VERSION: 'DELETE_STACK_VERSION' = `DELETE_STACK_VERSION`
export const RESET_TEMP_SHIELD_USER: 'RESET_TEMP_SHIELD_USER' = `RESET_TEMP_SHIELD_USER`
export const SET_THEME: 'SET_THEME' = `SET_THEME`
export const ADD_ALLOCATOR_TAG: 'ADD_ALLOCATOR_TAG' = `ADD_ALLOCATOR_TAG`
export const REMOVE_ALLOCATOR_TAG: 'REMOVE_ALLOCATOR_TAG' = `REMOVE_ALLOCATOR_TAG`
export const SEARCH_ALLOCATORS: 'SEARCH_ALLOCATORS' = `SEARCH_ALLOCATORS`
export const CALL_STORED_PROCEDURE: 'CALL_STORED_PROCEDURE' = `CALL_STORED_PROCEDURE`
export const CLEAR_STORED_PROCEDURE: 'CLEAR_STORED_PROCEDURE' = `CLEAR_STORED_PROCEDURE`
export const FETCH_INSTANCE_TYPES: 'FETCH_INSTANCE_TYPES' = `FETCH_INSTANCE_TYPES`
export const FETCH_INSTANCE_CONFIGURATIONS: 'FETCH_INSTANCE_CONFIGURATIONS' = `FETCH_INSTANCE_CONFIGURATIONS`
export const FETCH_INSTANCE_CONFIGURATION: 'FETCH_INSTANCE_CONFIGURATION' = `FETCH_INSTANCE_CONFIGURATION`
export const CREATE_DEPLOYMENT_TEMPLATE: 'CREATE_DEPLOYMENT_TEMPLATE' = `CREATE_DEPLOYMENT_TEMPLATE`
export const DELETE_DEPLOYMENT_TEMPLATE: 'DELETE_DEPLOYMENT_TEMPLATE' = `DELETE_DEPLOYMENT_TEMPLATE`
export const FETCH_DEPLOYMENT_TEMPLATES: 'FETCH_DEPLOYMENT_TEMPLATES' = `FETCH_DEPLOYMENT_TEMPLATES`
export const FETCH_GLOBAL_DEPLOYMENT_TEMPLATES: 'FETCH_GLOBAL_DEPLOYMENT_TEMPLATES' = `FETCH_GLOBAL_DEPLOYMENT_TEMPLATES`
export const FETCH_DEPLOYMENT_TEMPLATE: 'FETCH_DEPLOYMENT_TEMPLATE' = `FETCH_DEPLOYMENT_TEMPLATE`
export const UPDATE_DEPLOYMENT_TEMPLATE: 'UPDATE_DEPLOYMENT_TEMPLATE' = `UPDATE_DEPLOYMENT_TEMPLATE`
export const FETCH_VERSION: 'FETCH_VERSION' = `FETCH_VERSION`
export const REQUIRE_MFA: 'REQUIRE_MFA' = `REQUIRE_MFA`
export const SEND_MFA_CHALLENGE: 'SEND_MFA_CHALLENGE' = `SEND_MFA_CHALLENGE`
export const SUBMIT_MFA_RESPONSE: 'SUBMIT_MFA_RESPONSE' = `SUBMIT_MFA_RESPONSE`
export const CLEAR_VERSIONS: 'CLEAR_VERSIONS' = `CLEAR_VERSIONS`
export const QUERY_DEPLOYMENT_PROXY: 'QUERY_DEPLOYMENT_PROXY' = `QUERY_DEPLOYMENT_PROXY`
export const CLEAR_DEPLOYMENT_PROXY_RESPONSE: 'CLEAR_DEPLOYMENT_PROXY_RESPONSE' = `CLEAR_DEPLOYMENT_PROXY_RESPONSE`
export const CLEAR_API_CONSOLE_HISTORY: 'CLEAR_API_CONSOLE_HISTORY' = `CLEAR_API_CONSOLE_HISTORY`
export const SET_API_CONSOLE_HISTORY: 'SET_API_CONSOLE_HISTORY' = `SET_API_CONSOLE_HISTORY`
export const SET_API_CONSOLE_REQUEST: 'SET_API_CONSOLE_REQUEST' = `SET_API_CONSOLE_REQUEST`
export const FETCH_SAAS_USER: 'FETCH_SAAS_USER' = `FETCH_SAAS_USER`
export const RESET_APM_TOKEN: 'RESET_APM_TOKEN' = `RESET_APM_TOKEN`
export const FETCH_SNAPSHOT_STATUS: 'FETCH_SNAPSHOT_STATUS' = `FETCH_SNAPSHOT_STATUS`
export const CLEAR_APM_TOKEN: 'CLEAR_APM_TOKEN' = `CLEAR_APM_TOKEN`
export const SUBMIT_USER_FEEDBACK: 'SUBMIT_USER_FEEDBACK' = `SUBMIT_USER_FEEDBACK`
export const FETCH_ALL_USERS: 'FETCH_ALL_USERS' = `FETCH_ALL_USERS`
export const FETCH_CURRENT_USER: 'FETCH_CURRENT_USER' = `FETCH_CURRENT_USER`
export const UPDATE_CURRENT_USER: 'UPDATE_CURRENT_USER' = `UPDATE_CURRENT_USER`
export const CREATE_USER: 'CREATE_USER' = `CREATE_USER`
export const UPDATE_USER: 'UPDATE_USER' = `UPDATE_USER`
export const DELETE_USER: 'DELETE_USER' = `DELETE_USER`
export const FETCH_MFA_DEVICES: 'FETCH_MFA_DEVICES' = `FETCH_MFA_DEVICES`
export const RESET_MFA: 'RESET_MFA' = `RESET_MFA`
export const PUT_WHITELISTED_VERSION: 'PUT_WHITELISTED_VERSION' = `PUT_WHITELISTED_VERSION`
export const DELETE_WHITELISTED_VERSION: 'DELETE_WHITELISTED_VERSION' = `DELETE_WHITELISTED_VERSION`
export const FETCH_WHITELISTED_VERSIONS: 'FETCH_WHITELISTED_VERSIONS' = `FETCH_WHITELISTED_VERSIONS`
export const FETCH_AUTH_METHODS: 'FETCH_AUTH_METHODS' = `FETCH_AUTH_METHODS`
export const TAKE_SNAPSHOT: 'TAKE_SNAPSHOT' = `TAKE_SNAPSHOT`
export const DISMISS_NOTIFICATION_MESSAGE: 'DISMISS_NOTIFICATION_MESSAGE' = `DISMISS_NOTIFICATION_MESSAGE`
export const FETCH_UPGRADE_ASSISTANT_STATUS: 'FETCH_UPGRADE_ASSISTANT_STATUS' = `FETCH_UPGRADE_ASSISTANT_STATUS`
export const FETCH_CCS_SETTINGS: 'FETCH_CCS_SETTINGS' = `FETCH_CCS_SETTINGS`
export const UPDATE_CCS_SETTINGS: 'UPDATE_CCS_SETTINGS' = `UPDATE_CCS_SETTINGS`
export const FETCH_KEYSTORE: 'FETCH_KEYSTORE' = `FETCH_KEYSTORE`
export const CREATE_SECRET: 'CREATE_SECRET' = `CREATE_SECRET`
export const DELETE_SECRET: 'DELETE_SECRET' = `DELETE_SECRET`
export const START_CONSTRUCTOR_MAINTENANCE_MODE: 'START_CONSTRUCTOR_MAINTENANCE_MODE' = `START_CONSTRUCTOR_MAINTENANCE_MODE`
export const STOP_CONSTRUCTOR_MAINTENANCE_MODE: 'STOP_CONSTRUCTOR_MAINTENANCE_MODE' = `STOP_CONSTRUCTOR_MAINTENANCE_MODE`
export const FETCH_CCS_ELIGIBLE_REMOTES: 'FETCH_CCS_ELIGIBLE_REMOTES' = `FETCH_CCS_ELIGIBLE_REMOTES`
export const FETCH_RESOURCE_COMMENTS: 'FETCH_RESOURCE_COMMENTS' = `FETCH_RESOURCE_COMMENTS`
export const CREATE_RESOURCE_COMMENT: 'CREATE_RESOURCE_COMMENT' = `CREATE_RESOURCE_COMMENT`
export const DELETE_RESOURCE_COMMENT: 'DELETE_RESOURCE_COMMENT' = `DELETE_RESOURCE_COMMENT`
export const UPDATE_RESOURCE_COMMENT: 'UPDATE_RESOURCE_COMMENT' = `UPDATE_RESOURCE_COMMENT`
export const UPDATE_PENDING_TEMPLATE_NAME: 'UPDATE_PENDING_TEMPLATE_NAME' = `UPDATE_PENDING_TEMPLATE_NAME`
export const UPDATE_PENDING_TEMPLATE_DESCRIPTION: 'UPDATE_PENDING_TEMPLATE_DESCRIPTION' = `UPDATE_PENDING_TEMPLATE_DESCRIPTION`
export const HEROKU_AUTH_HANDSHAKE: 'HEROKU_AUTH_HANDSHAKE' = `HEROKU_AUTH_HANDSHAKE`
export const FETCH_HEAP_DUMPS: 'FETCH_HEAP_DUMPS' = `FETCH_HEAP_DUMPS`
export const CAPTURE_HEAP_DUMP: 'CAPTURE_HEAP_DUMP' = `CAPTURE_HEAP_DUMP`
export const CAPTURE_THREAD_DUMP: 'CAPTURE_THREAD_DUMP' = `CAPTURE_THREAD_DUMP`
export const FETCH_API_KEYS: 'FETCH_API_KEYS' = `FETCH_API_KEYS`
export const REVOKE_API_KEY: 'REVOKE_API_KEY' = `REVOKE_API_KEY`
export const GENERATE_API_KEY: 'GENERATE_API_KEY' = `GENERATE_API_KEY`
export const REVOKE_API_KEYS: 'REVOKE_API_KEYS' = `REVOKE_API_KEYS`
export const FETCH_MANAGED_API_KEYS: 'FETCH_MANAGED_API_KEYS' = `FETCH_MANAGED_API_KEYS`
export const CREATE_STACK_DEPLOYMENT: 'CREATE_STACK_DEPLOYMENT' = `CREATE_STACK_DEPLOYMENT`
export const FETCH_STACK_DEPLOYMENT: 'FETCH_STACK_DEPLOYMENT' = `FETCH_STACK_DEPLOYMENT`
export const SEARCH_STACK_DEPLOYMENTS: 'SEARCH_STACK_DEPLOYMENTS' = `SEARCH_STACK_DEPLOYMENTS`
export const UPDATE_STACK_DEPLOYMENT: 'UPDATE_STACK_DEPLOYMENT' = `UPDATE_STACK_DEPLOYMENT`
export const UPDATE_STACK_DEPLOYMENT_DRY_RUN: 'UPDATE_STACK_DEPLOYMENT_DRY_RUN' = `UPDATE_STACK_DEPLOYMENT_DRY_RUN`
export const DELETE_STACK_DEPLOYMENT: 'DELETE_STACK_DEPLOYMENT' = `DELETE_STACK_DEPLOYMENT`
export const CLAIM_INSTANT_STACK_DEPLOYMENT: 'CLAIM_INSTANT_STACK_DEPLOYMENT' = `CLAIM_INSTANT_STACK_DEPLOYMENT`
export const FETCH_PROVIDERS: 'FETCH_PROVIDERS' = `FETCH_PROVIDERS`
export const RESTART_DEPLOYMENT_RESOURCE: 'RESTART_DEPLOYMENT_RESOURCE' = `RESTART_DEPLOYMENT_RESOURCE`
export const RESTART_DEPLOYMENT_ES_RESOURCE: 'RESTART_DEPLOYMENT_ES_RESOURCE' = `RESTART_DEPLOYMENT_ES_RESOURCE`
export const SHUTDOWN_DEPLOYMENT: 'SHUTDOWN_DEPLOYMENT' = `SHUTDOWN_DEPLOYMENT`
export const DELETE_DEPLOYMENT_RESOURCE: 'DELETE_DEPLOYMENT_RESOURCE' = `DELETE_DEPLOYMENT_RESOURCE`
export const CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE: 'CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE' = `CLEAR_STACK_DEPLOYMENT_CREATE_RESPONSE`
export const SET_DEPLOYMENT_RESOURCE_METADATA: 'SET_DEPLOYMENT_RESOURCE_METADATA' = `SET_DEPLOYMENT_RESOURCE_METADATA`
export const CANCEL_DEPLOYMENT_RESOURCE_PLAN: 'CANCEL_DEPLOYMENT_RESOURCE_PLAN' = `CANCEL_DEPLOYMENT_RESOURCE_PLAN`
export const RESTORE_DEPLOYMENT: 'RESTORE_DEPLOYMENT' = `RESTORE_DEPLOYMENT`
export const CREATE_SAAS_USER_ADMIN: 'CREATE_SAAS_USER_ADMIN' = `CREATE_SAAS_USER_ADMIN`
export const CREATE_SAAS_USER: 'CREATE_SAAS_USER' = `CREATE_SAAS_USER`
export const CREATE_PENDING_SAAS_USER: 'CREATE_PENDING_SAAS_USER' = `CREATE_PENDING_SAAS_USER` // nosemgrep
export const EXTEND_TRIAL: 'EXTEND_TRIAL' = `EXTEND_TRIAL`
export const FETCH_CLOUD_STATUS: 'FETCH_CLOUD_STATUS' = `FETCH_CLOUD_STATUS`
export const FETCH_BLOGS: 'FETCH_BLOGS' = `FETCH_BLOGS`
export const FETCH_UPCOMING_EVENTS: 'FETCH_UPCOMING_EVENTS' = `FETCH_UPCOMING_EVENTS`
export const FETCH_EOL_STATUS: 'FETCH_EOL_STATUS' = `FETCH_EOL_STATUS`
export const FETCH_DEPLOYMENT_HEALTH: 'FETCH_DEPLOYMENT_HEALTH' = `FETCH_DEPLOYMENT_HEALTH`
export const FETCH_FEED: 'FETCH_FEED' = `FETCH_FEED`
export const SEARCH_RUNNERS: 'SEARCH_RUNNERS' = `SEARCH_RUNNERS`
export const UPDATE_SAAS_USER_PROFILE: 'UPDATE_SAAS_USER_PROFILE' = `UPDATE_SAAS_USER_PROFILE`
export const REFRESH_SAAS_CURRENT_USER: 'REFRESH_SAAS_CURRENT_USER' = `REFRESH_SAAS_CURRENT_USER`
export const FETCH_AUTHENTICATION_INFO: 'FETCH_AUTHENTICATION_INFO' = `FETCH_AUTHENTICATION_INFO`
export const SEARCH_FOR_ANYTHING: 'SEARCH_FOR_ANYTHING' = `SEARCH_FOR_ANYTHING`
export const FETCH_ADMINCONSOLES: 'FETCH_ADMINCONSOLES' = `FETCH_ADMINCONSOLES`
export const FETCH_ADMINCONSOLE_LOGGING_SETTINGS: 'FETCH_ADMINCONSOLE_LOGGING_SETTINGS' = `FETCH_ADMINCONSOLE_LOGGING_SETTINGS`
export const PATCH_ADMINCONSOLE_LOGGING_SETTINGS: 'PATCH_ADMINCONSOLE_LOGGING_SETTINGS' = `PATCH_ADMINCONSOLE_LOGGING_SETTINGS`
export const CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER' = `CLEAR_CURRENT_USER`
export const ENABLE_SLM: 'ENABLE_SLM' = `ENABLE_SLM`
export const EXECUTE_SLM_POLICY: 'EXECUTE_SLM_POLICY' = `EXECUTE_SLM_POLICY`
export const FETCH_CONTAINER_SETS: 'FETCH_CONTAINER_SETS' = `FETCH_CONTAINER_SETS`
export const FETCH_CONTAINER_SET: 'FETCH_CONTAINER_SET' = `FETCH_CONTAINER_SET`
export const FETCH_CONTAINER: 'FETCH_CONTAINER' = `FETCH_CONTAINER`
export const FETCH_RUNNER_LOGGING_SETTINGS: 'FETCH_RUNNER_LOGGING_SETTINGS' = `FETCH_RUNNER_LOGGING_SETTINGS`
export const PATCH_RUNNER_LOGGING_SETTINGS: 'PATCH_RUNNER_LOGGING_SETTINGS' = `PATCH_RUNNER_LOGGING_SETTINGS`
export const FETCH_ALLOCATOR_LOGGING_SETTINGS: 'FETCH_ALLOCATOR_LOGGING_SETTINGS' = `FETCH_ALLOCATOR_LOGGING_SETTINGS`
export const PATCH_ALLOCATOR_LOGGING_SETTINGS: 'PATCH_ALLOCATOR_LOGGING_SETTINGS' = `PATCH_ALLOCATOR_LOGGING_SETTINGS`
export const FETCH_CONSTRUCTOR_LOGGING_SETTINGS: 'FETCH_CONSTRUCTOR_LOGGING_SETTINGS' = `FETCH_CONSTRUCTOR_LOGGING_SETTINGS`
export const PATCH_CONSTRUCTOR_LOGGING_SETTINGS: 'PATCH_CONSTRUCTOR_LOGGING_SETTINGS' = `PATCH_CONSTRUCTOR_LOGGING_SETTINGS`
export const ENABLE_ILM: 'ENABLE_ILM' = `ENABLE_ILM`
export const AUTH_WITH_OPEN_ID: 'AUTH_WITH_OPEN_ID' = `AUTH_WITH_OPEN_ID`
export const FETCH_JWT_TOKEN: 'FETCH_JWT_TOKEN' = `FETCH_JWT_TOKEN`
export const CREATE_TRAFFIC_FILTER_RULESET: 'CREATE_TRAFFIC_FILTER_RULESET' = `CREATE_TRAFFIC_FILTER_RULESET`
export const CREATE_TRAFFIC_FILTER_RULESET_ASSOCIATION: 'CREATE_TRAFFIC_FILTER_RULESET_ASSOCIATION' = `CREATE_TRAFFIC_FILTER_RULESET_ASSOCIATION`
export const DELETE_TRAFFIC_FILTER_RULESET: 'DELETE_TRAFFIC_FILTER_RULESET' = `DELETE_TRAFFIC_FILTER_RULESET`
export const DELETE_TRAFFIC_FILTER_RULESET_ASSOCIATION: 'DELETE_TRAFFIC_FILTER_RULESET_ASSOCIATION' = `DELETE_TRAFFIC_FILTER_RULESET_ASSOCIATION`
export const FETCH_TRAFFIC_FILTER_RULESET: 'FETCH_TRAFFIC_FILTER_RULESET' = `FETCH_TRAFFIC_FILTER_RULESET`
export const FETCH_TRAFFIC_FILTER_RULESETS: 'FETCH_TRAFFIC_FILTER_RULESETS' = `FETCH_TRAFFIC_FILTER_RULESETS`
export const UPDATE_TRAFFIC_FILTER_RULESET: 'UPDATE_TRAFFIC_FILTER_RULESET' = `UPDATE_TRAFFIC_FILTER_RULESET`
export const SET_APP_SEARCH_READ_ONLY_MODE: 'SET_APP_SEARCH_READ_ONLY_MODE' = `SET_APP_SEARCH_READ_ONLY_MODE`
export const GET_APP_SEARCH_READ_ONLY_MODE: 'GET_APP_SEARCH_READ_ONLY_MODE' = `GET_APP_SEARCH_READ_ONLY_MODE`
export const WATCH_APP_SEARCH_MIGRATION_SNAPSHOT: 'WATCH_APP_SEARCH_MIGRATION_SNAPSHOT' = `WATCH_APP_SEARCH_MIGRATION_SNAPSHOT`
export const START_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION: 'START_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION' = `START_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION`
export const STOP_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION: 'STOP_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION' = `STOP_APP_SEARCH_TO_ENTERPRISE_SEARCH_MIGRATION`
export const CREATE_DEPLOYMENT_EXTENSION: 'CREATE_DEPLOYMENT_EXTENSION' = `CREATE_DEPLOYMENT_EXTENSION`
export const DELETE_DEPLOYMENT_EXTENSION: 'DELETE_DEPLOYMENT_EXTENSION' = `DELETE_DEPLOYMENT_EXTENSION`
export const FETCH_DEPLOYMENT_EXTENSION: 'FETCH_DEPLOYMENT_EXTENSION' = `FETCH_DEPLOYMENT_EXTENSION`
export const FETCH_DEPLOYMENT_EXTENSIONS: 'FETCH_DEPLOYMENT_EXTENSIONS' = `FETCH_DEPLOYMENT_EXTENSIONS`
export const UPDATE_DEPLOYMENT_EXTENSION: 'UPDATE_DEPLOYMENT_EXTENSION' = `UPDATE_DEPLOYMENT_EXTENSION`
export const UPLOAD_DEPLOYMENT_EXTENSION: 'UPLOAD_DEPLOYMENT_EXTENSION' = `UPLOAD_DEPLOYMENT_EXTENSION`
export const FETCH_HAPPY_SAD_CLUSTERS: 'FETCH_HAPPY_SAD_CLUSTERS' = `FETCH_HAPPY_SAD_CLUSTERS`
export const SET_MONITORING_DEPLOYMENT: 'SET_MONITORING_DEPLOYMENT' = `SET_MONITORING_DEPLOYMENT`
export const STOP_MONITORING_DEPLOYMENT: 'STOP_MONITORING_DEPLOYMENT' = `STOP_MONITORING_DEPLOYMENT`
export const CREATE_TRUST_RELATIONSHIP: 'CREATE_TRUST_RELATIONSHIP' = `CREATE_TRUST_RELATIONSHIP`
export const FETCH_TRUST_RELATIONSHIP: 'FETCH_TRUST_RELATIONSHIP' = `FETCH_TRUST_RELATIONSHIP`
export const FETCH_TRUST_RELATIONSHIPS: 'FETCH_TRUST_RELATIONSHIPS' = `FETCH_TRUST_RELATIONSHIPS`
export const UPDATE_TRUST_RELATIONSHIP: 'UPDATE_TRUST_RELATIONSHIP' = `UPDATE_TRUST_RELATIONSHIP`
export const DELETE_TRUST_RELATIONSHIP: 'DELETE_TRUST_RELATIONSHIP' = `DELETE_TRUST_RELATIONSHIP`
export const FETCH_API_BASE_URL: 'FETCH_API_BASE_URL' = `FETCH_API_BASE_URL`
export const UPDATE_API_BASE_URL: 'UPDATE_API_BASE_URL' = `UPDATE_API_BASE_URL`
export const FETCH_DEPLOYMENT_DOMAIN_NAME: 'FETCH_DEPLOYMENT_DOMAIN_NAME' = `FETCH_DEPLOYMENT_DOMAIN_NAME`
export const UPDATE_DEPLOYMENT_DOMAIN_NAME: 'UPDATE_DEPLOYMENT_DOMAIN_NAME' = `UPDATE_DEPLOYMENT_DOMAIN_NAME`
export const ENABLE_CROSS_CLUSTER_REPLICATION: 'ENABLE_CROSS_CLUSTER_REPLICATION' = `ENABLE_CROSS_CLUSTER_REPLICATION`
export const FETCH_CURRENT_ACCOUNT: 'FETCH_CURRENT_ACCOUNT' = `FETCH_CURRENT_ACCOUNT`
export const UPDATE_CURRENT_ACCOUNT: 'UPDATE_CURRENT_ACCOUNT' = `UPDATE_CURRENT_ACCOUNT`
export const UPDATE_DEPLOYMENT_ALIAS: 'UPDATE_DEPLOYMENT_ALIAS' = `UPDATE_DEPLOYMENT_ALIAS`
export const UPDATE_DEPLOYMENT_TAGS: 'UPDATE_DEPLOYMENT_TAGS' = `UPDATE_DEPLOYMENT_TAGS`
export const DEPLOYMENT_ALIAS_EDIT_ACCESS: 'DEPLOYMENT_ALIAS_EDIT_ACCESS' = `DEPLOYMENT_ALIAS_EDIT_ACCESS`
export const UPDATE_DEPLOYMENT_ALIAS_EDIT_ACCESS: 'UPDATE_DEPLOYMENT_ALIAS_EDIT_ACCESS' = `UPDATE_DEPLOYMENT_ALIAS_EDIT_ACCESS`
export const GET_DEPLOYMENT_ALIAS_EDIT_ACCESS: 'GET_DEPLOYMENT_ALIAS_EDIT_ACCESS' = `GET_DEPLOYMENT_ALIAS_EDIT_ACCESS`
export const FETCH_ESS_PRICES: 'FETCH_ESS_PRICES' = `FETCH_ESS_PRICES`
export const FETCH_NODE_STATS: 'FETCH_NODE_STATS' = `FETCH_NODE_STATS`
export const CREATE_ORGANIZATION: 'CREATE_ORGANIZATION' = `CREATE_ORGANIZATION`
export const UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION' = `UPDATE_ORGANIZATION`
export const DELETE_ORGANIZATION: 'DELETE_ORGANIZATION' = `DELETE_ORGANIZATION`
export const FETCH_ORGANIZATION: 'FETCH_ORGANIZATION' = `FETCH_ORGANIZATION`
export const FETCH_ORGANIZATIONS: 'FETCH_ORGANIZATIONS' = `FETCH_ORGANIZATIONS`
export const ACCEPT_ORGANIZATION_INVITATION: 'ACCEPT_ORGANIZATION_INVITATION' = `ACCEPT_ORGANIZATION_INVITATION`
export const UPSERT_ORGANIZATION_INVITATION: 'UPSERT_ORGANIZATION_INVITATION' = `UPSERT_ORGANIZATION_INVITATION`
export const DELETE_ORGANIZATION_INVITATIONS: 'DELETE_ORGANIZATION_INVITATIONS' = `DELETE_ORGANIZATION_INVITATIONS`
export const FETCH_ORGANIZATION_INVITATION: 'FETCH_ORGANIZATION_INVITATION' = `FETCH_ORGANIZATION_INVITATION`
export const FETCH_ORGANIZATION_INVITATIONS: 'FETCH_ORGANIZATION_INVITATIONS' = `FETCH_ORGANIZATION_INVITATIONS`
export const CREATE_ORGANIZATION_MEMBERSHIP: 'CREATE_ORGANIZATION_MEMBERSHIP' = `CREATE_ORGANIZATION_MEMBERSHIP`
export const DELETE_ORGANIZATION_MEMBERSHIPS: 'DELETE_ORGANIZATION_MEMBERSHIPS' = `DELETE_ORGANIZATION_MEMBERSHIPS`
export const FETCH_ORGANIZATION_MEMBERSHIPS: 'FETCH_ORGANIZATION_MEMBERSHIPS' = `FETCH_ORGANIZATION_MEMBERSHIPS`
export const ADD_ORGANIZATION_MEMBER: 'ADD_ORGANIZATION_MEMBER' = `ADD_ORGANIZATION_MEMBER`
export const DELETE_EMAIL_SUPPRESSIONS: 'DELETE_EMAIL_SUPPRESSIONS' = `DELETE_EMAIL_SUPPRESSIONS`
export const FETCH_DEPLOYMENTS_COSTS: 'FETCH_DEPLOYMENTS_COSTS' = `FETCH_DEPLOYMENTS_COSTS`
export const FETCH_ACCOUNT_ITEMS_COSTS: 'FETCH_ACCOUNT_ITEMS_COSTS' = `FETCH_ACCOUNT_ITEMS_COSTS`
export const FETCH_DEPLOYMENT_COST_ITEMS: 'FETCH_DEPLOYMENT_COST_ITEMS' = `FETCH_DEPLOYMENT_COST_ITEMS`
export const FETCH_ACCOUNT_COSTS_OVERVIEW: 'FETCH_ACCOUNT_COSTS_OVERVIEW' = `FETCH_ACCOUNT_COSTS_OVERVIEW`
export const CREATE_MARKETPLACE_USER: 'CREATE_MARKETPLACE_USER' = `CREATE_MARKETPLACE_USER`
export const SUBSCRIBE_MARKETPLACE_USER: 'SUBSCRIBE_MARKETPLACE_USER' = `SUBSCRIBE_MARKETPLACE_USER`
export const ACTIVATE_MARKETPLACE_USER: 'ACTIVATE_MARKETPLACE_USER' = `ACTIVATE_MARKETPLACE_USER`
export const SAVE_MARKETPLACE_TOKEN: 'SAVE_MARKETPLACE_TOKEN' = `SAVE_MARKETPLACE_TOKEN`
export const FETCH_PREPAID_BALANCE_LINE_ITEMS: 'FETCH_PREPAID_BALANCE_LINE_ITEMS' = `FETCH_PREPAID_BALANCE_LINE_ITEMS`
export const FETCH_BILLING_HISTORY: 'FETCH_BILLING_HISTORY' = `FETCH_BILLING_HISTORY`
export const FETCH_ORGANIZATION_PREPAIDS: 'FETCH_ORGANIZATION_PREPAIDS' = `FETCH_ORGANIZATION_PREPAIDS`
export const FETCH_SAAS_ORGANIZATION: 'FETCH_SAAS_ORGANIZATION' = `FETCH_SAAS_ORGANIZATION`
export const FETCH_ORGANIZATION_BILLING_DETAILS: 'FETCH_ORGANIZATION_BILLING_DETAILS' = `FETCH_ORGANIZATION_BILLING_DETAILS`
export const GENERATE_RESET_PASSWORD_LINK: 'GENERATE_RESET_PASSWORD_LINK' = `GENERATE_RESET_PASSWORD_LINK` // nosemgrep
export const MIGRATE_DEPLOYMENT_TEMPLATE: `MIGRATE_DEPLOYMENT_TEMPLATE` = `MIGRATE_DEPLOYMENT_TEMPLATE`
export const LOCK_DEPLOYMENT: 'LOCK_DEPLOYMENT' = `LOCK_DEPLOYMENT`
export const SCRUB_SAAS_USER: 'SCRUB_SAAS_USER' = `SCRUB_SAAS_USER` // nosemgrep
export const FETCH_COSTS_CHARTS: 'FETCH_COSTS_CHARTS' = `FETCH_COSTS_CHARTS`
export const FETCH_COSTS_CHARTS_BY_DEPLOYMENT: 'FETCH_COSTS_CHARTS_BY_DEPLOYMENT' = `FETCH_COSTS_CHARTS_BY_DEPLOYMENT`
export const SAVE_TOKEN: 'SAVE_TOKEN' = `SAVE_TOKEN`
export const ACTIVATE_LINE_ITEMS: 'ACTIVATE_LINE_ITEMS' = `ACTIVATE_LINE_ITEMS`
export const FETCH_CERTIFICATE_AUTHORITY: 'FETCH_CERTIFICATE_AUTHORITY' = `FETCH_CERTIFICATE_AUTHORITY`
export const FETCH_ALLOW_EXTRA_VERSIONS_STATUS: 'FETCH_ALLOW_EXTRA_VERSIONS_STATUS' = `FETCH_ALLOW_EXTRA_VERSIONS_STATUS`
export const UPDATE_ALLOW_EXTRA_VERSIONS_STATUS: 'UPDATE_ALLOW_EXTRA_VERSIONS_STATUS' = `UPDATE_ALLOW_EXTRA_VERSIONS_STATUS`
export const SAVE_DISCOVERY_QUESTIONS: 'SAVE_DISCOVERY_QUESTIONS' = `SAVE_DISCOVERY_QUESTIONS`
export const SEND_CLOUD_ANALYTICS: 'SEND_CLOUD_ANALYTICS' = `SEND_CLOUD_ANALYTICS`
