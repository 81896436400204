/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { Component } from 'react'

import type { AsyncRequestState } from '../../types'
import type { AuthenticationInfo } from '../../lib/api/v1/types'

type Props = {
  authenticationInfo: AuthenticationInfo | null
  fetchAuthenticationInfoRequest: AsyncRequestState
  authTokenExpiration: Date | null
  fetchAuthenticationInfo: () => Promise<any>
  refreshToken: () => Promise<any>
}

const TIMER_INTERVAL = 1000 * 15 // check every 15 seconds

const TWO_MINUTES = 1000 * 60 * 2

// anywhere from 0 to 3 minutes so that different browser tabs don't fight for tokens
const THREE_MINUTES_ENTROPY = Math.random() * 1000 * 60 * 3

// refresh ~2-5 minutes before the token expires
const REFRESH_EAGERNESS = TWO_MINUTES + THREE_MINUTES_ENTROPY

class RefreshApiToken extends Component<Props> {
  timer: number | null = null

  unmounted: boolean = false

  componentDidMount() {
    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.unmounted = true
  }

  render() {
    return null
  }

  start() {
    this.timer = window.setInterval(this.refreshTokenTimer, TIMER_INTERVAL)
  }

  refreshTokenTimer = () => {
    if (this.unmounted) {
      return
    }

    const {
      authenticationInfo,
      fetchAuthenticationInfoRequest,
      authTokenExpiration,
      fetchAuthenticationInfo,
      refreshToken,
    } = this.props

    if (!authTokenExpiration) {
      return
    }

    const refreshTime = authTokenExpiration.valueOf() - REFRESH_EAGERNESS
    const shouldRefresh = Date.now() > refreshTime

    if (!shouldRefresh) {
      return
    }

    /* [1] The `authenticationInfo` is required in the `refreshToken` action, given
     * it relies on the `refresh_token_url` property.
     *
     * - If that's the case, then we already have the data needed for [1]
     *
     * - If that's not the case, we invoke `fetchAuthenticationInfo` here [2],
     *   thus getting the data for [1]. We only do this if we haven't already tried and failed,
     *   and if we aren't currently fetching that same data, to avoid race conditions.
     *   Once we fired off the fetch action, we just let the timer tick again [3].
     *   On the next tick, we'll have the data we need [1] and refresh the token [4].
     */
    if (!authenticationInfo) {
      // [1]
      if (!fetchAuthenticationInfoRequest.error && !fetchAuthenticationInfoRequest.inProgress) {
        fetchAuthenticationInfo() // [2]
      }

      return // [3]
    }

    refreshToken() // [4]
  }

  stop() {
    if (this.timer) {
      window.clearInterval(this.timer)
    }

    this.timer = null
  }
}

export default RefreshApiToken
