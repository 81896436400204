/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { shouldCreateOrganization } from '@/lib/organizations'
import { createOrganization } from '@/actions/organizations'
import {
  FETCH_SAAS_USER,
  CREATE_SAAS_USER,
  UPDATE_SAAS_USER_PROFILE,
  CREATE_PENDING_SAAS_USER,
  SUBSCRIBE_MARKETPLACE_USER,
  CREATE_MARKETPLACE_USER,
  ACTIVATE_MARKETPLACE_USER,
  SCRUB_SAAS_USER,
  SAVE_DISCOVERY_QUESTIONS,
} from '@/constants/actions'
import {
  createSaasUserUrl,
  postActivateMarketplaceUrl,
  postSubscribeMarketplaceUrl,
} from '@/lib/api/v1/urls'

import asyncRequest, { resetAsyncRequest } from '../asyncRequests'

import type { ScrubSaasUserUrlParams } from '@/lib/api/v1/urls'
import type { RESET_ASYNC_REQUEST } from '@/constants/actions'
import type {
  CreateSaasUserRequest,
  SaasUserResponse,
  SaasUpdateProfileRequestData,
  MarketplaceActivateRequest,
  MarketplaceActivationResponse,
  SaasUserUpdateDiscoveryQuestions,
} from '@/lib/api/v1/types'
import type { Action, ThunkAction, ThunkDispatch, UserSubscription, UserProfile } from '../../types'

export function fetchSaasUser(userId: string) {
  const url = `api/v1/saas/users/${userId}`

  return asyncRequest<SaasUserResponse>({
    type: FETCH_SAAS_USER,
    url,
    meta: { userId },
    crumbs: [userId],
    handleUnauthorized: true,
  })
}

export function updateSaasUserProfile(userId: number, updates: SaasUpdateProfileRequestData) {
  const url = `api/v1/saas/users/${userId}/profile`

  return asyncRequest<SaasUserResponse>({
    type: UPDATE_SAAS_USER_PROFILE,
    method: `PUT`,
    url,
    meta: { userId },
    crumbs: [String(userId)],
    payload: updates,
    handleUnauthorized: true,
  })
}

export function createSaasUser(payload: CreateSaasUserRequest) {
  const url = `api/v1/users?activate=true`

  return asyncRequest({
    type: CREATE_SAAS_USER,
    method: 'POST',
    url,
    payload,
  })
}

export function saveDiscoveryQuestions(
  payload: SaasUserUpdateDiscoveryQuestions,
): ThunkAction<Promise<void>> {
  const url = `api/v1/saas/user/discovery_questions`

  return asyncRequest({
    type: SAVE_DISCOVERY_QUESTIONS,
    method: 'PUT',
    url,
    payload,
  })
}

export function createPendingSaasUser(payload: CreateSaasUserRequest) {
  const url = `api/v1/users?activate=false`

  return asyncRequest({
    type: CREATE_PENDING_SAAS_USER,
    method: 'POST',
    url,
    payload,
  })
}

export function scrubSaasUser({ userId }: ScrubSaasUserUrlParams): ThunkAction {
  const url = `api/v1/saas/users/${userId}/_scrub`

  return (dispatch) =>
    dispatch(
      asyncRequest({
        type: SCRUB_SAAS_USER,
        method: 'POST',
        url,
        crumbs: [userId],
      }),
    ).then(({ payload }) => {
      dispatch({
        type: FETCH_SAAS_USER,
        payload,
        meta: { userId },
        crumbs: [userId],
      })
    })
}

export function subscribeMarketplaceUser(payload: {
  partner: UserSubscription
  token: string
  profile: UserProfile
}): ThunkAction {
  const { profile } = payload
  const url = postSubscribeMarketplaceUrl()

  return (dispatch: ThunkDispatch) => {
    if (shouldCreateOrganization({ profile })) {
      return dispatch(createOrganization({})).then(() => dispatch(subscribeMarketplaceUserCall()))
    }

    return dispatch(subscribeMarketplaceUserCall())
  }

  function subscribeMarketplaceUserCall() {
    return asyncRequest({
      type: SUBSCRIBE_MARKETPLACE_USER,
      method: 'POST',
      url,
      payload,
    })
  }
}

export function activateMarketplaceUser(
  payload: MarketplaceActivateRequest,
  requestSettings?: any,
): ThunkAction<Promise<MarketplaceActivationResponse>> {
  const url = postActivateMarketplaceUrl()

  return (dispatch: ThunkDispatch) =>
    dispatch(
      asyncRequest({
        type: ACTIVATE_MARKETPLACE_USER,
        method: 'POST',
        url,
        payload,
        requestSettings,
      }),
    )
}

export function createMarketplaceUser(payload: CreateSaasUserRequest): ThunkAction {
  return (dispatch: ThunkDispatch) => {
    const url = createSaasUserUrl({ activate: true })

    return dispatch(
      asyncRequest({
        type: CREATE_MARKETPLACE_USER,
        method: 'POST',
        url,
        payload,
      }),
    )
  }
}

export const resetSaasUserProfileRequest = (...crumbs): Action<typeof RESET_ASYNC_REQUEST> =>
  resetAsyncRequest(UPDATE_SAAS_USER_PROFILE, crumbs)

export const resetScrubSaasUserRequest = (...crumbs): Action<typeof RESET_ASYNC_REQUEST> =>
  resetAsyncRequest(SCRUB_SAAS_USER, crumbs)
