/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import { callStoredProcedureRequest } from '@/reducers'
import {
  callStoredProcedure,
  clearProcedure,
  resetCallStoredProcedureRequest,
} from '@/actions/storedProcedures'

import EnableDiskNotificationsButton from './EnableDiskNotificationsButton'

import type { AsyncRequestState, ReduxState } from '@/types'

type StateProps = {
  setDiskNotificationsRequest: AsyncRequestState
}

type DispatchProps = {
  setDiskNotifications: () => void
  resetSetDiskNotifications: () => void
}

type ConsumerProps = {
  organizationId: string
  enabled: boolean
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  setDiskNotificationsRequest: callStoredProcedureRequest(
    state,
    `enable_organization_disk_notifications`,
  ),
})

const mapDispatchToProps = (
  dispatch,
  { organizationId, enabled }: ConsumerProps,
): DispatchProps => ({
  setDiskNotifications: () =>
    dispatch(
      callStoredProcedure({
        procedureName: `enable_organization_disk_notifications`,
        parameters: [organizationId, !enabled],
        organizationId,
      }),
    ),
  resetSetDiskNotifications: () => {
    dispatch(resetCallStoredProcedureRequest(`enable_organization_disk_notifications`))
    return dispatch(clearProcedure(`enable_organization_disk_notifications`))
  },
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EnableDiskNotificationsButton)
