/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

import {
  EuiCheckbox,
  EuiSpacer,
  EuiPopover,
  EuiButtonIcon,
  EuiText,
  EuiPopoverTitle,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'

import type { ReactNode, FunctionComponent } from 'react'
import type { ElasticsearchClusterPlan } from '../../../../lib/api/v1/types'

type Props = {
  id: string
  label: ReactNode
  description: ReactNode
  help: ReactNode
  plan: ElasticsearchClusterPlan
  onChange: (path: string[], value: any) => void
  path: string[]
}

const PlanOverride: FunctionComponent<Props> = ({
  id,
  label,
  description,
  help,
  plan,
  onChange,
  path,
  children,
}) => {
  const checked = get(plan, path, false)

  return (
    <div>
      <EuiSpacer size='m' />

      <EuiCheckbox
        id={id}
        label={
          <Fragment>
            <PlanOverrideDescription label={label} description={description} />

            <EuiSpacer size='xs' />

            <EuiText color='subdued' size='s'>
              {help}
            </EuiText>

            {children}
          </Fragment>
        }
        checked={checked}
        onChange={() => onChange(path, !checked)}
      />
    </div>
  )
}

const PlanOverrideDescription: FunctionComponent<{
  label: ReactNode
  description: ReactNode
}> = ({ label, description }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <EuiFlexGroup gutterSize='xs' alignItems='center'>
      <EuiFlexItem grow={false}>{label}</EuiFlexItem>

      <EuiFlexItem>
        <EuiPopover
          anchorPosition='upCenter'
          button={
            <EuiButtonIcon
              iconType='iInCircle'
              color='primary'
              aria-label='Info'
              onClick={() => setOpen(!isOpen)}
            />
          }
          isOpen={isOpen}
          closePopover={() => setOpen(false)}
          className='fs-unmask'
        >
          <EuiPopoverTitle>
            <FormattedMessage
              id='plan-override-description.title'
              defaultMessage='When should I use this?'
            />
          </EuiPopoverTitle>

          <div style={{ maxWidth: '300px' }}>
            <EuiText size='s'>{description}</EuiText>
          </div>
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default PlanOverride
