/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import EcuHelpTooltip from '@/components/EcuHelp/EcuHelpTooltip'

import type { FunctionComponent, ReactElement } from 'react'

interface Props {
  title?: ReactElement
  showEcuTooltip?: boolean
  ['data-test-id']?: string
}

const BillingHistoryPanel: FunctionComponent<Props> = ({
  title,
  showEcuTooltip,
  children,
  'data-test-id': dataTestId,
}) => (
  <EuiPanel data-test-id={dataTestId}>
    <Fragment>
      <EuiTitle size='xxxs'>
        <EuiFlexGroup gutterSize='none' alignItems='center'>
          <EuiFlexItem grow={false}>
            {title ? (
              title
            ) : (
              <h4>
                <FormattedMessage
                  id='billing-statements.billing-history.heading'
                  defaultMessage='Billing history'
                />
              </h4>
            )}
          </EuiFlexItem>

          {showEcuTooltip && <EcuHelpTooltip />}
        </EuiFlexGroup>
      </EuiTitle>

      <EuiSpacer size='m' />
    </Fragment>

    {children}
  </EuiPanel>
)

export default BillingHistoryPanel
