/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  needMoreTime: {
    id: 'expired-trial-modal.need-more-time',
    defaultMessage: 'Need more time?',
  },
  expiredTrialModalTitle: {
    id: 'expired-trial-modal.title',
    defaultMessage: 'Your trial is over',
  },
  hourglassAlt: {
    id: 'expired-trial-modal.hourglass.illustration',
    defaultMessage: `An illustration of an hourglass.`,
  },
  contactUs: {
    id: `govcloud-expired-trial.contact-us`,
    defaultMessage: `Contact us`,
  },
})

export default messages
