/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { ReactElement } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'

import { ClusterPlanWarning } from '../../api/v1/types'

const configurationChangeWarningMessages = defineMessages({
  IdentifiedIgnoredChangesInMoveOnlyPlan: {
    id: 'configuration-change-warnings.descriptions.identified-ignored-changes-in-move-only-plan',
    defaultMessage:
      'There was a problem with this configuration change, the cluster is in an inconsistent state due to prior failures. Try applying the plan again.',
  },
})

export function getFormattedWarningMessage(warning: ClusterPlanWarning): ReactElement {
  const description = configurationChangeWarningMessages[warning.code]

  if (!description) {
    return <p data-test-id='configuration-change-warning-description'>{warning.message}</p>
  }

  return (
    <FormattedMessage data-test-id='configuration-change-warning-description' {...description} />
  )
}
