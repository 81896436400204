/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'

import {
  EuiIcon,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFormHelpText,
  EuiText,
} from '@elastic/eui'

import { CuiAlert, CuiTable } from '../../../../cui'
import StoredProcedureResult from '../../../User/StoredProcedureResult'
import CustomSettingButton from '../../../User/CustomSettingButton'

import type { CuiTableColumn } from '../../../../cui'
import type { IntlShape } from 'react-intl'
import type { ReactNode } from 'react'
import type { AsyncRequestState } from '../../../../types'
import type { SaasOrganizationResponse } from '../../../../lib/api/v1/types'

type Props = {
  intl: IntlShape
  saasOrganization: SaasOrganizationResponse
  setCustomSettingRequest: AsyncRequestState
}

type FeatureRow = {
  id: string
  enabled: boolean
  name: ReactNode
  description?: ReactNode
  actions: ReactNode
}

const messages = defineMessages({
  enabled: {
    id: `organization.organization-overview.organization-features.enabled`,
    defaultMessage: `Enabled`,
  },
  enableFeature: {
    id: `organization.organization-overview.organization-features.enable-feature`,
    defaultMessage: `Enable feature {featureName}`,
  },
  disableFeature: {
    id: `organization.organization-overview.organization-features.disable-feature`,
    defaultMessage: `Disable feature {featureName}`,
  },
  enablePluginExtensions: {
    id: `organization.organization-overview.organization-features.enable-plugin-extensions-action`,
    defaultMessage: `Enable plugin extensions`,
  },
  disablePluginExtensions: {
    id: `organization.organization-overview.organization-features.disable-plugin-extensions-action`,
    defaultMessage: `Disable plugin extensions`,
  },
  enableBundleExtensions: {
    id: `organization.organization-overview.organization-features.enable-bundle-extensions-action`,
    defaultMessage: `Enable bundle extensions`,
  },
  disableBundleExtensions: {
    id: `organization.organization-overview.organization-features.disable-bundle-extensions-action`,
    defaultMessage: `Disable bundle extensions`,
  },
})

class OrganizationFeatures extends Component<Props> {
  render() {
    const { setCustomSettingRequest } = this.props

    const columns: Array<CuiTableColumn<FeatureRow>> = [
      {
        label: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.feature-label'
            defaultMessage='Feature'
          />
        ),
        render: (feature) => (
          <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiIcon type='dot' color={feature.enabled ? `success` : `subdued`} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <div>
                <EuiText size='s'>
                  <strong>{feature.name}</strong>
                </EuiText>

                {feature.description && <EuiFormHelpText>{feature.description}</EuiFormHelpText>}
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        ),
        sortKey: `id`,
      },

      {
        label: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.actions-label'
            defaultMessage='Enabled'
          />
        ),
        render: (feature) => feature.actions,
        actions: true,
        width: `70px`,
      },
    ]

    const rows = this.getFeatureRows()

    return (
      <Fragment>
        <CuiTable<FeatureRow>
          rows={rows}
          columns={columns}
          getRowId={(feature) => feature.id}
          pageSize={10}
        />

        {setCustomSettingRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{setCustomSettingRequest.error}</CuiAlert>
          </Fragment>
        )}

        <StoredProcedureResult procedureId='toggle_bundles_and_custom_plugins_organization' />
      </Fragment>
    )
  }

  getFeatureRows(): FeatureRow[] {
    const {
      intl: { formatMessage },
      saasOrganization: {
        organization: { organization_id: organizationId },
        rules,
      },
    } = this.props

    const { allow_plugins: allowPlugins, allow_bundles: allowBundles } = rules

    return [
      {
        id: `__extension-plugins`,
        enabled: allowPlugins,
        name: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.plugin-extensions-label'
            defaultMessage='Plugin extensions'
          />
        ),
        actions: (
          <CustomSettingButton
            checked={allowPlugins}
            organizationId={organizationId}
            allowPlugins={!allowPlugins}
            allowBundles={allowBundles}
            action={
              allowPlugins
                ? formatMessage(messages.disablePluginExtensions)
                : formatMessage(messages.enablePluginExtensions)
            }
          />
        ),
      },

      {
        id: `__extension-bundles`,
        enabled: allowBundles,
        name: (
          <FormattedMessage
            id='organization.organization-overview.organization-features.bundle-extensions-label'
            defaultMessage='Bundle extensions'
          />
        ),
        actions: (
          <CustomSettingButton
            checked={allowBundles}
            organizationId={organizationId}
            allowPlugins={allowPlugins}
            allowBundles={!allowBundles}
            action={
              allowBundles
                ? formatMessage(messages.disableBundleExtensions)
                : formatMessage(messages.enableBundleExtensions)
            }
          />
        ),
      },
    ]
  }
}

export default injectIntl(OrganizationFeatures)
