/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiFormHelpText,
  EuiSpacer,
  EuiFieldText,
  EuiFormRow,
} from '@elastic/eui'

import { createNewShieldUser } from '@/lib/stackDeployments/credentials'
import { replaceIn } from '@/lib/immutability-helpers'
import { createTempShieldUserSuccessAction } from '@/actions/stackDeployments/metadata'

import { CuiAlert, CuiPermissibleControl } from '../../../cui'
import SpinButton from '../../SpinButton'
import Permission from '../../../lib/api/v1/permissions'

import TempUserCreationSuccess from './TempUserCreationSuccess'

import type {
  AsyncRequestState,
  StackDeployment,
  Action,
  UserState,
  FoundUser,
} from '../../../types'

export type Props = {
  newTempShieldUser: FoundUser | null
  user: UserState
  deployment: StackDeployment
  updateMetadata: (params: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta?: { [key: string]: any }
    then?: () => Action
  }) => void
  setDeploymentResourceMetadataRequest: AsyncRequestState
}

type State = {
  _user: boolean
  username: string
}

const maxUsernameLength = 15
const createUserMetadataAction = `create-user`

export default class AddTempUser extends Component<Props, State> {
  state = {
    _user: Boolean(this.props.user),
    username: this.props.user ? this.props.user.username.substring(0, maxUsernameLength) : ``,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    if (!prevState._user && nextProps.user) {
      return {
        _user: true,
        username: nextProps.user.username.substring(0, maxUsernameLength),
      }
    }

    return null
  }

  render() {
    const { setDeploymentResourceMetadataRequest, newTempShieldUser } = this.props
    const { username } = this.state
    const updateRequestIsRelevant =
      get(setDeploymentResourceMetadataRequest, [`meta`, `action`]) === createUserMetadataAction

    return (
      <div>
        <EuiSpacer size='m' />

        <EuiFormLabel>
          <FormattedMessage
            id='cluster-manage-add-temp-user.add-a-new-temporary-shield-user-by-adding-a-username'
            defaultMessage='Create temporary shield users'
          />
        </EuiFormLabel>

        <EuiSpacer size='xs' />

        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiFieldText
                data-test-subj='add-temp-user-username'
                value={username}
                onChange={(e) => this.updateUsername(e.target.value)}
                maxLength={maxUsernameLength}
                className='tempShieldUsers-input'
              />
            </EuiFormRow>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <div>
              <CuiPermissibleControl permissions={Permission.setDeploymentResourceRawMetadata}>
                <SpinButton
                  data-test-id='add-temp-user-submit'
                  color='primary'
                  className='tempShieldUsers-btn'
                  onClick={() => this.createUser()}
                  disabled={
                    username === `` ||
                    (!updateRequestIsRelevant && setDeploymentResourceMetadataRequest.inProgress)
                  }
                  spin={updateRequestIsRelevant && setDeploymentResourceMetadataRequest.inProgress}
                >
                  <FormattedMessage
                    id='cluster-manage-add-temp-user.add-new-user'
                    defaultMessage='Create'
                  />
                </SpinButton>
              </CuiPermissibleControl>

              <EuiFormHelpText>
                <FormattedMessage
                  id='cluster-manage-add-temp-user.new-username-explained'
                  defaultMessage='Creates a new user named { username }'
                  values={{ username: `cloud-internal-${username}` }}
                />
              </EuiFormHelpText>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>

        {updateRequestIsRelevant && setDeploymentResourceMetadataRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{setDeploymentResourceMetadataRequest.error}</CuiAlert>
          </Fragment>
        )}

        {!setDeploymentResourceMetadataRequest.inProgress &&
          !setDeploymentResourceMetadataRequest.error &&
          newTempShieldUser !== null && (
            <Fragment>
              <EuiSpacer size='m' />

              <TempUserCreationSuccess newTempShieldUser={newTempShieldUser} />
            </Fragment>
          )}
      </div>
    )
  }

  createUser(): void {
    const { username } = this.state
    const { deployment, updateMetadata } = this.props

    const newUser = createNewShieldUser({ username })
    const then = () => createTempShieldUserSuccessAction(newUser)

    updateMetadata({
      deployment,
      then,
      requestMeta: { action: createUserMetadataAction, username: newUser.username },
      updater: (metadata) =>
        replaceIn(metadata, [`shield`, `found_users`, newUser.username], newUser),
    })
  }

  updateUsername(username: string): void {
    this.setState({
      username,
    })
  }
}
