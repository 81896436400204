/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiButtonEmpty, EuiSpacer } from '@elastic/eui'

import { CuiAlert } from '../../../../cui'
import StoredProcedureResult from '../../../User/StoredProcedureResult'
import DangerButton from '../../../DangerButton'

import type { WrappedComponentProps } from 'react-intl'
import type { AsyncRequestState } from '../../../../types'

interface Props extends WrappedComponentProps {
  organizationId: string
  convertToInvoicing: () => void
  convertToInvoicingRequest: AsyncRequestState
  resetConvertToInvoicing: () => void
}

const messages = defineMessages({
  convertToInvoicing: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.button-text`,
    defaultMessage: `Convert account to invoicing`,
  },
  convertToInvoicingModalTitle: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-title`,
    defaultMessage: `Convert account to invoicing?`,
  },
  convertToInvoicingModalBody: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-message`,
    defaultMessage: `You're about to change account to use invoices. This action cannot be undone from the UI.`,
  },
  convertToInvoicingModalConfirm: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.modal-confirm`,
    defaultMessage: `Yes, change account type`,
  },
  convertToInvoicingModalRequested: {
    id: `organization.organization-overview.convert-user-to-invoicing-button.action-requested`,
    defaultMessage: `Account converted to invoicing.`,
  },
})

class ConvertOrganizationToInvoicingButton extends Component<Props> {
  componentWillUnmount() {
    this.props.resetConvertToInvoicing()
  }

  render() {
    const {
      intl: { formatMessage },
      convertToInvoicing,
      convertToInvoicingRequest,
    } = this.props

    const actionText = formatMessage(messages.convertToInvoicing)

    return (
      <Fragment>
        <DangerButton
          buttonType={EuiButtonEmpty}
          size='s'
          onConfirm={convertToInvoicing}
          spin={convertToInvoicingRequest.inProgress}
          modal={{
            title: formatMessage(messages.convertToInvoicingModalTitle),
            body: <FormattedMessage {...messages.convertToInvoicingModalBody} />,
            confirmButtonText: formatMessage(messages.convertToInvoicingModalConfirm),
          }}
        >
          {actionText}
        </DangerButton>

        {convertToInvoicingRequest.error && (
          <Fragment>
            <EuiSpacer size='m' />

            <CuiAlert type='error'>{convertToInvoicingRequest.error}</CuiAlert>
          </Fragment>
        )}

        <StoredProcedureResult
          procedureId='convert_organization_to_invoicing'
          successColor='warning'
          successMessage={formatMessage(messages.convertToInvoicingModalRequested)}
        />
      </Fragment>
    )
  }
}

export default injectIntl(ConvertOrganizationToInvoicingButton)
