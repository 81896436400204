/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, get } from 'lodash'

import { castSize } from '@/lib/deployments/conversion'

import type { InstanceSummary } from '@/types'
import type {
  ClusterInstanceConfigurationInfo,
  ClusterInstanceInfo,
  DeploymentTemplateInfoV2,
} from '../api/v1/types'

export function getDefaultDiskQuota({ instance }: { instance: ClusterInstanceInfo }): number {
  return get(instance, [`disk`, `storage_multiplier`], 0)
}

export function getCurrentDiskQuota({ instance }: { instance: ClusterInstanceInfo }): number {
  const storage = get(instance, [`disk`, `disk_space_available`], 0)
  const memory = get(instance, [`memory`, `instance_capacity`], 0)
  return storage / memory
}

export function hasInstanceSizeOverride({
  instance,
  instanceConfigMeta,
  size,
}: {
  instance: ClusterInstanceInfo
  instanceConfigMeta: ClusterInstanceConfigurationInfo
  size: number
}) {
  const { resource } = instanceConfigMeta
  const storage = get(instance, [`disk`, `disk_space_available`], 0)
  const memory = get(instance, [`memory`, `instance_capacity`], 0)
  const resourceCapacity = resource === `storage` ? storage : memory

  if (size !== resourceCapacity) {
    return true
  }

  const defaultCapacity = getDefaultCapacity({
    instance,
    instanceConfigMeta,
  })

  return Boolean(defaultCapacity && defaultCapacity !== size)
}

export function getDefaultCapacity({
  instance,
  instanceConfigMeta,
}: {
  instance: ClusterInstanceInfo
  instanceConfigMeta: ClusterInstanceConfigurationInfo
}) {
  const { resource } = instanceConfigMeta
  const memoryPlanned = get(instance, [`memory`, `instance_capacity_planned`])

  const defaultCapacity = castSize({
    from: `memory`,
    to: resource!,
    storageMultiplier: getDefaultDiskQuota({ instance }),
    size: memoryPlanned,
  })

  return defaultCapacity
}

function hasDiskQuotaOverride({ instance }: { instance: ClusterInstanceInfo }) {
  // The operator was updated from '!==' to '<' as a temporary fix for https://github.com/elastic/cloud/issues/104508
  // Once a proper solution is implemented, the operator should be changed back
  return getDefaultDiskQuota({ instance }) < getCurrentDiskQuota({ instance })
}

export function getSizeOptions({
  instance,
  instanceConfigMeta,
  deploymentTemplate,
}: {
  instance: ClusterInstanceInfo
  instanceConfigMeta: ClusterInstanceConfigurationInfo
  deploymentTemplate: DeploymentTemplateInfoV2
}): { sizes: number[]; resource: 'memory' | 'storage' } {
  const { resource, sizes } = getDiscreteConfiguration()

  const storage: number = get(instance, [`disk`, `disk_space_available`], 0)
  const memory: number = get(instance, [`memory`, `instance_capacity`], 0)

  const resourceCapacity = resource === `storage` ? storage : memory

  if (isFinite(resourceCapacity) && !sizes.includes(resourceCapacity)) {
    sizes.push(resourceCapacity)
  }

  sizes.sort((a, b) => a - b)

  const lastSize = sizes[sizes.length - 1]

  sizes.push(lastSize * 2)
  sizes.push(lastSize * 4)

  return { resource, sizes: [...sizes] }

  function getDiscreteConfiguration() {
    const { id } = instanceConfigMeta
    const instanceConfiguration = find(deploymentTemplate.instance_configurations, { id })

    if (!instanceConfiguration) {
      // sanity
      return { resource: instanceConfigMeta.resource, sizes: [] }
    }

    const {
      discrete_sizes: { resource, sizes },
    } = instanceConfiguration

    return { resource, sizes: [...sizes] }
  }
}

export function getCurrentInstanceCapacity({
  instance,
  instanceConfigMeta,
  deploymentTemplate,
}: {
  instance: ClusterInstanceInfo
  instanceConfigMeta: ClusterInstanceConfigurationInfo
  deploymentTemplate: DeploymentTemplateInfoV2
}) {
  const { resource } = instanceConfigMeta
  const storage = get(instance, [`disk`, `disk_space_available`], 0)
  const memory = get(instance, [`memory`, `instance_capacity`], 0)

  const size = resource === `storage` ? storage : memory

  if (!isFinite(size)) {
    const sizes = getSizeOptions({ instance, instanceConfigMeta, deploymentTemplate }).sizes
    const [firstSize] = sizes
    return firstSize
  }

  return size
}

export function getInstancesWithOverride({
  instanceSummaries,
  deploymentTemplate,
}: {
  instanceSummaries: InstanceSummary[]
  deploymentTemplate: DeploymentTemplateInfoV2
}): InstanceSummary[] {
  return instanceSummaries.filter(
    (instanceSummary) =>
      hasInstanceSizeOverride({
        instance: instanceSummary.instance,
        instanceConfigMeta: instanceSummary.instance.instance_configuration!,
        size: getCurrentInstanceCapacity({
          instance: instanceSummary.instance,
          instanceConfigMeta: instanceSummary.instance.instance_configuration!,
          deploymentTemplate,
        }),
      }) || hasDiskQuotaOverride(instanceSummary),
  )
}
