/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import { isEmpty } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
} from '@elastic/eui'

import { CuiThemedIcon } from '@/cui'
import marketplaceIcon from '@/files/marketplace-conversion.svg'

import PrivacySensitiveContainer from '../../../../../../components/PrivacySensitiveContainer'
import MarketplaceActivationForm from '../MarketplaceActivationForm'
import SpinButton from '../../../../../../components/SpinButton'

import TrialStartedTitle from './TrialStartedTitle'
import TrialNotStartedTitle from './TrialNotStartedTitle'

import type { AsyncRequestState } from '../../../../../../types'
import type { FieldAttributeValue } from '../types'
import type { IntlShape } from 'react-intl'
import type { ReactElement } from 'react'

interface Props {
  intl: IntlShape
  activateMarketplaceUserRequest: AsyncRequestState
  isTrialStartedUser: boolean
  onSubmit: ({ values }: { values: FieldAttributeValue }) => void
  onClose: () => void
}

interface State {
  formFieldValues: FieldAttributeValue
  formFieldErrors?: FieldAttributeValue
}

const messages = defineMessages({
  fieldRequired: {
    id: `marketplace-conversion-modal.field-required`,
    defaultMessage: `Required`,
  },
})

class MarketplaceActivationModal extends PureComponent<Props, State> {
  state: State = {
    formFieldValues: {
      company: '',
      fullName: '',
    },
    formFieldErrors: undefined,
  }

  render(): ReactElement {
    const { activateMarketplaceUserRequest, onClose } = this.props
    const { formFieldErrors, formFieldValues } = this.state

    return (
      <EuiModal onClose={onClose} className='marketplace-conversion-modal'>
        <EuiModalHeader>
          <EuiFlexGroup>
            <EuiFlexItem>
              <CuiThemedIcon
                size='original'
                lightType={marketplaceIcon}
                darkType={marketplaceIcon}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiModalHeaderTitle>{this.renderModalHeaderTitle()}</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGroup
            gutterSize='none'
            justifyContent='spaceBetween'
            alignItems='center'
            responsive={false}
          >
            <EuiFlexItem>
              <PrivacySensitiveContainer>
                <MarketplaceActivationForm
                  onUpdate={this.onFormFieldUpdate}
                  values={formFieldValues}
                  errors={formFieldErrors || {}}
                />
              </PrivacySensitiveContainer>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiSpacer size='m' />
          <SpinButton
            fill={true}
            onClick={this.onSubmit}
            spin={activateMarketplaceUserRequest.inProgress}
          >
            <FormattedMessage
              id='marketplace-conversion.submit-button'
              defaultMessage='Save and continue'
            />
          </SpinButton>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  renderModalHeaderTitle(): ReactElement {
    const { isTrialStartedUser } = this.props

    if (isTrialStartedUser) {
      return <TrialStartedTitle />
    }

    return <TrialNotStartedTitle />
  }

  onFormFieldUpdate = ({ name, value }: { name: string; value: string }): void => {
    const { formFieldErrors, formFieldValues } = this.state
    const errors = { ...formFieldErrors }

    if (formFieldErrors && formFieldErrors[name] && value.length) {
      delete errors[name]
    }

    this.setState({
      formFieldValues: {
        ...formFieldValues,
        [name]: value,
      },
      formFieldErrors: errors,
    })
  }

  validateForm(): boolean {
    const {
      intl: { formatMessage },
    } = this.props
    const { formFieldValues } = this.state
    const formFieldErrors: FieldAttributeValue = {}
    const fields = Object.keys(formFieldValues)

    fields.forEach((field) => {
      const fieldValue = formFieldValues[field]

      if (fieldValue.trim().length === 0) {
        formFieldErrors[field] = formatMessage(messages.fieldRequired)
      }
    })

    const isValid = isEmpty(formFieldErrors)

    if (!isValid) {
      this.setState({ formFieldErrors })
    }

    return isValid
  }

  onSubmit = (): void => {
    const isValid = this.validateForm()

    if (isValid) {
      const { onSubmit } = this.props
      const { formFieldValues: values } = this.state
      onSubmit({ values })
    }
  }
}

export default injectIntl(MarketplaceActivationModal)
