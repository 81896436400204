/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { get } from 'lodash'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { CuiAlert, CuiCodeBlock } from '../../../../../cui'
import { isEnabledConfiguration } from '../../../../../lib/deployments/conversion'
import {
  getProductSliderTypesForStackDeployment,
  getSliderPrettyName,
} from '../../../../../lib/sliders'
import { isWellKnownSliderInstanceType } from '../../../../../lib/sliders/sliders'
import {
  getClusterPlanInfo,
  getFirstSliderCluster,
  getSliderPlan,
} from '../../../../../lib/stackDeployments/selectors'
import {
  getUserSettingsFromConfiguration,
  getPlanConfiguration,
  getPlanLevelSettings,
  hasPlanLevelSettings,
  hasTopologyLevelSettings,
} from '../../../../../lib/stackDeployments/selectors/configuration'
import {
  hasAnyUserSettingsInDeployment,
  hasSecurityRealmSettingsInDeployment,
  updateContainsInvalidUserSettingsYaml,
} from '../../../../../lib/stackDeployments/userSettings'
import lightTheme from '../../../../../lib/theme/light'
import StackDeploymentUpdateDryRunWarnings from '../../../../StackDeploymentUpdateDryRunWarnings'
import StackDeploymentUpdateDryRunWarningCheck from '../../../../StackDeploymentUpdateDryRunWarningCheck'
import ReleaseNotesLink from '../../../../../components/DocLink/ReleaseNotesLink'

import ReviewYamlSettingsEditor from './ReviewYamlSettingsEditor'
import ConfirmUpgrade from './ConfirmUpgrade'

import type {
  AnyTopologyElement,
  AsyncRequestState,
  WellKnownSliderInstanceType,
} from '../../../../../types'
import type {
  DeploymentGetResponse,
  DeploymentUpdateRequest,
} from '../../../../../lib/api/v1/types'
import type { ReactNode } from 'react'

export type Props = {
  deployment: DeploymentUpdateRequest
  deploymentUnderEdit: DeploymentGetResponse
  deploymentUpdateRequest: AsyncRequestState
  version: string
  updatePlan: (
    sliderInstanceType: WellKnownSliderInstanceType,
    path: string | string[],
    value: any,
  ) => void
  onClose: () => void
  onSave: () => void
}

type State = {
  showConfirmationModal: boolean
}

const { euiBreakpoints } = lightTheme

export default class ReviewYamlSettings extends Component<Props, State> {
  state = { showConfirmationModal: false }

  render(): ReactNode | null {
    const { deployment, deploymentUnderEdit, deploymentUpdateRequest, onClose, version } =
      this.props
    const { showConfirmationModal } = this.state

    // the deployment didn't have any user settings at all — no review necessary
    if (!hasAnyUserSettingsInDeployment({ deployment: deploymentUnderEdit })) {
      return this.renderUpgradeConfirmationModal()
    }

    const userSettingsReviewEditors = this.renderReviewYamlSettings()
    const hasInvalidYaml = updateContainsInvalidUserSettingsYaml({ deployment })

    return (
      <Fragment>
        <EuiOverlayMask>
          <EuiModal
            onClose={onClose}
            style={{ width: euiBreakpoints.l }}
            maxWidth={euiBreakpoints.xl}
            data-test-id='review-yaml-settings-modal'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <FormattedMessage
                  id='review-yaml-settings.deployment-title'
                  defaultMessage='Update Settings for {version}'
                  values={{ version }}
                />
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              {this.renderYamlSettingsDescription()}

              {userSettingsReviewEditors}

              {deploymentUpdateRequest.error && (
                <Fragment>
                  <EuiSpacer size='m' />

                  <CuiAlert type='error'>{deploymentUpdateRequest.error}</CuiAlert>
                </Fragment>
              )}
            </EuiModalBody>

            <EuiModalFooter>
              <EuiFlexGroup>
                <EuiFlexItem grow={true}>
                  {/* No point requesting a dry run when we know it will fail */}
                  {!hasInvalidYaml && (
                    <StackDeploymentUpdateDryRunWarnings
                      deploymentId={deploymentUnderEdit.id}
                      deployment={deployment}
                      ignoreSecurityRealmWarnings={true}
                      // Adds a .5 second delay on dry run feedback while updating user settings
                      debounceTimeout={500}
                    />
                  )}
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup alignItems='flexEnd'>
                    <EuiFlexItem>
                      <EuiButtonEmpty onClick={onClose}>
                        <FormattedMessage
                          id='review-yaml-settings.back-button'
                          defaultMessage='Back'
                        />
                      </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <StackDeploymentUpdateDryRunWarningCheck
                        deploymentId={deploymentUnderEdit.id}
                        ignoreSecurityRealmWarnings={true}
                      >
                        {({ dryRunCheckPassed }) => (
                          <EuiButton
                            fill={true}
                            data-test-id='confirm-yaml-settings'
                            disabled={hasInvalidYaml || !dryRunCheckPassed}
                            onClick={() => this.setState({ showConfirmationModal: true })}
                          >
                            <FormattedMessage
                              id='review-yaml-settings.button'
                              defaultMessage='Update settings and confirm upgrade'
                            />
                          </EuiButton>
                        )}
                      </StackDeploymentUpdateDryRunWarningCheck>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
        {showConfirmationModal && this.renderUpgradeConfirmationModal()}
      </Fragment>
    )
  }

  renderYamlSettingsDescription(): ReactNode {
    const { deploymentUnderEdit } = this.props

    // if there are any 'xpack.security.authc.realms.*' the user should fix these settings specifically
    if (hasSecurityRealmSettingsInDeployment({ deployment: deploymentUnderEdit })) {
      return (
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage
              id='review-yaml-settings.description'
              defaultMessage='The realm settings have changed in version 7.0. The <code>type</code> should now be a part of the configuration key.'
              values={{
                code: (content: string[]) => <code>{content}</code>,
              }}
            />
          </EuiText>

          <EuiSpacer size='m' />

          <EuiText size='s'>
            <EuiFlexGroup data-test-id='realm-settings-example'>
              <EuiFlexItem>
                <div>
                  <FormattedMessage
                    id='review-yaml-settings.settings-were'
                    defaultMessage='For example, if your setting was:'
                  />

                  <EuiSpacer size='s' />

                  <CuiCodeBlock language='yaml'>
                    {`realms:
  my-ldap:
  type: ldap
  …`}
                  </CuiCodeBlock>
                </div>
              </EuiFlexItem>

              <EuiFlexItem>
                <div>
                  <FormattedMessage
                    id='review-yaml-settings.settings-should-be'
                    defaultMessage='You should change it to:'
                  />

                  <EuiSpacer size='s' />

                  <CuiCodeBlock language='yaml'>
                    {`realms:
  ldap:
  my-ldap:
  …
  `}
                  </CuiCodeBlock>
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiText>
          <EuiSpacer size='m' />
        </Fragment>
      )
    }

    return null
  }

  renderReviewYamlSettings(): ReactNode {
    const { deploymentUnderEdit } = this.props

    return getProductSliderTypesForStackDeployment(deploymentUnderEdit).map(
      ({ sliderInstanceType }) => {
        const isValidSliderInstanceType = isWellKnownSliderInstanceType(sliderInstanceType)

        // Enforces downstream literals instead of strings for SliderInstanceType
        if (!isValidSliderInstanceType) {
          return null
        }

        const planInfo = getClusterPlanInfo({
          deployment: deploymentUnderEdit,
          sliderInstanceType,
        })

        if (!planInfo) {
          return null
        }

        if (hasPlanLevelSettings(planInfo)) {
          const configuration = getPlanConfiguration(planInfo)
          const userSettingsYaml = getUserSettingsFromConfiguration(configuration)

          if (userSettingsYaml) {
            return this.renderYamlSettingsEditorOfPlan(sliderInstanceType)
          }
        }

        if (hasTopologyLevelSettings(planInfo)) {
          return this.renderYamlSettingsEditorOfType(sliderInstanceType)
        }

        return null
      },
    )
  }

  renderYamlSettingsHeader(sliderInstanceType: WellKnownSliderInstanceType): ReactNode {
    return (
      <Fragment key={sliderInstanceType}>
        <EuiFlexGroup alignItems='baseline'>
          <EuiFlexItem grow={false}>
            <EuiTitle size='s'>
              <h3>
                <FormattedMessage
                  id='review-yaml-settings.title'
                  defaultMessage='{sliderName} settings'
                  values={{
                    sliderName: (
                      <FormattedMessage {...getSliderPrettyName({ sliderInstanceType })} />
                    ),
                  }}
                />
              </h3>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem>{this.renderReleaseNotesLink(sliderInstanceType)}</EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='s' />
      </Fragment>
    )
  }

  renderYamlSettingsEditorOfType(sliderInstanceType: WellKnownSliderInstanceType): ReactNode[] {
    const { deployment } = this.props

    const plan = getSliderPlan({ deployment, sliderInstanceType })

    const topology: AnyTopologyElement[] = plan?.cluster_topology || []
    const enabledNodes: AnyTopologyElement[] = topology.filter(isEnabledConfiguration)

    const title = this.renderYamlSettingsHeader(sliderInstanceType)
    const settings = enabledNodes.map((node) =>
      this.renderYamlSettingsEditorForNode(sliderInstanceType, node, topology.indexOf(node)),
    )

    return [title, ...settings]
  }

  renderYamlSettingsEditorOfPlan(sliderInstanceType: WellKnownSliderInstanceType): ReactNode[] {
    const { deployment } = this.props

    const resource = getFirstSliderCluster({ deployment, sliderInstanceType })

    if (!resource) {
      return []
    }

    const settingsString = getPlanLevelSettings(resource)

    const title = this.renderYamlSettingsHeader(sliderInstanceType)
    const settings = this.renderYamlSettingsEditor(sliderInstanceType, settingsString)

    return [title, settings]
  }

  renderYamlSettingsEditor(
    sliderInstanceType: WellKnownSliderInstanceType,
    userSettings: string,
  ): ReactNode {
    const { updatePlan } = this.props

    const id = `yaml-${sliderInstanceType}`

    return (
      <ReviewYamlSettingsEditor
        id={id}
        key={id}
        value={userSettings}
        onChange={(value) =>
          updatePlan(sliderInstanceType, [sliderInstanceType, `user_settings_yaml`], value)
        }
      />
    )
  }

  renderYamlSettingsEditorForNode(
    sliderInstanceType: WellKnownSliderInstanceType,
    node: AnyTopologyElement,
    nodeIndex: number,
  ): ReactNode {
    const { updatePlan } = this.props

    const userSettingsPath = [sliderInstanceType, `user_settings_yaml`]
    const settingsString = get(node, userSettingsPath, undefined)
    const id = `yaml-${sliderInstanceType}-${nodeIndex}`

    return (
      <ReviewYamlSettingsEditor
        id={id}
        key={id}
        title={node.instance_configuration_id}
        value={settingsString}
        onChange={(value) =>
          updatePlan(
            sliderInstanceType,
            [`cluster_topology`, String(nodeIndex), ...userSettingsPath],
            value,
          )
        }
      />
    )
  }

  renderReleaseNotesLink(sliderInstanceType: WellKnownSliderInstanceType): ReactNode {
    const { version } = this.props

    return (
      <ReleaseNotesLink sliderInstanceType={sliderInstanceType} version={version}>
        <FormattedMessage
          id='review-yaml-settings.resource-doclink'
          defaultMessage='View release notes'
        />
      </ReleaseNotesLink>
    )
  }

  renderUpgradeConfirmationModal(): ReactNode {
    const { deploymentUpdateRequest, version, onSave, onClose } = this.props

    return (
      <ConfirmUpgrade
        deploymentUpdateRequest={deploymentUpdateRequest}
        version={version}
        onCancel={onClose}
        onConfirm={onSave}
      />
    )
  }
}
