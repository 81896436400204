/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
} from '@elastic/eui'

import { CuiAlert } from '../../../../../cui'

import { AllProps } from './types'

class Status extends Component<AllProps> {
  componentDidMount() {
    this.props.fetchAllowExtraVersionsStatus()
  }

  render() {
    const {
      allowExtraVersionsStatus,
      fetchExtraVersionsStatusRequest,
      updateAllowExtraVersionsStatusRequest,
      regionId,
    } = this.props

    if (fetchExtraVersionsStatusRequest.error || updateAllowExtraVersionsStatusRequest.error) {
      return (
        <Fragment>
          <CuiAlert data-test-id='allow-extra-versions-error' type='error'>
            {fetchExtraVersionsStatusRequest.error}
          </CuiAlert>
          <EuiSpacer />
          {this.renderCheckAgainButton()}
        </Fragment>
      )
    }

    if (
      fetchExtraVersionsStatusRequest.inProgress ||
      updateAllowExtraVersionsStatusRequest.inProgress ||
      allowExtraVersionsStatus == null
    ) {
      return <EuiLoadingSpinner data-test-id='allow-extra-versions-loading' />
    }

    return (
      <Fragment>
        <EuiSwitch
          label={regionId}
          data-test-id='allow-extra-versions-switch'
          checked={allowExtraVersionsStatus.bypass_version_allowlist}
          onChange={this.onChange}
        />

        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>{this.renderCheckAgainButton()}</EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderCheckAgainButton() {
    return (
      <EuiButton
        size='s'
        onClick={() => this.props.hideStatus()}
        data-test-id='allow-extra-versions-check-again'
      >
        <FormattedMessage
          id='allow-extra-versions.status.search-again'
          defaultMessage='Check another region'
        />
      </EuiButton>
    )
  }

  onChange = (e) => {
    if (e.target.checked) {
      return this.enableBypass()
    }

    return this.disableBypass()
  }

  enableBypass() {
    this.props.updateAllowExtraVersionsStatus(true)
  }

  disableBypass() {
    this.props.updateAllowExtraVersionsStatus(false)
  }
}

export default Status
