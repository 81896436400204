/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiPanel,
  EuiSpacer,
  EuiTextAlign,
  EuiTitle,
  EuiToolTip,
} from '@elastic/eui'

import PrivacySensitiveContainer from '@/components/PrivacySensitiveContainer'
import { userOverviewUrl } from '@/lib/urlBuilder'

import { CuiAlert, CuiLink, CuiPermissibleControl, CuiTable, CuiTimeAgo } from '../../../cui'
import Permission from '../../../lib/api/v1/permissions'

import OrganizationMemberStatus from './OrganizationMemberStatus'
import RemoveInvitationButton from './RemoveInvitationButton'
import RemoveMemberButton from './RemoveMemberButton'
import { hasSameEmail } from './lib'
import InviteOrganizationMembersButton from './InviteOrganizationMembersFlyout/InviteOrganizationMembersButton'
import EmptyOrganizationMembers from './EmptyOrganizationMembers'
import AddMemberButton from './AddMemberButton'

import type { AllProps as Props, OrganizationMemberRow } from './types'
import type { CuiTableColumn } from '../../../cui'
import type { ReactElement, ReactNode } from 'react'

class OrganizationMembers extends Component<Props> {
  componentDidMount(): void {
    const { fetchOrganizationIfNeeded, fetchOrganizationMembers } = this.props

    fetchOrganizationIfNeeded()
    fetchOrganizationMembers()
  }

  render(): ReactElement {
    const {
      fetchOrganizationRequest: {
        error: fetchOrganizationError,
        inProgress: isFetchingOrganization,
      },
      fetchOrganizationInvitationsRequest: {
        error: fetchInvitationsError,
        inProgress: isFetchingInvitations,
      },
      fetchOrganizationMembersRequest: { error: fetchMembersError, inProgress: isFetchingMembers },
      organizationMemberRows,
      showEmptyOrganizationMembers,
      organization,
    } = this.props

    const error = fetchOrganizationError || fetchInvitationsError || fetchMembersError

    if (error) {
      return (
        <CuiAlert data-test-id='organization-members-error' type='error'>
          {error}
        </CuiAlert>
      )
    }

    const isLoading = isFetchingOrganization || isFetchingInvitations || isFetchingMembers

    if (!isLoading && showEmptyOrganizationMembers) {
      return (
        <EmptyOrganizationMembers
          organization={organization}
          organizationMemberRows={organizationMemberRows}
        />
      )
    }

    const { columns, initialSort } = this.getGridColumns()

    return (
      <EuiPanel>
        {this.renderPanelTitle(organizationMemberRows, isLoading)}

        <EuiSpacer />

        <PrivacySensitiveContainer>
          <CuiTable<OrganizationMemberRow>
            getRowId={({ email }) => email}
            rows={organizationMemberRows}
            columns={columns}
            initialLoading={isLoading}
            initialSort={initialSort}
            data-test-id='organization-members-table'
          />
        </PrivacySensitiveContainer>
      </EuiPanel>
    )
  }

  renderPanelTitle(
    organizationMemberRows: OrganizationMemberRow[],
    isLoading: boolean,
  ): ReactElement {
    const { organization, isAdminconsole } = this.props
    const { length: membersCount } = organizationMemberRows

    const title = isLoading ? (
      <div style={{ maxWidth: '250px' }}>
        <EuiLoadingContent lines={1} />
      </div>
    ) : (
      <FormattedMessage
        id='organization.organization-members.x-members'
        defaultMessage='{membersCount, plural, =0 {Members} one {# member} other {# members}}'
        values={{ membersCount }}
      />
    )

    return (
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFlexGroup>
            <EuiFlexItem grow={9}>
              <EuiTitle size='m' data-test-id='organization-members-title'>
                <h2>{title}</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              {!isLoading && isAdminconsole && organization && (
                <CuiPermissibleControl permissions={Permission.createOrganizationMembership}>
                  <AddMemberButton organizationId={organization.id} />
                </CuiPermissibleControl>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        {!isLoading && !isAdminconsole && organization && (
          <EuiFlexItem>
            <EuiTextAlign textAlign='right'>
              <InviteOrganizationMembersButton
                organizationId={organization.id}
                organizationMemberRows={organizationMemberRows}
              />
            </EuiTextAlign>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  renderMemberEmail(organizationMemberRow: OrganizationMemberRow): ReactNode {
    const { profile, isAdminconsole } = this.props
    const { id: userId, email, isInvitation } = organizationMemberRow

    if (hasSameEmail(profile, organizationMemberRow)) {
      return (
        <FormattedMessage
          id='organization.organization-members.email-address-you'
          defaultMessage='{email} (you)'
          values={{ email }}
          data-test-id='organization.organization-members.email-address-you'
        />
      )
    }

    const memberEmail = isAdminconsole ? (
      <CuiLink
        data-test-id='organization.organization-members.email-address'
        to={userOverviewUrl(userId)}
      >
        {email}
      </CuiLink>
    ) : (
      <span data-test-id='organization.organization-members.email-address'>{email}</span>
    )

    if (isInvitation) {
      return memberEmail
    }

    return (
      <EuiToolTip
        content={
          <FormattedMessage
            id='organization.organization-members.user-id'
            defaultMessage='User ID: {userId}'
            values={{ userId }}
          />
        }
      >
        {memberEmail}
      </EuiToolTip>
    )
  }

  renderRemoveButton(organizationMemberRow: OrganizationMemberRow): ReactNode {
    const { organization } = this.props

    if (!organization) {
      return null
    }

    if (organizationMemberRow.isInvitation) {
      return (
        <RemoveInvitationButton
          organization={organization}
          organizationMemberRow={organizationMemberRow}
        />
      )
    }

    return (
      <RemoveMemberButton
        organization={organization}
        organizationMemberRow={organizationMemberRow}
      />
    )
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<OrganizationMemberRow>
    columns: Array<CuiTableColumn<OrganizationMemberRow>>
  } {
    const { organization } = this.props

    const emailColumn: CuiTableColumn<OrganizationMemberRow> = {
      label: (
        <FormattedMessage
          id='organization.organization-members.email-address'
          defaultMessage='Email address'
        />
      ),
      render: (organizationMemberRow) => this.renderMemberEmail(organizationMemberRow),
      sortKey: `email`,
    }

    return {
      initialSort: emailColumn,
      columns: [
        emailColumn,
        {
          label: (
            <FormattedMessage
              id='organization.organization-members.status'
              defaultMessage='Status'
            />
          ),
          render: (organizationMemberRow) =>
            organization && (
              <OrganizationMemberStatus
                organizationId={organization.id}
                organizationMemberRow={organizationMemberRow}
              />
            ),
          sortKey: `isInvitation`,
        },
        {
          label: (
            <FormattedMessage id='organization.organization-members.added' defaultMessage='Added' />
          ),
          render: ({ addedDate }) => <CuiTimeAgo date={addedDate} longTime={true} />,
          sortKey: `addedDate`,
        },
        {
          actions: true,
          align: 'right',
          label: (
            <FormattedMessage
              id='organization.organization-members.actions'
              defaultMessage='Actions'
            />
          ),
          render: (organizationMemberRow) => this.renderRemoveButton(organizationMemberRow),
          width: '10%',
        },
      ],
    }
  }
}

export default injectIntl(OrganizationMembers)
