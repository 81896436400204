/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiForm,
  EuiIconTip,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingContent,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import Status from './Status'

import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  organizationId?: string
}

const messages = defineMessages({
  label: {
    id: `allow-extra-versions.label`,
    defaultMessage: `Region Id`,
  },
  tooltipText: {
    id: `allow-extra-versions.description`,
    defaultMessage: `Some customers will ask to create a deployment on an older version that is not offered by default. We allow this only in exceptional circumstances and must be approved by Janitors. Approval is on a per region and per organization level.`,
  },
})

interface State {
  userInputRegionId: string
  showStatus: boolean
}

class AllowExtraVersions extends Component<Props> {
  state: State = {
    userInputRegionId: '',
    showStatus: false,
  }

  render() {
    const {
      intl: { formatMessage },
      organizationId,
    } = this.props

    if (organizationId) {
      return (
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage
              id='allow-extra-versions.title'
              defaultMessage='Allow Customer to create deployments on older versions'
            />
            <EuiIconTip
              color='primary'
              content={formatMessage(messages.tooltipText)}
              position='top'
              type='iInCircle'
            />
          </EuiText>

          <EuiSpacer size='s' />

          {this.state.showStatus ? this.renderStatus() : this.renderInitial()}
        </Fragment>
      )
    }

    return (
      <Fragment>
        <EuiLoadingContent data-test-id='allow-extra-versions-loading' lines={1} />
      </Fragment>
    )
  }

  renderInitial() {
    const {
      intl: { formatMessage },
    } = this.props
    return (
      <EuiForm>
        <EuiFormRow label={formatMessage(messages.label)}>
          <EuiFieldText
            value={this.state.userInputRegionId}
            data-test-id='allow-extra-versions-region-id-input'
            onChange={(e) => this.setState({ userInputRegionId: e.target.value })}
          />
        </EuiFormRow>

        <EuiButton
          onClick={() => this.showStatus()}
          data-test-id='allow-extra-versions-check-status'
        >
          <FormattedMessage id='allow-extra-versions.status.check' defaultMessage='Check Status' />
        </EuiButton>
      </EuiForm>
    )
  }

  renderStatus() {
    const { organizationId } = this.props
    return (
      <div data-test-id='allow-extra-versions-status-fragment'>
        <EuiSpacer />
        <Status
          regionId={this.state.userInputRegionId}
          organizationId={organizationId!}
          hideStatus={() => this.hideStatus()}
        />
      </div>
    )
  }

  showStatus() {
    this.setState({ showStatus: true })
  }

  hideStatus() {
    this.setState({ showStatus: false })
  }
}

export default injectIntl(AllowExtraVersions)
