/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Component, createRef } from 'react'
import _ from 'lodash'

import euiVars from '@elastic/eui/dist/eui_theme_light.json'

import type { RefObject } from 'react'
import type { Props } from './types'

interface State {
  shouldTrackUserAttributes: boolean
}

export class DriftChatIframe extends Component<Props, State> {
  chatRef: RefObject<HTMLIFrameElement>

  state: State = {
    shouldTrackUserAttributes: false,
  }

  constructor(props: Props) {
    super(props)
    this.chatRef = createRef()
  }

  componentDidMount() {
    window.addEventListener('message', this.handleWindowMessage)
    const { fetchDriftJwt } = this.props
    fetchDriftJwt()
  }

  // We need to avoid unnecessary re-rendering as it causes glitches in drift iframe component
  // PureComponent doesn't work because shallow comparison of `profile` property isn't working
  // `profile` has plenty of keys and deep comparison of all keys is an overkill here
  // so we compare only keys that are used in component logic
  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.chatUrl !== this.props.chatUrl) {
      return true
    }

    if (nextProps.fetchDriftJwtRequestState !== this.props.fetchDriftJwtRequestState) {
      return true
    }

    if (nextProps.fetchDriftJwt !== this.props.fetchDriftJwt) {
      return true
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      return true
    }

    if (nextProps.location.search !== this.props.location.search) {
      return true
    }

    const { profile: nextProfile } = nextProps
    const { profile } = this.props
    const isProfileChanged =
      nextProfile.user_id !== profile.user_id ||
      nextProfile.email !== profile.email ||
      nextProfile.domain !== profile.domain ||
      nextProfile.inTrial !== profile.inTrial ||
      !_.isEqual(nextProfile.trials, profile.trials) ||
      nextProfile.created !== profile.created ||
      nextProfile.conversion_timestamp !== profile.conversion_timestamp ||
      nextProfile.is_paying !== profile.is_paying

    if (isProfileChanged) {
      return true
    }

    return false
  }

  componentDidUpdate() {
    if (this.state.shouldTrackUserAttributes) {
      this.trackUserAttributes(this.props)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleWindowMessage)
  }

  render() {
    const { fetchDriftJwtRequestState, chatUrl } = this.props

    if (fetchDriftJwtRequestState.inProgress) {
      return null
    }

    const initialStyle = {
      position: 'fixed' as const,
      botton: '30px',
      right: '30px',
      display: 'block',
      zIndex: euiVars.euiZMaskBelowHeader,
    }

    return (
      <iframe data-test-id='drift-chat' style={initialStyle} ref={this.chatRef} src={chatUrl} />
    )
  }

  startTrackingUserAttributes(): void {
    this.setState({ shouldTrackUserAttributes: true })
    // in case if props have been changed
    // between initial rendering and driftWidgetReady event
    this.trackUserAttributes(this.props)
  }

  trackUserAttributes(props: Props): void {
    const chatIframe = this.chatRef.current

    if (!chatIframe?.contentWindow) {
      return
    }

    const context = {
      window: {
        location: {
          hash: window.location.hash,
          host: window.location.host,
          hostname: window.location.hostname,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname,
          port: window.location.port,
          protocol: window.location.protocol,
          search: window.location.search,
        },
        navigator: {
          language: window.navigator.language,
          userAgent: window.navigator.userAgent,
        },
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      },
      document: {
        title: document.title,
        referrer: document.referrer,
      },
    }
    const { profile } = props
    const userAttributes = {
      email: profile.email,
      cloud_user_domain: profile.domain,
      cloud_user_trial: profile.inTrial && profile.trials?.length > 0,
      cloud_user_created_at: profile.created,
      cloud_user_conversion_date: profile.conversion_timestamp,
      cloud_user_paying: profile.is_paying,
    }
    chatIframe.contentWindow.postMessage(
      {
        type: 'driftSetUserAttributes',
        data: { context, userAttributes },
      },
      '*',
    )
  }

  handleWindowMessage = (event: MessageEvent): void => {
    const chatIframe = this.chatRef.current

    if (!chatIframe?.contentWindow || event.source !== chatIframe?.contentWindow) {
      return
    }

    const message = event.data

    switch (message.type) {
      case 'driftWidgetReady': {
        this.startTrackingUserAttributes()
        break
      }

      case 'driftIframeReady': {
        const context = {
          window: {
            location: {
              hash: window.location.hash,
              host: window.location.host,
              hostname: window.location.hostname,
              href: window.location.href,
              origin: window.location.origin,
              pathname: window.location.pathname,
              port: window.location.port,
              protocol: window.location.protocol,
              search: window.location.search,
            },
            navigator: {
              language: window.navigator.language,
              userAgent: window.navigator.userAgent,
            },
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth,
          },
          document: {
            title: document.title,
            referrer: document.referrer,
          },
        }
        const { profile } = this.props
        const userData = {
          id: profile.user_id,
          attributes: {
            email: profile.email,
            cloud_user_domain: profile.domain,
            cloud_user_trial: profile.inTrial && profile.trials?.length > 0,
            cloud_user_created_at: profile.created,
            cloud_user_conversion_date: profile.conversion_timestamp,
            cloud_user_paying: profile.is_paying,
          },
          jwt: profile.driftJwt,
        }
        chatIframe.contentWindow.postMessage(
          {
            type: 'driftSetContext',
            data: { context, user: userData },
          },
          '*',
        )
        break
      }

      case 'driftIframeResize': {
        const iframeStylePropertyUpdateDenyList = ['z-index']

        const styles = message.data.styles as Record<string, string>
        Object.entries(styles).forEach(([key, value]) => {
          if (!iframeStylePropertyUpdateDenyList.includes(key)) {
            chatIframe.style.setProperty(key, value)
          }
        })
        break
      }

      default:
        break
    }
  }
}
